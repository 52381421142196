import { secureRefSplitApi } from './ref-api';

const adminRegionsApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchAdminRegionsRefData: build.query<
      { label: string; value: string }[],
      { profileId: number | string }
    >({
      query: ({ profileId }) => ({
        url: `/admin-profiles/${profileId}/buyer-profiles/regions`,
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchAdminRegionsRefDataQuery } = adminRegionsApi;
