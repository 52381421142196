import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@client';
import Option from '../../../../../../types/option';
import { FleetResponseDto } from './teams/models';

export const adminSupportBuyerApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'adminSupportBuyer_api',
  endpoints: (build) => ({
    fetchBuyerFleets: build.query<FleetResponseDto[], { buyerId: number }>({
      query: ({ buyerId }) => ({
        url: `/secured/buyers/${buyerId}/fleets`,
        method: 'GET'
      })
    }),
    fetchBuyerRuleOptions: build.query<Option[], { buyerId: number }>({
      query: ({ buyerId }) => ({
        url: `/secured/rules`,
        method: 'GET',
        params: { buyerId, size: 1000, page: 0 }
      }),
      transformResponse: (response: {
        items: { id: number; name: string }[];
      }): Option[] => {
        const items = response.items ?? [];
        return items.map((item) => ({ label: item.name, value: item.id }));
      }
    })
  })
});

export type StateWithAdminSupportBuyerApi = {
  [adminSupportBuyerApi.reducerPath]: ReturnType<
    typeof adminSupportBuyerApi.reducer
  >;
};

export const { useFetchBuyerRuleOptionsQuery, useFetchBuyerFleetsQuery } =
  adminSupportBuyerApi;
