import React, {
  MutableRefObject,
  PropsWithChildren,
  useImperativeHandle
} from 'react';
import { PsDialogHandler } from '../handlers';
import { StyleLoadingAttributes, StyleState } from '../types';
import PsButton from './Button/Button';
import PsModal from './ps-modal';
import usePsModal from './ps-modal-hook';

interface Props extends StyleLoadingAttributes, PropsWithChildren {
  title?: string;
  isOpened?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  closeButtonName?: string;
  confirmButtonName?: string;
  confirmButtonState?: StyleState;
  isLoading?: boolean;
  onConfirmTo?: string;
  ref: MutableRefObject<PsDialogHandler>;
}

const PsDialog = React.forwardRef<PsDialogHandler, Props>(
  (
    {
      children,
      title,
      isOpened = false,
      onClose,
      onConfirm,
      closeButtonName = 'No',
      confirmButtonName = 'Yes',
      confirmButtonState = 'primary',
      isLoading = false,
      onConfirmTo,
      ...rest
    },
    ref
  ) => {
    const modal = usePsModal();

    useImperativeHandle(ref, () => {
      return {
        open: modal.open,
        close: modal.close
      };
    });

    return (
      <PsModal
        ref={modal.ref}
        title={title}
        closeButtonName={closeButtonName}
        isOpened={isOpened}
        minHeight="120px"
        onClose={onClose}
        actions={
          <PsButton
            link={onConfirmTo}
            state={confirmButtonState}
            onClick={onConfirm}
            isLoading={isLoading}
          >
            {confirmButtonName}
          </PsButton>
        }
      >
        {children}
      </PsModal>
    );
  }
);

export default PsDialog;
