import { authInterceptor, axiosBaseQuery } from '@client';
import { getEnvironmentVariables } from '@common';
import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

const { API_URL } = getEnvironmentVariables();
const baseURL = `${API_URL}/sof`;

const headers = {
  Accept: '*/*',
  'Cache-Control': 'max-age=0',
  'Content-Type': 'application/json'
};
const axiosInstance = axios.create({
  baseURL,
  headers
});
axiosInstance.interceptors.request.use(authInterceptor.onRequest.onFulfilled);

export const sofRestApi = createApi({
  reducerPath: 'sof-rest-api',
  baseQuery: axiosBaseQuery({
    customAxiosInstance: axiosInstance,
    baseUrl: ''
  }),
  endpoints: () => ({}),
  tagTypes: ['SOF_SIGNATURE_DATA', 'SOF_TIMESHEET']
});
