import { useAuth0 } from '@auth0/auth0-react';
import React, { FC, PropsWithChildren } from 'react';
import PageLoader from '../../frontend/fragment/PageLoader';

const AuthWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isLoading } = useAuth0();

  return !isLoading ? <>{children}</> : <PageLoader />;
};

export default AuthWrapper;
