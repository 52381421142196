import { ProfileResponse } from '@module/profile/model/ProfileResponse';

import {
  BillingCompanyForm,
  BuyerProfileForm,
  BuyerUserForm
} from '../../../types';
import { mainRestApi } from '../rest-api';

export const buyerApi = mainRestApi.injectEndpoints({
  endpoints: (build) => ({
    loadBuyerProfileSettings: build.query<
      ProfileResponse,
      { profileId: string | number }
    >({
      query: () => ({
        url: `/secured/buyer/settings/profile`,
        method: 'GET'
      }),
      providesTags: (result, _error, arg) =>
        result
          ? [
              {
                type: 'BUYER_SETTINGS_PROFILE' as const,
                id: arg.profileId.toString()
              }
            ]
          : ['BUYER_SETTINGS_PROFILE']
    }),
    saveBuyerProfileSettings: build.mutation<ProfileResponse, BuyerProfileForm>(
      {
        query: (data) => ({
          url: '/secured/buyer/settings/profile/save',
          method: 'POST',
          data
        }),
        invalidatesTags: (result) =>
          result
            ? [
                {
                  type: 'BUYER_SETTINGS_PROFILE' as const,
                  id: result.id.toString()
                }
              ]
            : ['BUYER_SETTINGS_PROFILE']
      }
    ),
    loadBuyerUserProfile: build.query<
      ProfileResponse,
      { buyerProfileId: string | number }
    >({
      query: ({ buyerProfileId }) => ({
        url: `/secured/buyer/settings/buyer-user/${buyerProfileId}/load`,
        method: 'GET'
      }),
      providesTags: (result, _error, arg) =>
        result
          ? [
              {
                type: 'BUYER_SETTINGS_USER_PROFILE' as const,
                id: arg.buyerProfileId.toString()
              }
            ]
          : ['BUYER_SETTINGS_USER_PROFILE']
    }),
    saveBuyerUserProfile: build.mutation<ProfileResponse, BuyerUserForm>({
      query: (data) => ({
        url: `/secured/buyer/settings/buyer-user/save`,
        method: 'POST',
        data
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'BUYER_SETTINGS_USER_PROFILE' as const,
                id: result.id.toString()
              }
            ]
          : ['BUYER_SETTINGS_USER_PROFILE']
    }),
    createBuyerUser: build.mutation<ProfileResponse, BuyerUserForm>({
      query: (data) => ({
        url: `/secured/buyer/settings/buyer-user/create`,
        method: 'POST',
        data
      })
    }),
    loadBuyerBillingCompany: build.query<
      BillingCompanyForm,
      { id: string | number }
    >({
      query: ({ id }) => ({
        url: `/secured/buyer/settings/billing-company/${id}/load`,
        method: 'GET'
      }),
      providesTags: (result, _error, arg) =>
        result
          ? [
              {
                type: 'BUYER_SETTINGS_BILLING_COMPANY' as const,
                id: arg.id.toString()
              }
            ]
          : ['BUYER_SETTINGS_BILLING_COMPANY']
    }),
    saveBuyerBillingCompany: build.mutation<
      BillingCompanyForm,
      BillingCompanyForm
    >({
      query: (data) => ({
        url: `/secured/buyer/settings/billing-company/save`,
        method: 'POST',
        data
      }),
      invalidatesTags: (result) =>
        result
          ? [
              {
                type: 'BUYER_SETTINGS_BILLING_COMPANY' as const,
                id: result.id.toString()
              }
            ]
          : ['BUYER_SETTINGS_BILLING_COMPANY']
    }),
    createBuyerBillingCompany: build.mutation<
      BillingCompanyForm,
      BillingCompanyForm
    >({
      query: (data) => ({
        url: `/secured/buyer/settings/billing-company/create`,
        method: 'POST',
        data
      })
    })
  })
});

export const {
  useSaveBuyerProfileSettingsMutation,
  useLoadBuyerProfileSettingsQuery,
  useLoadBuyerUserProfileQuery,
  useSaveBuyerUserProfileMutation,
  useCreateBuyerUserMutation,
  useLoadBuyerBillingCompanyQuery,
  useSaveBuyerBillingCompanyMutation,
  useCreateBuyerBillingCompanyMutation
} = buyerApi;
