import {
  StateWithBuyerTeamsState,
  buyerTeamsTableSlice,
  BuyerTeamsState
} from './reducers';
import { filtersAdapter } from '../../../../../types';

export const selectBuyerTeamsState = (state: StateWithBuyerTeamsState) =>
  state[buyerTeamsTableSlice.name];

const filterSelectors = filtersAdapter.getSelectors<BuyerTeamsState>(
  (state) => state.filters
);

export const selectBuyerTeamsFilters = (state: StateWithBuyerTeamsState) => {
  return filterSelectors.selectAll(selectBuyerTeamsState(state));
};

export const selectBuyerTeamsFilterBy =
  (name: string) => (state: StateWithBuyerTeamsState) => {
    return filterSelectors.selectById(selectBuyerTeamsState(state), name);
  };
