import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@client';
import { BuyerProfileTeamsForm, BuyerTeamProfileResponse } from '@state';
import { ProfileResponse } from '../../../../../../module/profile/model/ProfileResponse';
import { ProfileVesselsForm } from '@module/profile/model/ProfileVesselsForm';

export const buyerProfileApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['buyerProfile', 'buyerProfileTeams'],
  reducerPath: 'buyer-profile-api',
  endpoints: (build) => ({
    fetchBuyerProfile: build.query<ProfileResponse, { profileId: number }>({
      query: ({ profileId }) => ({
        url: `/secured/buyer-profile/${profileId}/load`,
        method: 'GET'
      }),
      providesTags: ['buyerProfile']
    }),
    fetchBuyerProfileTeams: build.query<
      BuyerTeamProfileResponse[],
      { profileId: number }
    >({
      query: ({ profileId }) => ({
        url: `/secured/buyer-profile/${profileId}/load-buyer-teams`,
        method: 'GET'
      }),
      providesTags: ['buyerProfileTeams']
    }),
    submitProfileVessels: build.mutation<ProfileResponse, ProfileVesselsForm>({
      query: (data) => ({
        url: '/secured/buyer-profile/save-vessels',
        method: 'POST',
        data
      }),
      invalidatesTags: ['buyerProfile']
    }),
    submitBuyerProfileTeams: build.mutation<
      ProfileResponse,
      BuyerProfileTeamsForm
    >({
      query: (data) => ({
        url: '/secured/buyer-profile/save-buyer-teams',
        method: 'POST',
        data
      }),
      invalidatesTags: ['buyerProfileTeams', 'buyerProfile']
    })
  })
});

export type StateWithBuyerProfileApi = {
  [buyerProfileApi.reducerPath]: ReturnType<typeof buyerProfileApi.reducer>;
};

export const {
  useFetchBuyerProfileQuery,
  useFetchBuyerProfileTeamsQuery,
  useSubmitProfileVesselsMutation,
  useSubmitBuyerProfileTeamsMutation
} = buyerProfileApi;
