import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { RequestCaseQuotationsEvaluationResponse } from '../../types';

export const requestCaseApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'secured/request-case' }),
  reducerPath: 'request-case-api',
  endpoints: (build) => ({
    evaluateRequestCase: build.query<
      RequestCaseQuotationsEvaluationResponse,
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        method: 'GET',
        url: `/${requestCaseId}/evaluate`
      })
    }),
    shareRequestCase: build.mutation<
      void,
      { requestCaseId: number; emails: string[] }
    >({
      query: ({ requestCaseId, emails }) => ({
        method: 'POST',
        url: `/${requestCaseId}/share`,
        data: { emails }
      })
    })
  })
});

export const { useEvaluateRequestCaseQuery, useShareRequestCaseMutation } =
  requestCaseApi;
