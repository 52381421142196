import { graphqlBaseQuery, GraphqlRequestParams } from '@client';
import { getEnvironmentVariables } from '@common';
import { BaseQueryApi } from '@reduxjs/toolkit/query';
import { selectAuthToken, StateWithSession } from '../../user';

export const dynamicBaseGraphqlQuery =
  ({ baseUrl }: Pick<GraphqlRequestParams, 'baseUrl'>) =>
  async (
    graphqlRquestParams: Omit<GraphqlRequestParams, 'baseUrl'>,
    api: BaseQueryApi
  ) => {
    const { SOF_API_URL } = getEnvironmentVariables();
    const token = selectAuthToken(api.getState() as StateWithSession);

    if (token) {
      graphqlRquestParams.requestHeaders = {
        ...graphqlRquestParams.requestHeaders,
        Authorization: `Bearer ${token}`
      };
    }

    // provide the amended url and other params to the raw base query
    return graphqlBaseQuery({ baseUrl: `${SOF_API_URL}/${baseUrl}` })(
      graphqlRquestParams
    );
  };
