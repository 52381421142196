import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NotificationsState = {
  pagination: {
    page: number;
    pageSize: number;
    filter: 'ALL' | 'UNREAD' | 'EVALUATION' | 'ASSIGNMENT';
  };
  drawerOpened: boolean;
};

const initialState: NotificationsState = {
  pagination: { page: 0, pageSize: 14, filter: 'ALL' },
  drawerOpened: false
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<number>) => {
      state.pagination.page = payload;
    },
    updateFilter: (
      state,
      { payload }: PayloadAction<'ALL' | 'UNREAD' | 'EVALUATION' | 'ASSIGNMENT'>
    ) => {
      state.pagination.filter = payload;
      state.pagination.page = 0;
    },
    openDrawer: (state) => {
      state.drawerOpened = true;
    }
  }
});

export const {
  updatePage: updateNotificationsPage,
  updateFilter: updateNotificationsFilter,
  openDrawer: openNotificationsDrawer
} = notificationsSlice.actions;

export type StateWithNotifications = {
  [notificationsSlice.name]: NotificationsState;
};
