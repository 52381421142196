import { RefData } from '../../types';
import { secureRefSplitApi } from './ref-api';

const vendorTypesApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchVendorTypes: build.query<RefData[], void>({
      query: () => ({
        url: '/vendor-types',
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchVendorTypesQuery } = vendorTypesApi;
