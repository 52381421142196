import { getEnvironmentVariables } from '@common';
import { selectAuthCompany, selectAuthProfile, useAppSelector } from '@state';
import insp, { install } from 'inspectlet-es';
import { useEffect } from 'react';
import { UseScriptStatus } from './useCookieConsentBanner';

export const useInspectlet = (autoBlockCookiesStatus?: UseScriptStatus) => {
  const { INSPECTLET } = getEnvironmentVariables();

  const profile = useAppSelector(selectAuthProfile);
  const company = useAppSelector(selectAuthCompany);

  useEffect(() => {
    if (autoBlockCookiesStatus === 'ready') {
      INSPECTLET && install(INSPECTLET);

      if (company && profile && INSPECTLET) {
        insp(['identify', company.name + ' / ' + profile.email]);
      }
    }
  }, [autoBlockCookiesStatus, company, profile]);
};
