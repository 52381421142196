import { api, PortCall } from './generated';

import type {
  DefinitionsFromApi,
  OverrideResultType,
  TagTypesFromApi
} from '@reduxjs/toolkit/dist/query/endpointDefinitions';

type Definitions = DefinitionsFromApi<typeof api>;
type TagTypes = TagTypesFromApi<typeof api>;

type UpdatedDefinitions = Definitions & {
  getPortCallDetails: OverrideResultType<
    Definitions['getPortCallDetails'],
    PortCall
  >;
};

const enhancedApi = api.enhanceEndpoints<TagTypes, UpdatedDefinitions>({
  endpoints: {
    getPortCallDetails: {
      // https://redux-toolkit.js.org/rtk-query/api/createApi#providestags
      providesTags: ['PORTCALL'],
      transformResponse: ({ getPortCallById }) => {
        return getPortCallById;
      }
    }
  }
});

export const { useGetPortCallDetailsQuery, useLazyGetPortCallDetailsQuery } =
  enhancedApi;
