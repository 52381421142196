export enum Role {
  USER = 'USER',
  DEPUTY = 'DEPUTY',
  MANAGER = 'MANAGER'
}

export enum RoleRank {
  USER,
  DEPUTY,
  MANAGER
}
