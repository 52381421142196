import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isAfter, isBefore, parseISO } from 'date-fns';
import get from 'lodash/get';
import has from 'lodash/has';
import React, {
  PropsWithChildren,
  Ref,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import DatePicker from 'react-datepicker';
import { createPortal } from 'react-dom';
import { PsDateFieldHandler } from '../handlers';
import { FieldWrapperProps } from '../types';
import PsFieldWrapper from './ps-form-field-wrapper';
import PsStyle from './ps-style';
import PsUtilities from './ps-utilities';

type Props = {
  name: string;
  entity?: any;
  isDisabled?: boolean;
  form: any;
  placeholder?: string;
  isRequired?: boolean;
  dateFormat?: string;
  isClearable?: boolean;
  hasIconLeft?: boolean;
  hasIconRight?: boolean;
  minDate?: string;
  maxDate?: string;
  defaultValue?: string | null;
  popperPlacement?: string;
  isPortal?: boolean;
  onKeyDown?: any;
  onSelect?: (date: Date, event: React.SyntheticEvent) => any;
  ref?: any;
} & FieldWrapperProps;

const DatePickerPortal: React.FunctionComponent<PropsWithChildren> = ({
  children
}) => createPortal(children, window.document.body);

const PsDateField: React.FunctionComponent<Props> = React.forwardRef(
  (
    {
      children,
      placeholder,
      name,
      entity,
      isDisabled = false,
      form,
      isRequired = false,
      dateFormat = 'dd/MM/yyyy',
      isClearable = false,
      hasIconLeft = false,
      hasIconRight = false,
      minDate,
      maxDate,
      defaultValue,
      popperPlacement,
      isPortal = false,
      onKeyDown,
      onSelect,
      className,
      ...rest
    },
    ref: Ref<PsDateFieldHandler>
  ) => {
    const [defValue, setDefValue] = useState<Date | null>();
    const [val, setVal] = useState<Date | null>();

    const [minimumDate, setMinimumDate] = useState<Date | null>();
    const [maximumDate, setMaximumDate] = useState<Date | null>();

    useImperativeHandle(ref, () => {
      return {
        reset: reset,
        value: val
      };
    });

    useEffect(() => {
      setMinimumDate(!!minDate ? parseISO(minDate) : null);
    }, [minDate]);

    useEffect(() => {
      setMaximumDate(!!maxDate ? parseISO(maxDate) : null);
    }, [maxDate]);

    useEffect(() => {
      if (!!minimumDate && !!val) {
        if (isBefore(val, minimumDate)) onSetValueDate(null);
      }
    }, [minimumDate]);

    useEffect(() => {
      if (!!maximumDate && !!val) {
        if (isAfter(val, maximumDate)) onSetValueDate(null);
      }
    }, [maximumDate]);

    useEffect(() => {
      let initialValue = defaultValue;
      if (!!entity && !!name && get(entity, name)) {
        initialValue = get(entity, name);
      }

      setDefValue(!!initialValue ? parseISO(initialValue) : null);

      if (!has(form.getValues(), name))
        form.register(
          { name: name },
          {
            validate: {
              required: (value: any) =>
                isRequired ? !!value || 'This field is required!' : undefined
            }
          }
        );
    }, [entity, name, defaultValue]);

    useEffect(() => {
      onSetValueDate(defValue);
    }, [defValue]);

    const error = !!form && get(form.errors, name);
    const hasError = !!error;

    const updateFormValue = (value: Date | null | undefined) => {
      if (!!form) {
        form.setValue(name, !!value ? PsUtilities.formatISO(value) : null);
        form.clearErrors(name);
      }
    };

    const onSetValueDate = (value: Date | null | undefined) => {
      setVal(value);
      updateFormValue(value);
    };

    const reset = () => {
      onSetValueDate(defValue);
    };

    return (
      <PsFieldWrapper
        hasError={hasError}
        errorMessage={get(error, 'message')}
        hasIconsLeft={hasIconLeft}
        hasIconsRight={hasIconRight}
        {...rest}
      >
        <DatePicker
          name={name}
          className={
            'input' +
            (hasError ? ' is-danger' : '') +
            PsStyle.applyClassName(className)
          }
          disabled={isDisabled}
          placeholderText={placeholder}
          dateFormat={dateFormat}
          isClearable={isClearable}
          selected={val}
          minDate={minimumDate}
          maxDate={maximumDate}
          popperPlacement={popperPlacement}
          popperContainer={isPortal ? DatePickerPortal : undefined}
          onChange={onSetValueDate}
          onSelect={onSelect}
          onKeyDown={onKeyDown}
        />
        {(hasIconLeft || hasIconRight) && (
          <span
            className={
              'icon is-small' +
              (hasIconLeft ? ' is-left' : '') +
              (hasIconRight ? ' is-right' : '')
            }
          >
            <FontAwesomeIcon icon={faCalendar} />
          </span>
        )}
      </PsFieldWrapper>
    );
  }
);

export default PsDateField;
