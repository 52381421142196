import { secureRefSplitApi } from './ref-api';

const activeExpensesCategoriesApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchActiveExpensesCategories: build.query<
      { label: string; value: string }[],
      void
    >({
      query: () => ({
        url: '/active-expenses-categories',
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchActiveExpensesCategoriesQuery } =
  activeExpensesCategoriesApi;
