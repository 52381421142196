import { io, Socket } from 'socket.io-client';
import { getEnvironmentVariables } from 'src/common/constants';

let socket: Socket;
const { SSE_HOST } = getEnvironmentVariables();

async function initializeSocket(token: any, userId: any) {
  if (!socket) {
    socket = io(SSE_HOST, {
      auth: {
        token: `Bearer ${token}` // This will be available on the server side
      },
      reconnection: true,
      reconnectionAttempts: 10,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
      transports: ['websocket', 'polling']
    });

    socket.on('connect', () => {
      console.log(`Connected as ${userId}`);
    });

    socket.on('disconnect', (reason: any) => {
      console.warn(`Disconnected: ${reason}`);
    });

    socket.on('connect_error', (err: any) => {
      console.error('Connection error:', err.message);
    });
  }
}

export async function connectSocket(token: any, userId: any) {
  initializeSocket(token, userId);
  if (!socket.connected) {
    await socket.connect(); // Ensure the socket connection is open
  }
}

export async function subscribeToTopic(
  token: any,
  userId: any,
  topic: any,
  listener: any
) {
  await connectSocket(token, userId);
  socket.on(`${userId}/` + topic.toLowerCase(), listener);
}

export function disconnectSocket() {
  if (socket) {
    socket.disconnect();
  }
}
