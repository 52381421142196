import { secureRefSplitApi } from './ref-api';

const currenciesApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchCurrencies: build.query<{ label: string; value: string }[], void>({
      query: () => ({ url: '/currencies', method: 'GET' })
    })
  }),
  overrideExisting: false
});

export const { useFetchCurrenciesQuery } = currenciesApi;
