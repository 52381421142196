import { PERSIST_KEY, SESSION, hotjarIdentify, webStorage } from '@common';
import { PhoneResponse } from '@module/contact/model/PhoneResponse';
import { ProfileResponse } from '@module/profile/model/ProfileResponse';
import { ProfileStatus } from '@module/profile/model/ProfileStatus';
import { Role } from '@module/role/model/Role';
import { WebSession } from '@module/web-session/model/WebSession';
import { ListenerMiddlewareInstance, isAnyOf } from '@reduxjs/toolkit';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import { rootReducer } from '../../reducers';
import { accountApi } from '../restApi/secured/accountApi';
import { authApi } from '../restApi/secured/authApi';
import { buyerApi } from '../restApi/secured/buyerSettingsApi';
import { supplierApi } from '../restApi/secured/supplierApi';
import { Involvement } from '../statement-of-facts/graphql/generated';
import { statementOfFactsApi } from '../statement-of-facts/rest';
import {
  authenticationSlice,
  selectAuthCompany,
  selectAuthProfile,
  selectAuthToken,
  setProfile,
  setToken
} from './reducers';

export const registerSessionListeners = (
  listenerMiddleware: ListenerMiddlewareInstance<ReturnType<typeof rootReducer>>
) => {
  // Add one or more listener entries that look for specific actions.
  // They may contain any sync or async logic, similar to thunks.

  listenerMiddleware.startListening({
    matcher: authApi.endpoints.getUserProfile.matchFulfilled,
    effect: (action, api) => {
      webStorage.setItem(SESSION, {
        ...action.payload,
        token: api.getState().session.authentication.token
      });
    }
  });

  listenerMiddleware.startListening({
    matcher: authApi.endpoints.getUserProfile.matchFulfilled,
    effect: (_action, { getState }) => {
      const profile = selectAuthProfile(getState());
      const company = selectAuthCompany(getState());

      if (!profile || !company) {
        return;
      }

      hotjarIdentify(profile.id.toString(), {
        userId: profile.id.toString(),
        companyId: company.id.toString() || '',
        departmentName: profile.department?.name || '',
        profileRole: profile.role || '',
        profileType: profile.type || ''
      });
    }
  });

  listenerMiddleware.startListening({
    matcher: isAnyOf(
      authApi.endpoints.logout.matchFulfilled,
      authApi.endpoints.logout.matchRejected
    ),
    effect: () => {
      webStorage.removeItem(PERSIST_KEY);
      webStorage.removeItem(SESSION);
    }
  });

  listenerMiddleware.startListening({
    matcher: authApi.endpoints.getUserProfile.matchFulfilled,
    effect: (_action, { getState }) => {
      const profile = selectAuthProfile(getState());
      const company = selectAuthCompany(getState());

      if (!profile || !company) {
        return;
      }

      const { heap } = window;

      heap?.identify?.(profile.id.toString());
      heap?.addUserProperties?.({
        type: profile.type || '',
        role: profile.role || '',
        companyId: company.id || '',
        companyName: company.name || '',
        departmentName: profile.department?.name || ''
      });
    }
  });

  listenerMiddleware.startListening({
    predicate: (action) =>
      statementOfFactsApi.endpoints.authenticateInvitee.matchFulfilled(action),
    effect: (action, { dispatch }) => {
      const token = action.payload;
      const uuid = action.meta.arg.originalArgs.uuid;
      const decoded = jwtDecode<
        {
          collaboratorType: ProfileResponse['type'] | Involvement;
          fullName?: string | undefined;
        } & JwtPayload
      >(token);

      const type = decoded.collaboratorType ?? '';

      const profile = {
        fullName: decoded.fullName ?? '',
        id: uuid,
        status: ProfileStatus.ACTIVE,
        type,
        username: '',
        password: '',
        name: '',
        surname: '',
        email: '',
        creationDate: '',
        lastLoginDate: '',
        isEnabled: false,
        isExpired: false,
        isLocked: false,
        loginTries: '',
        canBeHijacked: false,
        intercomHash: '',
        isEmailMuted: false,
        isNotificationMuted: false,
        isAdmin: type === 'ADMIN',
        isBuyer: type === 'BUYER',
        isSupplier: type === 'VENDOR',
        isAgent: type === 'AGENT',
        isVendor: type === 'VENDOR',
        isInactive: false,
        authorities: [],
        rules: [],
        role: Role.USER,
        department: {
          id: '',
          name: '',
          abbreviation: ''
        },
        departmentId: '',
        vessels: [],
        companyId: '',
        isPendingVerification: false,
        isCompanyPendingRegistration: false,
        isCompanyLocked: false,
        products: [],
        phone: {} as PhoneResponse,
        internalPhoneNumber: ''
      } as ProfileResponse;
      webStorage.setItem(SESSION, {
        profile,
        token
      });

      dispatch(setToken({ token }));
      dispatch(setProfile(profile));
    }
  });

  listenerMiddleware.startListening({
    predicate: (action) =>
      accountApi.endpoints.acceptTermsCondition.matchFulfilled(action),
    effect: (action, { dispatch }) => {
      const {
        payload,
        meta: {
          arg: {
            originalArgs: { accepted }
          }
        }
      } = action;
      if (!accepted) {
        webStorage.setItem(SESSION, null);
        dispatch(authenticationSlice.actions.clear());
        dispatch(authenticationSlice.actions.clearToken());
      } else {
        dispatch(authenticationSlice.actions.setProfile(payload));
        const session: WebSession | null = webStorage.getItem(SESSION);
        session!.profile = payload;
        webStorage.setItem(SESSION, session);
      }
    }
  });

  listenerMiddleware.startListening({
    matcher: isAnyOf(
      buyerApi.endpoints.saveBuyerProfileSettings.matchFulfilled,
      supplierApi.endpoints.saveSupplierProfileSettings.matchFulfilled
    ),
    effect: (_action, { dispatch, getState }) => {
      const token = selectAuthToken(getState());
      token && dispatch(authApi.endpoints.refreshUserClaims.initiate());
    }
  });
};
