import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/query/react';
import { RefData } from 'components/types';

type BankCodesResponse = { bankCodes: RefData[]; preselectedBankCode: RefData };
type BankCodesRequest = { buyerId: number; currency: string };

export const bankCodesApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'secured/bank-codes' }),
  reducerPath: 'bank-codes-api',
  endpoints: (build) => ({
    fetchBankCodes: build.query<BankCodesResponse, BankCodesRequest>({
      query: ({ buyerId, currency }) => ({
        method: 'GET',
        url: `/active-with-preselection?buyerId=${buyerId}&currency=${currency}`
      })
    })
  })
});

export const { useFetchBankCodesQuery } = bankCodesApi;
