import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface PostLoginRedirectLocation {
  pathname: string;
  searchParams?: string;
}

export interface MetaDataState {
  postLoginRedirectLocation?: PostLoginRedirectLocation;
}

const initialState: MetaDataState = {};

export const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    setPostLoginRedirectLocation: (
      state: MetaDataState,
      action: PayloadAction<PostLoginRedirectLocation>
    ) => {
      state.postLoginRedirectLocation = action.payload;
    },
    clearPostLoginRedirectLocation: (state: MetaDataState) => {
      state.postLoginRedirectLocation = undefined;
    }
  }
});

export const { setPostLoginRedirectLocation, clearPostLoginRedirectLocation } =
  metadataSlice.actions;

export type StateWithMetadata = {
  [metadataSlice.name]: MetaDataState;
};
