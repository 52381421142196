import React, {
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState
} from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { WithLoading } from '../helpers';
import { LoadingTemplate } from './ps-loading-placeholder';
import { PsDrawerHandler } from '../handlers';
import useKeyHandler from './ps-key-handler';
import { useDisableBodyScroll } from '@common';

interface Props extends PropsWithChildren {
  title: string;
  action: ReactNode;
  icon?: IconDefinition;
  isLeft?: boolean;
  isLarge?: boolean;
  isSmall?: boolean;
  hasNoPadding?: boolean;
  closeButtonName?: string;
  onOpen?: () => void;
  onClose?: () => void;
  className?: string;
  ref: MutableRefObject<PsDrawerHandler>;
  disableScrollLock: boolean;
}

const PsDrawer = React.forwardRef<PsDrawerHandler, Props>(
  (
    {
      children,
      title,
      action,
      icon,
      onOpen,
      onClose,
      isLeft = false,
      isLarge = false,
      isSmall = false,
      hasNoPadding = false,
      closeButtonName = 'Close',
      className,
      disableScrollLock = false
    },
    ref
  ) => {
    const [isActive, setIsActive] = useState(false);

    const open = () => {
      setIsActive(true);
      if (onOpen) onOpen();
    };

    const close = () => {
      setIsActive(false);
      if (onClose) onClose();
    };

    useKeyHandler(document, 'Escape', 'keydown', close);

    const scrollToBottom = () => {
      const content = document.getElementById('content');
      if (content) content.scrollTop = content.scrollHeight;
    };

    useImperativeHandle(ref, () => {
      return {
        open: open,
        close: close,
        scrollToBottom: scrollToBottom,
        isOpen: isActive
      };
    });

    useDisableBodyScroll(disableScrollLock && isActive);

    return (
      <div>
        <div
          id="overlay"
          onClick={close}
          className="slide-background"
          hidden={!isActive}
        />
        <div
          className={
            'slide-in-panel' +
            (isActive ? ' is-active' : '') +
            (isSmall ? ' has-max-width' : '') +
            (isLarge ? ' has-three-quarters-width' : '') +
            (isLeft ? ' from-left' : '')
          }
        >
          <div className="card">
            <header className="card-header">
              <p className="card-header-title">
                {!!icon && (
                  <span className="icon is-medium" style={{ height: '0' }}>
                    <FontAwesomeIcon icon={icon} />
                  </span>
                )}
                <span>{title}</span>
              </p>
              <a
                onClick={close}
                className="card-header-icon"
                aria-label="more options"
              >
                <span className="icon has-text-grey-dark">
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </a>
            </header>
            <div
              id="content"
              className={
                'card-content' +
                (hasNoPadding ? ' is-paddingless' : '') +
                (!!className ? ' ' + className : '')
              }
            >
              {isActive ? children : null}
            </div>
            <footer className="card-footer">
              <button
                type="button"
                className="card-footer-item button has-text-grey is-text"
                onClick={close}
              >
                {closeButtonName}
              </button>
              {action}
            </footer>
          </div>
        </div>
      </div>
    );
  }
);

export default WithLoading(PsDrawer, LoadingTemplate.VERTICAL_FORM);
