import { axiosBaseQuery } from '@client';
import { getEnvironmentVariables } from '@common';
import { createApi } from '@reduxjs/toolkit/query/react';
import { RequestCaseQuotationsEvaluationResponse } from '@state';
import { PublicRfQAuthRequest } from '@state/types/ShareCaseRequestRequestDTO';
import axios from 'axios';

const { API_URL } = getEnvironmentVariables();

const axiosWithoutInterceptors = axios.create({
  baseURL: `${API_URL}/da-app`
});

export const publicRfQPageApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: '/shared',
    customAxiosInstance: axiosWithoutInterceptors
  }),
  reducerPath: 'public-rfq-page_api',
  endpoints: (build) => ({
    submitRequestForRfQAccess: build.query<
      RequestCaseQuotationsEvaluationResponse,
      PublicRfQAuthRequest
    >({
      query: ({ requestCaseId, ...payload }) => ({
        url: `/request-case/${requestCaseId}`,
        method: 'POST',
        data: payload
      })
    })
  })
});

export type publicRfQPageState = {
  [publicRfQPageApi.reducerPath]: ReturnType<typeof publicRfQPageApi.reducer>;
};

export const {
  useSubmitRequestForRfQAccessQuery,
  useLazySubmitRequestForRfQAccessQuery
} = publicRfQPageApi;
