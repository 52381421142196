import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagingState, SortingState } from '@state/types';

type adminRulesState = PagingState & SortingState;

const initialState: adminRulesState = {
  page: 0,
  size: 10
};

export const adminRulesSlice = createSlice({
  name: 'admin-rules',
  initialState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.page = payload.page;
    },
    updateOrder: (
      state,
      {
        payload
      }: PayloadAction<{ property: string; direction: 'ASC' | 'DESC' }>
    ) => {
      state.property = payload.property;
      state.direction = payload.direction;
      state.page = 0;
    }
  }
});

export const {
  updatePage: updateAdminRulesPage,
  updateOrder: updateAdminRulesOrder
} = adminRulesSlice.actions;

export type StateWithAdminRules = {
  [adminRulesSlice.name]: adminRulesState;
};
