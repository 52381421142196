import { useEffect, useState } from 'react';
import { getEnvironmentVariables } from '@common';

export type UseScriptStatus = 'idle' | 'loading' | 'ready' | 'error';
// Cached script statuses
const cachedScriptStatuses: Record<string, UseScriptStatus | undefined> = {};

function getScriptNode(src: string) {
  const node: HTMLScriptElement | null = document.querySelector(
    `script[src="${src}"]`
  );
  const status = node?.getAttribute('data-status') as
    | UseScriptStatus
    | undefined;

  return {
    node,
    status
  };
}

export function useAutoBlockCookies(): UseScriptStatus | undefined {
  const { COOKIES_CONSENT_ENABLED } = getEnvironmentVariables();

  const otAutoBlockSrc =
    'https://cookie-cdn.cookiepro.com/consent/ce2a5111-ed3b-40c6-9c2b-4cd9d16e968c/OtAutoBlock.js';

  const [status, setStatus] = useState<UseScriptStatus>(() => {
    if (typeof window === 'undefined') {
      // SSR Handling - always return 'loading'
      return 'loading';
    }

    return cachedScriptStatuses[otAutoBlockSrc] ?? 'loading';
  });

  useEffect(() => {
    if (!COOKIES_CONSENT_ENABLED) {
      setStatus('ready');
      return;
    }

    const cachedScriptStatus = cachedScriptStatuses[otAutoBlockSrc];
    if (cachedScriptStatus === 'ready' || cachedScriptStatus === 'error') {
      // If the script is already cached, set its status immediately
      setStatus(cachedScriptStatus);
      return;
    }

    // Fetch existing script element by src
    // It may have been added by another instance of this hook
    const script = getScriptNode(otAutoBlockSrc);
    let scriptNode = script.node;

    if (!scriptNode) {
      scriptNode = document.createElement('script');
      scriptNode.src = otAutoBlockSrc;
      scriptNode.async = false;
      scriptNode.setAttribute('data-status', 'loading');
      document.head.insertBefore(scriptNode, document.head.firstElementChild);

      // Store status in attribute on script
      // This can be read by other instances of this hook
      const setAttributeFromEvent = (event: Event) => {
        const scriptStatus: UseScriptStatus =
          event.type === 'load' ? 'ready' : 'error';

        scriptNode?.setAttribute('data-status', scriptStatus);
      };

      scriptNode.addEventListener('load', setAttributeFromEvent);
      scriptNode.addEventListener('error', setAttributeFromEvent);
    } else {
      // Grab existing script status from attribute and set to state.
      setStatus(script.status ?? cachedScriptStatus ?? 'loading');
    }

    // Script event handler to update status in state
    // Note: Even if the script already exists we still need to add
    // event handlers to update the state for *this* hook instance.
    const setStateFromEvent = (event: Event) => {
      const newStatus = event.type === 'load' ? 'ready' : 'error';
      setStatus(newStatus);
      cachedScriptStatuses[otAutoBlockSrc] = newStatus;
    };

    // Add event listeners
    scriptNode.addEventListener('load', setStateFromEvent);
    scriptNode.addEventListener('error', setStateFromEvent);

    // Remove event listeners on cleanup
    return () => {
      if (scriptNode) {
        scriptNode.removeEventListener('load', setStateFromEvent);
        scriptNode.removeEventListener('error', setStateFromEvent);
      }

      if (scriptNode) {
        scriptNode.remove();
      }
    };
  }, []);

  return status;
}

export function useCookieConsentBanner(
  autoBlockCookiesStatus?: UseScriptStatus
) {
  const { COOKIES_CONSENT_ENABLED } = getEnvironmentVariables();
  const otSDKStubSrc =
    'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js';

  const [status, setStatus] = useState<UseScriptStatus>(() => {
    if (typeof window === 'undefined') {
      // SSR Handling - always return 'loading'
      return 'loading';
    }

    return cachedScriptStatuses[otSDKStubSrc] ?? 'loading';
  });

  useEffect(() => {
    if (autoBlockCookiesStatus === 'ready' && COOKIES_CONSENT_ENABLED) {
      const cachedScriptStatus = cachedScriptStatuses[otSDKStubSrc];
      if (cachedScriptStatus === 'ready' || cachedScriptStatus === 'error') {
        // If the script is already cached, set its status immediately
        setStatus(cachedScriptStatus);
        return;
      }

      // Fetch existing script element by src
      // It may have been added by another instance of this hook
      const script = getScriptNode(otSDKStubSrc);
      let scriptNode = script.node;

      if (!scriptNode) {
        // Create script element and add it to document body
        scriptNode = document.createElement('script');
        scriptNode.src = otSDKStubSrc;
        scriptNode.async = true;
        scriptNode.setAttribute('data-status', 'loading');
        scriptNode.setAttribute(
          'data-domain-script',
          'ce2a5111-ed3b-40c6-9c2b-4cd9d16e968c'
        );
        document.head.insertBefore(scriptNode, document.head.firstElementChild);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = (event: Event) => {
          const scriptStatus: UseScriptStatus =
            event.type === 'load' ? 'ready' : 'error';

          scriptNode?.setAttribute('data-status', scriptStatus);
        };

        scriptNode.addEventListener('load', setAttributeFromEvent);
        scriptNode.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.status ?? cachedScriptStatus ?? 'loading');
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = (event: Event) => {
        const newStatus = event.type === 'load' ? 'ready' : 'error';
        setStatus(newStatus);
        cachedScriptStatuses[otSDKStubSrc] = newStatus;
      };

      // Add event listeners
      scriptNode.addEventListener('load', setStateFromEvent);
      scriptNode.addEventListener('error', setStateFromEvent);

      // Remove event listeners on cleanup
      return () => {
        if (scriptNode) {
          scriptNode.removeEventListener('load', setStateFromEvent);
          scriptNode.removeEventListener('error', setStateFromEvent);
        }

        if (scriptNode) {
          scriptNode.remove();
        }
      };
    }
  }, [autoBlockCookiesStatus]);

  return status;
}
