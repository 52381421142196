import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@client';
import ListResponse from '../../../../../../../types/ListResponse';
import TableRequest from '../../../../../../..//types/TableRequest';
import {
  BuyerTeamDetailsResponseDto,
  UpdateTeamDetailsRequestDto,
  CreateTeamDetailsRequestDto
} from './models';

interface FetchBuyerTeamsRequest {
  buyerId: number;
  params: TableRequest;
}

export const buyerTeamsApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['BuyerTeams'],
  reducerPath: 'buyerTeamsApi_api',
  endpoints: (build) => ({
    fetchBuyerTeams: build.query<
      ListResponse<BuyerTeamDetailsResponseDto>,
      FetchBuyerTeamsRequest
    >({
      query: ({ buyerId, params }) => ({
        url: `secured/buyers/${buyerId}/teams`,
        method: 'GET',
        params
      })
    }),
    fetchBuyerTeam: build.query<
      BuyerTeamDetailsResponseDto,
      { teamId: number; buyerId: number }
    >({
      query: ({ buyerId, teamId }) => ({
        url: `secured/buyers/${buyerId}/teams/${teamId}`,
        method: 'GET'
      })
    }),
    createBuyerTeam: build.mutation<
      BuyerTeamDetailsResponseDto,
      { buyerId: number; data: CreateTeamDetailsRequestDto }
    >({
      query: ({ buyerId, data }) => ({
        url: `secured/buyers/${buyerId}/teams`,
        method: 'POST',
        data
      }),
      async onQueryStarted({ buyerId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            buyerTeamsApi.util.updateQueryData(
              'fetchBuyerTeam',
              { teamId: data.id, buyerId },
              () => data
            )
          );
        } catch {
          //
        }
      }
    }),
    updateBuyerTeam: build.mutation<
      BuyerTeamDetailsResponseDto,
      { teamId: number; buyerId: number; data: UpdateTeamDetailsRequestDto }
    >({
      query: ({ teamId, buyerId, data }) => ({
        url: `secured/buyers/${buyerId}/teams/${teamId}`,
        method: 'PATCH',
        data
      }),
      async onQueryStarted({ teamId, buyerId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            buyerTeamsApi.util.updateQueryData(
              'fetchBuyerTeam',
              { teamId, buyerId },
              () => data
            )
          );
        } catch {
          //
        }
      }
    })
  })
});

export type StateWithBuyerTeamsApi = {
  [buyerTeamsApi.reducerPath]: ReturnType<typeof buyerTeamsApi.reducer>;
};

export const {
  useFetchBuyerTeamsQuery,
  useFetchBuyerTeamQuery,
  useCreateBuyerTeamMutation,
  useUpdateBuyerTeamMutation
} = buyerTeamsApi;
