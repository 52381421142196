import { SofSignatureData } from '../../../types';
import { Involvement } from '../graphql/generated';
import { sofRestApi } from './sofRestApi';

export const statementOfFactsApi = sofRestApi.injectEndpoints({
  endpoints: (build) => ({
    uploadAttachment: build.mutation<
      void,
      { portCallId: string; data: FormData }
    >({
      query: ({ portCallId, data }) => ({
        url: `/api/private/statement-of-facts/${portCallId}/attachments`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data
      })
    }),
    deleteAttachment: build.mutation<
      void,
      { portCallId: string; fileId: string }
    >({
      query: ({ portCallId, fileId }) => ({
        url: `/api/private/statement-of-facts/${portCallId}/attachments/${fileId}`,
        method: 'DELETE'
      })
    }),
    authenticateInvitee: build.query<
      string,
      { portCallId: string; uuid: string; collaboratorType: string }
    >({
      query: ({ portCallId, uuid, collaboratorType }) => ({
        url: `/api/public/invited/statement-of-facts/${portCallId}?inviteId=${uuid}&collaboratorType=${collaboratorType}`,
        method: 'GET',
        headers: { Authorization: '' }
      })
    }),
    fetchInvolvements: build.query<Involvement[], void>({
      query: () => ({
        url: `/api/private/ref-data/involvements`,
        method: 'GET'
      })
    }),
    inviteCollaborators: build.mutation<
      void,
      {
        portCallId: string | number;
        data: {
          involvement: Involvement;
          email: string;
          vesselName: string;
          voyageNumber: string;
          portName: string;
          countryName: string;
          eta: string;
          timezoneId: string;
          activities: string;
          cargoes: string;
        };
      }
    >({
      query: ({ portCallId, data }) => ({
        url: `/api/private/invites/port-calls/${portCallId}`,
        method: 'POST',
        data
      })
    }),
    resendMasterInvite: build.mutation<void, { portCallId: string | number }>({
      query: ({ portCallId }) => ({
        url: `/api/private/invites/port-calls/${portCallId}/resend`,
        method: 'POST'
      })
    }),
    fetchTimesheet: build.query<void, { portCallId: string | number }>({
      query: ({ portCallId }) => ({
        url: `/api/private/statement-of-facts/${portCallId}/timesheet`,
        method: 'GET'
      })
    }),
    fetchSignatureData: build.query<
      SofSignatureData,
      { portCallId: string | number; exportType: 'SIGNATURE_JSON' }
    >({
      query: ({ portCallId, exportType }) => ({
        url: `/api/private/statement-of-facts/${portCallId}?exportType=${exportType}`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'SOF_SIGNATURE_DATA' as const, id: arg.portCallId }]
          : ['SOF_SIGNATURE_DATA']
    }),
    uploadSofEventAttachment: build.mutation<
      void,
      { portCallId: string | number; eventId: string | number; data: FormData }
    >({
      query: ({ portCallId, eventId, data }) => ({
        url: `/api/private/statement-of-facts/${portCallId}/events/${eventId}/attachments`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data
      })
    }),
    deleteSofEventAttachment: build.mutation<
      void,
      {
        portCallId: string | number;
        eventId: string | number;
        attachmentId: string | number;
      }
    >({
      query: ({ portCallId, eventId, attachmentId }) => ({
        url: `/api/private/statement-of-facts/${portCallId}/events/${eventId}/attachments/${attachmentId}`,
        method: 'DELETE'
      })
    })
  }),
  overrideExisting: false
});

export const {
  useUploadAttachmentMutation,
  useDeleteAttachmentMutation,
  useAuthenticateInviteeQuery,
  useFetchInvolvementsQuery,
  useLazyFetchInvolvementsQuery,
  useInviteCollaboratorsMutation,
  useResendMasterInviteMutation,
  useFetchTimesheetQuery,
  useFetchSignatureDataQuery,
  useUploadSofEventAttachmentMutation,
  useDeleteSofEventAttachmentMutation,
  useLazyFetchSignatureDataQuery
} = statementOfFactsApi;

export const authenticateInviteeMatcher =
  statementOfFactsApi.endpoints.authenticateInvitee.matchFulfilled;
