import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@client';
import { SuppliersKypVerificationDto } from './models';

export const kypApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'kypApi_api',
  endpoints: (build) => ({
    fetchSuppliersKypVerification: build.query<
      SuppliersKypVerificationDto,
      { supplierId: number }
    >({
      query: ({ supplierId }) => ({
        url: `secured/companies/${supplierId}/kyp-verification`,
        method: 'GET'
      })
    }),
    updateSuppliersKypVerification: build.mutation<
      SuppliersKypVerificationDto,
      {
        supplierId: number;
        kypVerificationId: number;
        data: FormData;
      }
    >({
      query: ({ kypVerificationId, data }) => ({
        url: `/secured/kyp-verifications/${kypVerificationId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data
      }),
      async onQueryStarted({ supplierId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            kypApi.util.updateQueryData(
              'fetchSuppliersKypVerification',
              { supplierId },
              () => data
            )
          );
        } catch {
          //
        }
      }
    })
  })
});

export type StateWithKypApi = {
  [kypApi.reducerPath]: ReturnType<typeof kypApi.reducer>;
};

export const {
  useFetchSuppliersKypVerificationQuery,
  useUpdateSuppliersKypVerificationMutation
} = kypApi;
