import { useEffect, useState } from 'react';
import { webStorage } from '@common';
import usePsFetch from './ps-fetch';

const usePsCache = <T>(
  key: string,
  fetchApi: string,
  clearOnRefresh: boolean = true
) => {
  const psFetch = usePsFetch();
  const [value, setValue] = useState<T>();

  useEffect(() => {
    if (!!key) {
      if (clearOnRefresh) clear();
      if (!value) fetch().then((r) => setValue(r));
    }
  }, [key]);

  const get = (): Promise<T> => {
    return new Promise((resolve, reject) => {
      const val: T | null = webStorage.getItem(key);
      if (val) resolve(val);
      else reject();
    });
  };

  const set = (result: T) => {
    webStorage.setItem(key, result);
  };

  const clear = () => {
    webStorage.removeItem(key);
  };

  const fetch = (): Promise<T> => {
    return get()
      .then((result) => Promise.resolve(result))
      .catch(() => {
        return load();
      });
  };

  const load = (): Promise<T> => {
    return psFetch
      .get(fetchApi)
      .then((result: any | T) => {
        set(result);
        return Promise.resolve(result);
      })
      .catch((err) => {
        //
      });
  };

  return {
    values: value,
    fetch: fetch,
    isEmpty: !value || (Array.isArray(value) && value.length === 0)
  };
};

export default usePsCache;
