import get from 'lodash/get';
import has from 'lodash/has';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { PsSelectFieldHandler } from '../handlers';
import { FieldWrapperProps, RefData } from '../types';
import PsFieldWrapper from './ps-form-field-wrapper';

type Props = {
  name: string;
  entity?: any;
  isDisabled?: boolean;
  form: any;
  onChange?: (value: RefData) => any;
  placeholder?: string;
  isRequired?: boolean;
  options: RefData[] | undefined;
  isClearable?: boolean;
  isSmall?: boolean;
  isPortal?: boolean;
  isExpanded?: boolean;
  noOptionsMessage?: string;
  defaultValue?: RefData;
  ref?: any;
  forceClear?: boolean;
} & FieldWrapperProps;

const PsSelectField: React.FunctionComponent<Props> = React.forwardRef(
  (
    {
      children,
      placeholder,
      name,
      entity,
      isDisabled = false,
      form,
      onChange,
      isRequired = false,
      options,
      isClearable = false,
      isSmall = false,
      isExpanded = false,
      isPortal = false,
      defaultValue,
      noOptionsMessage = 'No Options!',
      forceClear = false,
      ...rest
    },
    ref: React.Ref<PsSelectFieldHandler>
  ) => {
    const [value, setValue] = useState<RefData | null>();
    const [defValue, setDefValue] = useState<RefData | null | undefined>();

    useImperativeHandle(ref, () => {
      return {
        reset: reset,
        clear: clear
      };
    });

    const getValue = () => {
      return has(entity, name) && !!options
        ? options.find((o) => o.value == get(entity, name))
        : null;
    };

    useEffect(() => {
      init();
    }, [entity, name, defaultValue, options]);

    useEffect(() => {
      if (!!forceClear) {
        clear();
      }
    }, [forceClear]);

    const error = get(form.errors, name);
    const hasError = !!error;

    const init = () => {
      const value = getValue();
      setValue(!!value ? value : defaultValue);
      setDefValue(!!value ? value : defaultValue);
      form.setValue(
        name,
        !!value ? get(value, 'value') : get(defaultValue, 'value')
      );
    };

    const reset = () => {
      form.setValue(name, get(defValue, 'value'));
      setValue(defValue);
    };

    const clear = () => {
      form.setValue(name, null);
      setValue(null);
    };

    const customStyles = {
      menuPortal: (provided: any, state: any) => {
        const zIndex = '50 !important';
        return { ...provided, zIndex };
      }
    };

    return (
      <PsFieldWrapper
        hasError={hasError}
        errorMessage={get(error, 'message')}
        isSmall={isSmall}
        isExpanded={isExpanded}
        {...rest}
      >
        <Controller
          name={name}
          control={form.control}
          rules={{ required: isRequired }}
          defaultValue={defValue}
          render={() => (
            <Select
              isDisabled={isDisabled}
              value={value}
              getOptionValue={(o) => o.value}
              onChange={(selected: any) => {
                form.setValue(name, get(selected, 'value'));
                setValue(selected);
                if (onChange) onChange(selected);
              }}
              placeholder={placeholder}
              styles={customStyles}
              options={options}
              noOptionsMessage={() => noOptionsMessage}
              isClearable={isClearable}
              escapeClearsValue={isClearable}
              classNamePrefix="custom-select"
              menuPortalTarget={isPortal ? document.body : undefined}
              className={hasError ? 'is-danger' : ''}
              register={form.register()}
            />
          )}
        />
      </PsFieldWrapper>
    );
  }
);

export default PsSelectField;
