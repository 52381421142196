import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { StyleLoadingAttributes, StyleStateAttributes } from '../../types';
import PsStyle from '../ps-style';

export interface ButtonProps
  extends StyleStateAttributes,
    StyleLoadingAttributes,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  link?: string;
}

const PsButton: React.FunctionComponent<ButtonProps> = ({
  children = 'button-name',
  onClick,
  link,
  className,
  ...props
}): JSX.Element => {
  const history = useHistory();
  const classes =
    'button' +
    PsStyle.applyState(props) +
    PsStyle.applyLoading(props) +
    PsStyle.applyClassName(className);

  return (
    <button
      className={classes}
      onClick={!!link ? () => history.push(link) : onClick}
      type={!!props.type ? props.type : 'button'}
      {...PsStyle.extractProperties(props)}
    >
      {children}
    </button>
  );
};

export default PsButton;
