import { secureRefSplitApi } from './ref-api';
import { RefNumberData } from 'components';

const accountingCodeApplicationTypesApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchAccountingCodeApplicationTypes: build.query<RefNumberData[], void>({
      query: () => ({
        url: '/accountingCodeApplicationTypes',
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchAccountingCodeApplicationTypesQuery } =
  accountingCodeApplicationTypesApi;
