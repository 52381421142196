import PsLoadingPlaceholder, {
  LoadingTemplate
} from '../components/ps-loading-placeholder';
import React, { ForwardedRef } from 'react';

interface WithLoadingProps {
  forwardedRef: ForwardedRef<any>;
  children: any;
  isLoading: boolean;
}

export const WithLoading = (
  WrappedComponent: React.FunctionComponent<any>,
  template: LoadingTemplate
) => {
  const WithLoadingComponent: (props: WithLoadingProps & any) => any = ({
    forwardedRef,
    children,
    isLoading,
    ...props
  }) => {
    return (
      <WrappedComponent ref={forwardedRef} {...props}>
        {isLoading ? PsLoadingPlaceholder.parse(template) : children}
      </WrappedComponent>
    );
  };

  return React.forwardRef((props: WithLoadingProps & any, ref) => {
    return <WithLoadingComponent {...props} forwardedRef={ref} />;
  });
};
