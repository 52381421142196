import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/query/react';
import { RefData } from '@state';

export const currencyApi = createApi({
  reducerPath: '',
  baseQuery: axiosBaseQuery({ baseUrl: '/secured/currency' }),
  endpoints: (build) => ({
    fetchPortSupportedCurrencies: build.query<RefData[], { portId: number }>({
      query: ({ portId }) => ({
        url: `/port-supported-currencies/${portId}`,
        method: 'GET'
      })
    })
  })
});

export const { useFetchPortSupportedCurrenciesQuery } = currencyApi;
