import { EntityState, createEntityAdapter } from '@reduxjs/toolkit';

export type SortingState = {
  direction?: 'ASC' | 'DESC';
  property?: string;
};

export type PagingState = {
  page: number;
  size: number;
};

export type Filter = {
  name: string;
  value: string | string[] | number | number[] | boolean;
};

// Normalized filter collection, see the docs:
// https://redux.js.org/style-guide/#normalize-complex-nestedrelational-state
// https://redux-toolkit.js.org/api/createEntityAdapter
// https://redux-toolkit.js.org/api/createEntityAdapter#examples
export type FiltersState = { filters: EntityState<Filter> };

export type TableState = SortingState & PagingState & FiltersState;

export const filtersAdapter = createEntityAdapter<Filter>({
  selectId: (filter) => filter.name
});
