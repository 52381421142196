import {
  AdminPath,
  AgentProductPath,
  ModulePath,
  ProfilePath,
  SecuredPath,
  VendorProductPath,
  BuyerProductPath
} from '../../../../common/constants';
import { Authority } from '../../../module/authority/model/Authority';
import { Product } from '../../../module/product/model/Product';
import { ProfileAuthorityResponse } from '../../../module/profile/model/ProfileAuthorityResponse';
import { ProfileResponse } from '../../../module/profile/model/ProfileResponse';

export const hasProduct = (products: Product[], product: Product) => {
  return (products || []).some((p) => p === product);
};

export const hasAuthority = (
  authorities: ProfileAuthorityResponse[],
  authority: Authority
) => {
  return (authorities || []).some(
    (auth) => auth.isEnabled && auth.authority === authority
  );
};

export const hasProductAndAuthority = (
  profile: ProfileResponse,
  product: Product,
  authority: Authority
) => {
  return (
    hasProduct(profile.products, product) &&
    hasAuthority(profile.authorities, authority)
  );
};

export const pathOptions: Array<{
  product: Product;
  authority: Authority;
  path: BuyerProductPath;
}> = [
  {
    product: Product.DASHBOARD,
    authority: Authority.DA_PORT_CALL_DASHBOARD,
    path: BuyerProductPath.DASHBOARD
  },
  {
    product: Product.DA_TOOL,
    authority: Authority.DA_VIEW,
    path: BuyerProductPath.DA_TOOL
  },
  {
    product: Product.POSITION_LIST,
    authority: Authority.POSITION_LIST_VIEW,
    path: BuyerProductPath.POSITION_LIST
  },
  {
    product: Product.PORT_ESTIMATOR,
    authority: Authority.PORT_ESTIMATOR_VIEW,
    path: BuyerProductPath.PORT_ESTIMATOR
  },
  {
    product: Product.PORT_DIRECTORY,
    authority: Authority.PORT_DIRECTORY_VIEW,
    path: BuyerProductPath.PORT_DIRECTORY
  },
  {
    product: Product.AGENT_DIRECTORY,
    authority: Authority.AGENT_DIRECTORY_VIEW,
    path: BuyerProductPath.AGENT_DIRECTORY
  },
  {
    product: Product.CLAIMS,
    authority: Authority.CLAIMS_VIEW,
    path: BuyerProductPath.CLAIMS
  }
];

export const createAfterLoginPath = (
  profile: ProfileResponse | null | undefined,
  loginRedirectPathname?: string
) => {
  if (!profile) {
    return '/authorization';
  }
  if (loginRedirectPathname) return loginRedirectPathname;
  if (profile.isAgent) {
    return AgentProductPath.DA_TOOL;
  }
  if (profile.isVendor) {
    return VendorProductPath.DA_TOOL;
  }
  if (profile.isBuyer) {
    const path =
      pathOptions.find(({ product, authority }) =>
        hasProductAndAuthority(profile, product, authority)
      )?.path || `/${SecuredPath.SECURED}/${ProfilePath.BUYER}`;
    return path;
  }
  if (profile.isAdmin) {
    return AdminPath.BASE;
  }
  return '/authorization';
};

export const createAfterLoginSearchParams = (
  loginRedirectSearchParams?: string
) => {
  if (loginRedirectSearchParams) return loginRedirectSearchParams;

  return undefined;
};

export const createProfilePath = (
  profile: ProfileResponse | null | undefined
) => {
  if (profile && profile.isBuyer) {
    return ProfilePath.BUYER;
  }
  if (profile && profile.isAgent) {
    return ProfilePath.AGENT;
  }
  if (profile && profile.isVendor) {
    return ProfilePath.VENDOR;
  }
  if (profile && profile.isAdmin) {
    return ProfilePath.ADMIN;
  }

  return 'not-found';
};

export const createPathTo = (
  profile: ProfileResponse | null | undefined,
  modulePath: ModulePath
) => {
  return `/${SecuredPath.SECURED}/${createProfilePath(profile)}/${modulePath}`;
};

export const createDAToolBasePath = (
  profile: ProfileResponse | null | undefined
) => {
  return createPathTo(profile, ModulePath.DA_TOOL);
};

export const createSettingsBasePath = (
  profile: ProfileResponse | null | undefined
) => {
  return createPathTo(profile, ModulePath.SETTINGS);
};
