import * as React from 'react';
import ReactDOM from 'react-dom';
import App from './app/sitemap/App';
import './sass/main.scss';

ReactDOM.render(<App />, document.getElementById('root'));

let newWorker: ServiceWorker | null;

function showUpdateBar() {
  let snackbar = document.getElementById('snackbar');
  snackbar && (snackbar.className = 'show');
}

// The click event on the pop up notification
document.getElementById('reload')?.addEventListener('click', function () {
  newWorker?.postMessage({ action: 'skipWaiting' });
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistration().then((reg) => {
    if (!reg) {
      return;
    }
    reg.addEventListener('updatefound', () => {
      // A wild service worker has appeared in reg.installing!
      newWorker = reg.installing;

      newWorker?.addEventListener('statechange', () => {
        // Has network.state changed?
        switch (newWorker?.state) {
          case 'installed':
            if (navigator.serviceWorker.controller) {
              // new update available
              showUpdateBar();
            }
            // No update available
            break;
        }
      });
    });
  });

  let refreshing: boolean;
  navigator.serviceWorker.addEventListener('controllerchange', function () {
    if (refreshing) return;
    window.location.reload();
    refreshing = true;
  });
}
