import { mainRestApi } from '../rest-api';

export interface SupplierProfileForm {
  id?: string;
  username?: string;
  name: string;
  surname: string;
  email: string;
}

export const supplierApi = mainRestApi.injectEndpoints({
  endpoints: (build) => ({
    saveSupplierProfileSettings: build.mutation<
      SupplierProfileForm,
      SupplierProfileForm
    >({
      query: (data) => ({
        url: '/secured/supplier/settings/profile/save',
        method: 'POST',
        data
      })
    })
  })
});

export const { useSaveSupplierProfileSettingsMutation } = supplierApi;
