import {
  StateWithPortTerminalsState,
  PortTerminalsState,
  portTerminalsTableSlice
} from './reducers';
import { filtersAdapter } from '../../../../types';

export const selectPortTerminalsState = (state: StateWithPortTerminalsState) =>
  state[portTerminalsTableSlice.name];

const filterSelectors = filtersAdapter.getSelectors<PortTerminalsState>(
  (state) => state.filters
);

export const selectPortTerminalsFilters = (
  state: StateWithPortTerminalsState
) => {
  return filterSelectors.selectAll(selectPortTerminalsState(state));
};

export const selectPortTerminalsFilterBy =
  (name: string) => (state: StateWithPortTerminalsState) => {
    return filterSelectors.selectById(selectPortTerminalsState(state), name);
  };
