import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@client';
import TableRequest from '../../../../../../types/TableRequest';
import { TerminalResponse } from '@module/terminal/model/TerminalResponse';

interface FetchPortTerminalsRequest {
  portId: number;
  params: TableRequest;
}

export const portsApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['PortAnalysisPorts'],
  reducerPath: 'portAnalysisPorts_api',
  endpoints: (build) => ({
    fetchPortTerminals: build.query<
      TerminalResponse[],
      FetchPortTerminalsRequest
    >({
      query: ({ portId, params }) => ({
        url: `/secured/ports/${portId}/terminals`,
        method: 'GET',
        params
      })
    })
  })
});

export const { useFetchPortTerminalsQuery } = portsApi;
