import { useRef } from 'react';
import { PsModalHandler } from '../handlers';

const usePsModal = () => {
  const modal = useRef<PsModalHandler>(null);

  const open = () => {
    modal.current!.open();
  };

  const close = () => {
    modal.current!.close();
  };

  return {
    ref: modal,
    open: open,
    close: close
  };
};

export default usePsModal;
