import {
  PERSIST_KEY,
  SESSION,
  getEnvironmentVariables,
  webStorage
} from '@common';
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios';
import { Auth0Client } from '@auth0/auth0-spa-js';

const { AUTH0_DOMAIN, AUTH0_CLIENT_ID } = getEnvironmentVariables();

export const DEFAULT_HEADERS: Partial<AxiosHeaders> = {
  Accept: '*/*',
  'Cache-Control': 'max-age=0'
};

export interface RequestOptions<T = unknown, E = unknown, D = unknown>
  extends AxiosRequestConfig<D> {
  onSuccess?: (response: AxiosResponse<T, D>) => void;
  onError?: (error: AxiosError<E, D>) => void;
  onAbort?: (error: AxiosError<E, D>) => void;
  onComplete?: () => void;
}

interface RestfulClientProps {
  headers: AxiosRequestHeaders;
}

export class RestfulClient {
  private axiosInstance: AxiosInstance;

  constructor(props?: RestfulClientProps) {
    const headers = props?.headers ?? DEFAULT_HEADERS;
    const baseURL = `${getEnvironmentVariables().API_URL}/da-app`;
    this.axiosInstance = axios.create({
      baseURL,
      headers
    });
  }

  addInterceptor<T>(interceptor: {
    onRequest?: {
      onFulfilled?:
        | ((
            value: InternalAxiosRequestConfig<any>
          ) =>
            | InternalAxiosRequestConfig<any>
            | Promise<InternalAxiosRequestConfig<any>>)
        | null;
      onRejected?: (error: AxiosError) => unknown;
    };
    onResponse?: {
      onFulfilled?: (
        value: AxiosResponse<T, InternalAxiosRequestConfig<any>>
      ) =>
        | AxiosResponse<T, InternalAxiosRequestConfig<any>>
        | Promise<AxiosResponse<T, InternalAxiosRequestConfig<any>>>;
      onRejected?: ((error: any) => any) | null;
    };
  }) {
    this.axiosInstance.interceptors.request.use(
      interceptor.onRequest?.onFulfilled,
      interceptor.onRequest?.onRejected
    );

    if (interceptor?.onResponse) {
      this.axiosInstance.interceptors.response.use(
        interceptor.onResponse.onFulfilled,
        interceptor.onResponse.onRejected
      );
    }
  }

  private handleSuccessfulResponse<T = unknown, D = unknown>(
    response: AxiosResponse<T, D>,
    callbackFn?: (response: AxiosResponse<T, D>) => any
  ): T | any {
    if (callbackFn) {
      return callbackFn(response);
    }
    return response.data;
  }

  private handleFailureResponse<E, D, T>(
    error: AxiosError<E, D>,
    options: RequestOptions<T, E, D>
  ) {
    if (axios.isCancel(error)) {
      options.onAbort?.(error);
      return;
    }
    options.onError?.(error);
  }

  private async sendRequest<T = unknown, E = unknown, D = unknown>(
    requestOptions: RequestOptions<T, E, D>
  ) {
    return await this.axiosInstance
      .request(requestOptions)
      .then((response) =>
        this.handleSuccessfulResponse<T, D>(response, requestOptions.onSuccess)
      )
      .catch((error: AxiosError<E, D>) =>
        this.handleFailureResponse<E, D, T>(error, requestOptions)
      )
      .finally(requestOptions.onComplete);
  }

  /**
   * T is success response data type,
   * E is error response data type,
   * D is request data type
   */
  get<T = unknown, E = unknown, D = unknown>(config: RequestOptions<T, E, D>) {
    return this.sendRequest<T, E, D>({
      ...config,
      method: 'GET'
    });
  }

  /**
   * T is success response data type,
   * E is error response data type,
   * D is request data type
   */
  post<T = unknown, E = unknown, D = unknown>(config: RequestOptions<T, E, D>) {
    return this.sendRequest<T, E, D>({
      ...config,
      method: 'POST'
    });
  }

  /**
   * T is success response data type,
   * E is error response data type,
   * D is request data type
   */
  patch<T = unknown, E = unknown, D = unknown>(
    config: RequestOptions<T, E, D>
  ) {
    return this.sendRequest<T, E, D>({
      ...config,
      method: 'PATCH'
    });
  }

  /**
   * T is success response data type,
   * E is error response data type,
   * D is request data type
   */
  put<T = unknown, E = unknown, D = unknown>(config: RequestOptions<T, E, D>) {
    return this.sendRequest<T, E, D>({
      ...config,
      method: 'PUT'
    });
  }

  /**
   * T is success response data type,
   * E is error response data type,
   * D is request data type
   */
  delete<T = unknown, E = unknown, D = unknown>(
    config: RequestOptions<T, E, D>
  ) {
    return this.sendRequest<T, E, D>({
      ...config,
      method: 'DELETE'
    });
  }

  /**
   * T is success response data type,
   * E is error response data type,
   * D is request data type
   */
  multipart<T = unknown, E = unknown, D = unknown>(
    config: RequestOptions<T, E, D>
  ) {
    return this.sendRequest<T, E, D>({
      ...config,
      headers: {
        ...config.headers,
        'Content-Type': 'multipart/form-data'
      },
      method: 'POST'
    });
  }
}

const defaultClient = new RestfulClient();

const auth0 = new Auth0Client({
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin + '/authorization'
  }
});

export const logout = () => {
  defaultClient.post({
    url: '/secured/auth/logout',
    onComplete: () => {
      auth0.logout({
        logoutParams: { returnTo: window.location.origin + '/authorization' }
      });
    }
  });
};

export const authInterceptor = {
  onRequest: {
    onFulfilled: (config: InternalAxiosRequestConfig) => {
      const session = webStorage.getItem<{ token: string }>(SESSION);

      if (session) {
        config.headers.setAuthorization(`Bearer ${session.token}`);
      }

      return config;
    }
  }
};

export type ErrorHandlingInterceptorConfig = {
  handleUnauthorizedResponse?: boolean;
  handleUnauthenticatedResponse?: boolean;
  handleNotFoundResponse?: boolean;
};

export type InterceptorType = {
  onResponse: { onRejected: (error: AxiosError) => Promise<void> };
};

export const errorHandlingInterceptor = (
  config: ErrorHandlingInterceptorConfig = {
    handleUnauthorizedResponse: true,
    handleUnauthenticatedResponse: true,
    handleNotFoundResponse: true
  }
): InterceptorType => ({
  onResponse: {
    onRejected: async (error: AxiosError) => {
      const { response } = error;
      if (error.config?.url?.endsWith('logout')) {
        return Promise.resolve();
      }
      if (config.handleUnauthenticatedResponse && response?.status === 401) {
        webStorage.removeItem(SESSION);
        webStorage.removeItem(PERSIST_KEY);
        logout();
        return Promise.reject({
          ...error,
          response: {
            data: {
              message: 'Full authentication is required to access this resource'
            }
          }
        });
      } else if (
        config.handleUnauthorizedResponse &&
        response?.status === 403
      ) {
        document.location.href = '/error-no-access';
      } else if (config.handleNotFoundResponse && response?.status === 404) {
        document.location.href = '/error-not-found';
      }

      return Promise.reject(error);
    }
  }
});

defaultClient.addInterceptor(authInterceptor);
defaultClient.addInterceptor(errorHandlingInterceptor());

export default defaultClient;

export const isAuthenticated = auth0.isAuthenticated;
