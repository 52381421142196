import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ConversationsState = {
  pagination: {
    page: number;
    pageSize: number;
    filter: 'ALL' | 'UNREAD' | 'READ';
  };
  drawerOpened: boolean;
};

const initialState: ConversationsState = {
  pagination: { page: 0, pageSize: 14, filter: 'ALL' },
  drawerOpened: false
};

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<number>) => {
      state.pagination.page = payload;
    },
    updateFilter: (
      state,
      { payload }: PayloadAction<'ALL' | 'UNREAD' | 'READ'>
    ) => {
      state.pagination.filter = payload;
      state.pagination.page = 0;
    },
    openDrawer: (state) => {
      state.drawerOpened = true;
    }
  }
});

export const {
  updatePage: updateConversationsPage,
  updateFilter: updateConversationsFilter,
  openDrawer: openMessageDrawer
} = conversationsSlice.actions;

export type StateWithConversations = {
  [conversationsSlice.name]: ConversationsState;
};
