import {
  AppointmentState,
  appointmentReducerName,
  appointmentsWizardSlice,
  deleteDraftAppointmentSlice,
  previewAppointmentSlice
} from './reducers';

type StateWithAppointment = {
  [appointmentReducerName]: AppointmentState;
};

export const selectAppointmentState = (state: StateWithAppointment) =>
  state[appointmentReducerName];

export const selectPreviewAppointmentState = (state: StateWithAppointment) =>
  selectAppointmentState(state)[previewAppointmentSlice.name];

export const selectWizardAppointmentState = (state: StateWithAppointment) =>
  selectAppointmentState(state)[appointmentsWizardSlice.name];

export const selectWizardAppointmentIsProccessing = (
  state: StateWithAppointment
) => selectWizardAppointmentState(state).isProcessing;

export const selectWizardVoyage = (state: StateWithAppointment) =>
  selectWizardAppointmentState(state).voyage;

export const selectWizardVessel = (state: StateWithAppointment) =>
  selectWizardAppointmentState(state).vessel;

export const selectDeleteAppointmentState = (state: StateWithAppointment) =>
  selectAppointmentState(state)[deleteDraftAppointmentSlice.name];
