import React, { memo, useEffect } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import {
  selectIsAuthenticated,
  setPostLoginRedirectLocation,
  useAppDispatch,
  useAppSelector
} from '@state';

const PrivateRoute: React.FunctionComponent<RouteProps> = ({
  children,
  ...properties
}) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { pathname, search } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAuthenticated)
      dispatch(
        setPostLoginRedirectLocation({
          pathname,
          searchParams: search
        })
      );
  }, [isAuthenticated, dispatch, pathname, search]);

  const render: RouteProps['render'] = ({ location }) =>
    isAuthenticated ? (
      <>{children}</>
    ) : (
      <Redirect
        to={{ pathname: '/authorization', state: { from: location } }}
      />
    );

  return <Route {...properties} render={render} />;
};

export default memo(PrivateRoute);
