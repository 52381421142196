import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { AppointmentResponse } from '../../../module/appointment/model/AppointmentResponse';
import { AppointmentEmailConfigurationResponse } from '../../../module/appointment/model/AppointmentEmailConfigurationResponse';
import { BuyerAddressAndContactResponse } from '../../../module/buyer/model/BuyerAddressAndContactResponse';
import { BillingCompanyResponse } from '../../../module/billing-company/model/BillingCompanyResponse';
import { PortCallAppointmentsResponse } from '../../../module/port-call/model/PortCallAppointmentsResponse';
import { PortCallResponse } from '../../../module/port-call/model/PortCallResponse';
import { FileItem } from 'components';
import { VoyageResponseWithForm } from '../../../module/voyage/model/VoyageResponseWithForm';
import { VesselResponse } from '../../../module/vessel/model/VesselResponse';

export interface PreviewAppointmentState {
  isLoading: boolean;
  isProcessing: boolean;
  appointment: AppointmentResponse | null;
  vessel: VesselResponse | null;
  message: string | null;
  billingCompany: BillingCompanyResponse | null;
  buyer: BuyerAddressAndContactResponse | null;
  attachments: FileItem[] | null;
}

export interface AppointmentsWizardState {
  isLoading: boolean;
  isProcessing: boolean;
  vessel: VesselResponse | null;
  voyage: VoyageResponseWithForm | null;
  portCall: PortCallResponse | null;
  appointments: PortCallAppointmentsResponse | null;
  messages: AppointmentEmailConfigurationResponse[] | null;
  selected: AppointmentEmailConfigurationResponse | null;
  preview: AppointmentEmailConfigurationResponse | null;
}

export interface DeleteDraftAppointmentState {
  isProcessing: boolean;
}

const initialDeleteDraftAppointmentState: DeleteDraftAppointmentState = {
  isProcessing: false
};

export const deleteDraftAppointmentSlice = createSlice({
  name: 'delete',
  initialState: initialDeleteDraftAppointmentState,
  reducers: {
    inProcess: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    }
  }
});

const initialPreviewAppointmentState: PreviewAppointmentState = {
  isLoading: false,
  isProcessing: false,
  appointment: null,
  vessel: null,
  message: null,
  billingCompany: null,
  buyer: null,
  attachments: null
};

export const previewAppointmentSlice = createSlice({
  name: 'preview',
  initialState: initialPreviewAppointmentState,
  reducers: {
    isLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAppointment: (
      state,
      action: PayloadAction<AppointmentResponse | null>
    ) => {
      state.appointment = action.payload;
    },
    setVessel: (state, action: PayloadAction<VesselResponse | null>) => {
      state.vessel = action.payload;
    },
    setMessage: (state, action: PayloadAction<string | null>) => {
      state.message = action.payload;
    },
    setBillingCompany: (
      state,
      action: PayloadAction<BillingCompanyResponse | null>
    ) => {
      state.billingCompany = action.payload;
    },
    setBuyer: (
      state,
      action: PayloadAction<BuyerAddressAndContactResponse | null>
    ) => {
      state.buyer = action.payload;
    },
    setAttachments: (state, action: PayloadAction<FileItem[] | null>) => {
      state.attachments = action.payload;
    }
  }
});

const initialAppointmentWizardState: AppointmentsWizardState = {
  isLoading: false,
  isProcessing: false,
  vessel: null,
  voyage: null,
  portCall: null,
  appointments: null,
  messages: null,
  selected: null,
  preview: null
};

export const appointmentsWizardSlice = createSlice({
  name: 'wizard',
  initialState: initialAppointmentWizardState,
  reducers: {
    isLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    inProcess: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    setVessel: (state, action: PayloadAction<VesselResponse | null>) => {
      state.vessel = action.payload;
    },
    setVoyage: (
      state,
      action: PayloadAction<VoyageResponseWithForm | null>
    ) => {
      state.voyage = action.payload;
    },
    changeVoyagePortCall: (state, action: PayloadAction<PortCallResponse>) => {
      const pc = action.payload;

      const copy = JSON.parse(JSON.stringify(state.voyage));
      if (copy && copy.portCalls) {
        const idx = copy.portCalls.findIndex((p: any) => p.id == pc.id);
        if (idx > -1) {
          copy.portCalls[idx] = pc;
          state.voyage = copy;
        }
      }
    },
    setPortCall: (state, action: PayloadAction<PortCallResponse | null>) => {
      state.portCall = action.payload;
    },
    setAppointments: (
      state,
      action: PayloadAction<PortCallAppointmentsResponse | null>
    ) => {
      state.appointments = action.payload;
    },
    setMessages: (
      state,
      action: PayloadAction<AppointmentEmailConfigurationResponse[]>
    ) => {
      state.messages = action.payload;
    },
    setMessage: (
      state,
      action: PayloadAction<AppointmentEmailConfigurationResponse>
    ) => {
      if (state.messages) {
        const messages = [...state.messages];
        const idx = messages.findIndex((m) => m.id === action.payload.id);
        if (idx > -1) {
          messages[idx] = action.payload;
          state.messages = messages;
        }
      }
    },
    setSelected: (
      state,
      action: PayloadAction<AppointmentEmailConfigurationResponse | null>
    ) => {
      state.selected = action.payload;
      state.preview = action.payload;
    },
    setPreview: (
      state,
      action: PayloadAction<AppointmentEmailConfigurationResponse | null>
    ) => {
      state.preview = action.payload;
    },
    reset: (state) => {
      state.vessel = null;
      state.voyage = null;
      state.portCall = null;
      state.appointments = null;
      state.messages = null;
      state.preview = null;
      state.selected = null;
    }
  }
});

export const appointmentsReducer = combineReducers({
  [previewAppointmentSlice.name]: previewAppointmentSlice.reducer,
  [appointmentsWizardSlice.name]: appointmentsWizardSlice.reducer,
  [deleteDraftAppointmentSlice.name]: deleteDraftAppointmentSlice.reducer
});

export default appointmentsReducer;

export const appointmentReducerName = 'appointment';

export type AppointmentState = ReturnType<typeof appointmentsReducer>;
