import { getEnvironmentVariables } from '@common';

const usePsContext = () => {
  const env = getEnvironmentVariables();

  return {
    api: env.API_URL,
    path: '/da-app',
    pathIntg: '/integration-veson-tma/api',
    s3PublicUrl: env.S3_PUBLIC_URL
  };
};

export default usePsContext;
