import get from 'lodash/get';
import React, { Ref, useEffect, useImperativeHandle, useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { PsNumberFieldHandler } from '../handlers';
import { FieldWrapperProps } from '../types';
import PsFieldWrapper from './ps-form-field-wrapper';
import Tooltip from 'rc-tooltip';

type Props = {
  name: string;
  entity?: any;
  isDisabled?: boolean;
  form: any;
  onBlur?: (value: string | number | undefined) => any;
  placeholder?: string;
  isRequired?: boolean;
  isMonetary?: boolean;
  allowNegative?: boolean;
  decimalScale?: number;
  fixedDecimalScale?: boolean;
  thousandSeparator?: boolean;
  defaultValue?: number;
  isAllowed?: (values: NumberFormatValues) => boolean;
  onValueChange?: (values: NumberFormatValues) => void;
  runOnInit?: (value: number) => void;
  onKeyDown?: any;
  ref?: any;
  dataTestId?: string;
  tooltip?: string;
} & FieldWrapperProps;

const PsNumberField: React.FunctionComponent<Props> = React.forwardRef(
  (
    {
      children,
      placeholder,
      name,
      entity,
      isDisabled = false,
      form,
      onBlur,
      isRequired = false,
      isMonetary = false,
      allowNegative = false,
      decimalScale = 2,
      fixedDecimalScale = false,
      thousandSeparator = false,
      defaultValue,
      isAllowed,
      onValueChange,
      onKeyDown,
      runOnInit,
      dataTestId,
      tooltip,
      ...rest
    },
    ref: Ref<PsNumberFieldHandler>
  ) => {
    const [value, setValue] = useState<string | number>();
    const [defValue, setDefValue] = useState<string | number>();

    useImperativeHandle(ref, () => {
      return {
        reset: reset
      };
    });

    useEffect(() => {
      init();
    }, [entity, name, defaultValue]);

    useEffect(() => {
      if (runOnInit) {
        if (defaultValue) {
          runOnInit(defaultValue);
        }
      }
    }, [defaultValue, runOnInit]);

    const error = get(form.errors, name);
    const hasError = !!error;

    const init = () => {
      const value = get(entity, name);
      const hasValue = !!value || value === 0;
      setValue(hasValue ? value : defaultValue);
      setDefValue(hasValue ? value : defaultValue);
      form.setValue(name, hasValue ? value : defaultValue);
    };

    const reset = () => {
      form.setValue(name, defValue);
      setValue(defValue);
    };

    const numberFormatComponent = (
      <NumberFormat
        type="text"
        name={name}
        data-testid={dataTestId}
        className={'input' + (hasError ? ' is-danger' : '')}
        placeholder={placeholder}
        disabled={isDisabled}
        defaultValue={defaultValue}
        allowNegative={isMonetary ? false : allowNegative}
        decimalScale={isMonetary ? 2 : decimalScale}
        fixedDecimalScale={isMonetary ? true : fixedDecimalScale}
        thousandSeparator={isMonetary ? true : thousandSeparator}
        isAllowed={isAllowed}
        onValueChange={(values) => {
          form.setValue(name, get(values, 'floatValue'));
          setValue(get(values, 'floatValue'));
          if (onValueChange) onValueChange(values);
        }}
        onKeyDown={onKeyDown}
        onBlur={() => !!onBlur && onBlur(value)}
        value={value}
      />
    );

    return (
      <PsFieldWrapper
        hasError={hasError}
        errorMessage={get(error, 'message')}
        {...rest}
      >
        <Controller
          name={name}
          control={form.control}
          rules={{ required: isRequired }}
          defaultValue={defaultValue}
          render={() =>
            tooltip ? (
              <Tooltip overlay={tooltip} trigger="hover" placement="top">
                <div>{numberFormatComponent}</div>
              </Tooltip>
            ) : (
              numberFormatComponent
            )
          }
        />
      </PsFieldWrapper>
    );
  }
);

export default PsNumberField;
