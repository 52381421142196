import React, {
  useImperativeHandle,
  useRef,
  useState,
  PropsWithChildren
} from 'react';
import { useForm } from 'react-hook-form';
import { PsPagingHandler, PsTableFilterFormHandler } from '../handlers';
import { FormState, SingleValueFilterItem } from '../types';

interface Props extends PropsWithChildren {
  paging: PsPagingHandler<any>;
  ref: any;
}

const PsTableFilterForm: React.FunctionComponent<Props> = React.forwardRef(
  ({ children, paging }, ref: React.Ref<PsTableFilterFormHandler>) => {
    const form = useForm();
    const formElement = useRef<any>();
    const [state, setState] = useState<FormState>('SUBMIT');

    useImperativeHandle(ref, () => {
      return {
        form: form,
        reset: () => reset(0),
        submit: form.handleSubmit(onSubmit),
        findFilterValue: (name: string) => findFilterValue(name),
        hasFilterValue: (name: string) => hasFilterValue(name),
        getState: () => {
          return state;
        }
      };
    });

    const reset = (to: number) => {
      formElement.current!.reset();
      paging.reset(to);
      setState('RESET');
    };

    const findFilterValue = (name: string) => {
      const filter = paging.findFilter(name);
      return !!filter ? filter.value : '';
    };

    const hasFilterValue = (name: string) => {
      return paging.hasFilter(name);
    };

    const onSubmit = (data: Record<string, string>) => {
      setState('SUBMIT');
      const filters: SingleValueFilterItem[] = [];
      const keyNames = Object.keys(data);
      if (keyNames) {
        keyNames.forEach((name) => {
          filters.push({ name: name, value: data[name] });
        });
        paging.filters(filters);
      }
    };

    return (
      <form
        id="filterForm"
        onSubmit={form.handleSubmit(onSubmit)}
        ref={formElement}
      >
        {children}
      </form>
    );
  }
);
export default PsTableFilterForm;
