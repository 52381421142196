import { usePsFetch } from 'components/components/index';
import { FilePondFile } from 'filepond';

const fetch = usePsFetch();

const registerDownloadComponent = (
  item: any,
  el: any,
  labelButtonDownload: string,
  allowDownloadByUrl: boolean
) => {
  const info = el.querySelector('.filepond--file-info'),
    downloadIcon = getDownloadIcon(labelButtonDownload);

  info.prepend(downloadIcon);
  downloadIcon.addEventListener('click', () =>
    downloadFile(item, allowDownloadByUrl)
  );
};

const getDownloadIcon = (labelButtonDownload: string) => {
  let icon = document.createElement('span');
  icon.className = 'filepond--download-icon';
  icon.title = labelButtonDownload;
  return icon;
};

const downloadFile = (
  item: Omit<FilePondFile, 'file'> & {
    file: File & { load?: () => Promise<Blob> };
  },
  allowDownloadByUrl: boolean
) => {
  // if client want to download file from remote server
  if (allowDownloadByUrl && item.getMetadata('url')) {
    location.href = item.getMetadata('url'); // full path to remote server is stored in metadata with key 'url'
    return;
  }

  // if load function is specified, load and then create temp hyperlink
  if (item.file.load) {
    item.file.load().then((res) => {
      createTempHyperLink(
        item.file.name,
        window.URL.createObjectURL(new Blob([res], { type: item.file.type }))
      );
    });
    return;
  }

  createTempHyperLink(item.file.name, window.URL.createObjectURL(item.file));
};

// create a temporary hyperlink to force the browser to download the file
const createTempHyperLink = (name: string, url: string) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  a.href = url;
  a.download = name;
  a.click();

  window.URL.revokeObjectURL(url);
  a.remove();
};

const fileDownloadPlugin = (fpAPI: any) => {
  const { addFilter, utils } = fpAPI;
  const { Type, createRoute } = utils;

  // called for each view that is created right after the 'create' method
  addFilter('CREATE_VIEW', (viewAPI: any) => {
    // get reference to created view
    const { is, view, query } = viewAPI;

    // only hook up to item view
    if (!is('file')) {
      return;
    }

    // create the get file plugin
    // @ts-ignore
    const didLoadItem = ({ root, props }) => {
      const { id } = props;
      const item = query('GET_ITEM', id);

      if (!item || item.archived) {
        return;
      }

      const labelButtonDownload = root.query('GET_LABEL_BUTTON_DOWNLOAD_ITEM');

      const allowDownloadByUrl = root.query('GET_ALLOW_DOWNLOAD_BY_URL');

      registerDownloadComponent(
        item,
        root.element,
        labelButtonDownload,
        allowDownloadByUrl
      );
    };

    // start writing
    view.registerWriter(
      createRoute(
        {
          DID_LOAD_ITEM: didLoadItem
        },
        // @ts-ignore
        ({ root, props }) => {
          const { id } = props;
          const item = query('GET_ITEM', id);

          // don't do anything while hidden
          if (root.rect.element.hidden) return;
        }
      )
    );
  });

  // expose plugin
  return {
    options: {
      labelButtonDownloadItem: ['Download file', Type.STRING],
      allowDownloadByUrl: [false, Type.BOOLEAN]
    }
  };
};

// fire pluginloaded event if running in browser, this allows registering the plugin when using async script tags
const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';
if (isBrowser) {
  document.dispatchEvent(
    new CustomEvent('FilePond:pluginloaded', { detail: fileDownloadPlugin })
  );
}

export default fileDownloadPlugin;
