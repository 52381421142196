import { createApi } from '@reduxjs/toolkit/query/react';
import { invalidateLoadAppointment, PortCallResponse } from '@state';
import { axiosBaseQuery } from '@client';
import { LinkImportedToPortCallRequest } from '@module/buyer/model/LinkImportedToPortCallRequest';

export const portCallApi = createApi({
  reducerPath: 'port-call-api',
  tagTypes: ['portCallApi'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    linkPortCall: builder.mutation<
      PortCallResponse,
      { portCallId: string; request: LinkImportedToPortCallRequest }
    >({
      query: ({ portCallId, request }) => ({
        method: 'POST',
        url: `/secured/buyer/port-call/${portCallId}/link-imported-port-call`,
        data: request
      })
    })
  })
});

export type StateWithLinkedPortCallApi = {
  [portCallApi.reducerPath]: ReturnType<typeof portCallApi.reducer>;
};

export const { useLinkPortCallMutation } = portCallApi;
