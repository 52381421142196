import { secureSplitApi } from './ref-api';

const vesselTypesApi = secureSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchVesselTypes: build.query<{ label: string; value: string }[], void>({
      query: () => ({
        url: 'vessel-types-ref-data',
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchVesselTypesQuery } = vesselTypesApi;
