import {
  StateWithCharterersState,
  charterersTableSlice,
  CharterersState,
  ChartererVesselTypesState,
  StateWithChartererVesselTypesState,
  chartererVesselTypesTableSlice
} from './reducers';
import { filtersAdapter } from '../../../../types';

export const selectCharterersState = (state: StateWithCharterersState) =>
  state[charterersTableSlice.name];

const filterSelectors = filtersAdapter.getSelectors<CharterersState>(
  (state) => state.filters
);

export const selectCharterersFilters = (state: StateWithCharterersState) => {
  return filterSelectors.selectAll(selectCharterersState(state));
};

export const selectCharterersFilterBy =
  (name: string) => (state: StateWithCharterersState) => {
    return filterSelectors.selectById(selectCharterersState(state), name);
  };

export const selectChartererVesselTypesState = (
  state: StateWithChartererVesselTypesState
) => state[chartererVesselTypesTableSlice.name];

const chartererVesselTypesFilterSelectors =
  filtersAdapter.getSelectors<ChartererVesselTypesState>(
    (state) => state.filters
  );

export const selectChartererVesselTypesFilters = (
  state: StateWithChartererVesselTypesState
) => {
  return chartererVesselTypesFilterSelectors.selectAll(
    selectChartererVesselTypesState(state)
  );
};

export const selectChartererVesselTypesFilterBy =
  (name: string) => (state: StateWithChartererVesselTypesState) => {
    return chartererVesselTypesFilterSelectors.selectById(
      selectChartererVesselTypesState(state),
      name
    );
  };
