import { ClientError, request } from 'graphql-request';
import { Messages } from '../app/sitemap/_common/messages/messages';

type GraphqlRequestParamsArray = Parameters<typeof request>;

export type GraphqlRequestParams = {
  baseUrl: GraphqlRequestParamsArray[0];
  document: GraphqlRequestParamsArray[1];
  variables?: GraphqlRequestParamsArray[2] | void;
  requestHeaders?: GraphqlRequestParamsArray[3];
};

const hasMessage = (error: unknown): error is { message: string } =>
  error !== null &&
  typeof error !== 'undefined' &&
  'message' in (error as { message: 'string' });

export const graphqlBaseQuery =
  ({ baseUrl }: Pick<GraphqlRequestParams, 'baseUrl'>) =>
  async ({
    document,
    variables,
    requestHeaders
  }: Omit<GraphqlRequestParams, 'baseUrl'>) => {
    try {
      const result = await request(
        baseUrl,
        document,
        variables || undefined,
        requestHeaders
      );
      return { data: result };
    } catch (error) {
      if (error instanceof ClientError) {
        const message = hasMessage(error.response.data)
          ? Messages.parse(error.response.data.message)
          : Messages.list().common.error.general;

        return {
          error: {
            status: error.response.status,
            data: { message }
          }
        };
      }
      return {
        error: { status: 500, data: Messages.list().common.error.general }
      };
    }
  };
