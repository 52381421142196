import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@client';
import ListResponse from '../../../../../../../types/ListResponse';
import TableRequest from '../../../../../../../types/TableRequest';
import {
  BuyerPaymentInstructionResponseDto,
  CreatePaymentInstructionRequestDto,
  UpdatePaymentInstructionRequestDto
} from './models';

interface FetchBuyerPaymentInstructionsRequest {
  buyerId: number;
  params: TableRequest;
}

export const buyerPaymentInstructionsApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['BuyerPaymentInstructions'],
  reducerPath: 'buyer_paymentInstructionsApi_api',
  endpoints: (build) => ({
    fetchBuyerPaymentInstructions: build.query<
      ListResponse<BuyerPaymentInstructionResponseDto>,
      FetchBuyerPaymentInstructionsRequest
    >({
      query: ({ buyerId, params }) => ({
        url: `/secured/buyers/${buyerId}/payment-instructions/search`,
        method: 'GET',
        params
      })
    }),
    fetchBuyerPaymentInstruction: build.query<
      BuyerPaymentInstructionResponseDto,
      { paymentInstructionId: number; buyerId: number }
    >({
      query: ({ buyerId, paymentInstructionId }) => ({
        url: `/secured/buyers/${buyerId}/payment-instructions/${paymentInstructionId}`,
        method: 'GET'
      })
    }),
    createBuyerPaymentInstruction: build.mutation<
      BuyerPaymentInstructionResponseDto,
      { buyerId: number; data: CreatePaymentInstructionRequestDto }
    >({
      query: ({ buyerId, data }) => ({
        url: `/secured/buyers/${buyerId}/payment-instructions`,
        method: 'POST',
        data
      }),
      async onQueryStarted({ buyerId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            buyerPaymentInstructionsApi.util.updateQueryData(
              'fetchBuyerPaymentInstruction',
              { paymentInstructionId: data.id, buyerId },
              () => data
            )
          );
        } catch {
          //
        }
      }
    }),
    updateBuyerPaymentInstruction: build.mutation<
      BuyerPaymentInstructionResponseDto,
      {
        paymentInstructionId: number;
        buyerId: number;
        data: UpdatePaymentInstructionRequestDto;
      }
    >({
      query: ({ paymentInstructionId, buyerId, data }) => ({
        url: `secured/buyers/${buyerId}/payment-instructions/${paymentInstructionId}`,
        method: 'PATCH',
        data
      }),
      async onQueryStarted(
        { paymentInstructionId, buyerId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            buyerPaymentInstructionsApi.util.updateQueryData(
              'fetchBuyerPaymentInstruction',
              { paymentInstructionId, buyerId },
              () => data
            )
          );
        } catch {
          //
        }
      }
    })
  })
});

export type StateWithBuyerPaymentInstructionsApi = {
  [buyerPaymentInstructionsApi.reducerPath]: ReturnType<
    typeof buyerPaymentInstructionsApi.reducer
  >;
};

export const {
  useFetchBuyerPaymentInstructionsQuery,
  useFetchBuyerPaymentInstructionQuery,
  useCreateBuyerPaymentInstructionMutation,
  useUpdateBuyerPaymentInstructionMutation
} = buyerPaymentInstructionsApi;
