import { secureRefSplitApi } from './ref-api';
import { RefData } from '@state';

const compareBuyerRegions = (buyerRegion1: RefData, buyerRegion2: RefData) =>
  buyerRegion1.label.localeCompare(buyerRegion2.label);

const buyerRegionsApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchRegionsRefData: build.query<RefData[], void>({
      query: () => ({
        url: '/profile/rules/regions',
        method: 'GET'
      }),
      transformResponse: (buyerRegions: RefData[]) =>
        buyerRegions.sort(compareBuyerRegions)
    })
  }),
  overrideExisting: false
});

export const { useFetchRegionsRefDataQuery } = buyerRegionsApi;
