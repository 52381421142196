import { format, formatISO, parseISO } from 'date-fns';
import { RefData } from '../types';

class PsUtilities {
  public static createRandomKey = (index: any = 0): number => {
    return Math.random() * 1000000000000000000 * (index + 1);
  };

  public static convertToRefData = (
    obj: { id: string | number; name: string } | undefined
  ): RefData | undefined => {
    return !!obj ? { label: obj.name, value: '' + obj.id } : undefined;
  };

  public static convertToRefDataArray = (
    arr: { id: string; name: string }[] | undefined
  ): RefData[] | undefined => {
    return !!arr ? arr.map((a) => PsUtilities.convertToRefData(a)!) : undefined;
  };

  public static formatDateTime = (date: string, defaultValue: string = '') => {
    return date ? format(parseISO(date), 'dd/MM/yyyy HH:mm:ss') : defaultValue;
  };

  public static formatDate = (
    date?: string,
    defaultValue: string = '',
    pattern = 'dd/MM/yyyy'
  ) => {
    return date ? format(parseISO(date), pattern) : defaultValue;
  };

  public static formatTime = (
    date: string | undefined,
    defaultValue: string = ''
  ) => {
    return !!date ? format(parseISO(date), 'HH:mm:ss') : defaultValue;
  };

  public static getLocalDateFromUtcDate = (
    date?: string,
    defaultValue: string = '',
    pattern = 'dd/MM/yyyy'
  ) => {
    return date ? format(parseISO(date + 'Z'), pattern) : defaultValue;
  };

  public static getLocalTimeFromUtcDate = (
    date: string | undefined,
    defaultValue: string = ''
  ) => {
    return !!date ? format(parseISO(date + 'Z'), 'HH:mm:ss') : defaultValue;
  };

  public static formatDouble = (
    num: number | undefined | null,
    minimumFractionDigits = 2,
    defaultValue: string = '0.00'
  ): string => {
    return num
      ? PsUtilities.roundDouble(num, minimumFractionDigits).toLocaleString(
          'en-US',
          {
            minimumFractionDigits: minimumFractionDigits
          }
        )
      : defaultValue;
  };

  public static roundDouble = (
    num: number,
    minimumFractionDigits = 2
  ): number => {
    return (
      Math.round(num * Math.pow(10, minimumFractionDigits)) /
      Math.pow(10, minimumFractionDigits)
    );
  };

  public static formatInteger = (
    num: number | undefined | null,
    minimumFractionDigits = 0,
    defaultValue: string = '0'
  ): string => {
    return num
      ? (
          Math.round(num * Math.pow(10, minimumFractionDigits)) /
          Math.pow(10, minimumFractionDigits)
        ).toLocaleString('en-US', {
          minimumFractionDigits: minimumFractionDigits
        })
      : defaultValue;
  };

  public static formatAmount = (
    num: number,
    currency: string | undefined,
    minimumFractionDigits = 2,
    defaultValue: string = '0.00'
  ): string => {
    return (
      PsUtilities.formatDouble(num, minimumFractionDigits, defaultValue) +
      (!!currency ? ' ' + currency : '')
    );
  };

  public static formatISO = (date: Date | null) => {
    return date
      ? formatISO(date, { representation: 'date' }) + 'T00:00:00'
      : null;
  };

  public static createFormRequestParts = (
    form: any,
    attachments: File[] | undefined,
    formParamName: string = 'form',
    attachmentsParamName: string = 'attachments'
  ): FormData => {
    const data = new FormData();
    data.append(
      formParamName,
      new Blob([JSON.stringify(form)], {
        type: 'application/json'
      })
    );
    PsUtilities.addAttachmentsToForm(data, attachments, attachmentsParamName);
    return data;
  };

  public static addAttachmentsToForm = (
    data: FormData,
    attachments: File[] | undefined,
    paramName: string = 'attachments'
  ) => {
    if (!!attachments) {
      attachments.forEach((a) => {
        if (a instanceof Blob) {
          const file = new File([a], a.name, {
            lastModified: new Date().getTime()
          });
          data.append(paramName, file);
        } else {
          data.append(paramName, a);
        }
      });
    }
  };

  public static findInRefDataByValue = (
    list: RefData[] | undefined,
    value: string | undefined
  ): RefData | undefined => {
    if (!list || !value) return undefined;
    return list.find((i) => i.value == value);
  };
}

export default PsUtilities;
