import {
  AdminProfilesState,
  StateWithAdminProfilesState,
  adminProfilesTableSlice
} from './reducers';
import { filtersAdapter } from '../../../types';

export const selectAdminProfilesState = (state: StateWithAdminProfilesState) =>
  state[adminProfilesTableSlice.name];

const filterSelectors = filtersAdapter.getSelectors<AdminProfilesState>(
  (state) => state.filters
);

export const selectAdminProfilesFilters = (
  state: StateWithAdminProfilesState
) => {
  return filterSelectors.selectAll(selectAdminProfilesState(state));
};

export const selectAdminProfilesFilterBy =
  (name: string) => (state: StateWithAdminProfilesState) => {
    return filterSelectors.selectById(selectAdminProfilesState(state), name);
  };
