const webStorage = {
  setItem: <T>(name: string, value: T): void => {
    return localStorage.setItem(name, JSON.stringify(value));
  },
  getItem: <T>(name: string): T | null => {
    const val = localStorage.getItem(name);
    if (val === null) return null;
    return <T | null>JSON.parse(val);
  },
  removeItem: (name: string) => {
    return localStorage.removeItem(name);
  }
};

export default webStorage;
export * from './versionedStorage';
