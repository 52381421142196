import { useDisableBodyScroll } from '@common';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animated, useSpringRef, useTransition } from '@react-spring/web';
import clsx from 'clsx';
import { useKeyHandler } from 'components/components';
import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { createPortal } from 'react-dom';

export const DrawerContext = createContext<{
  isDrawerOpen: (id: string) => boolean;
  drawerDict: Record<string, boolean>;
  toggleDrawer: (id: string) => void;
  openDrawer: (id: string) => void;
  closeDrawer: (id: string) => void;
}>({
  isDrawerOpen: () => false,
  drawerDict: {},
  toggleDrawer: () => {},
  openDrawer: () => {},
  closeDrawer: () => {}
});

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  return context;
};

export const Drawer = ({
  children,
  title,
  subTitle,
  className,
  id
}: PropsWithChildren<{
  id: string;
  title?: string | undefined | ReactNode;
  subTitle?: ReactNode | undefined;
  className?: string | undefined;
}>) => {
  const { isDrawerOpen, drawerDict, closeDrawer } = useDrawerContext();
  const transRef = useSpringRef();

  const transitions = useTransition(drawerDict[id], {
    from: { width: 0, opacity: 0 },
    enter: { width: 700, opacity: 1 },
    leave: { width: 0, opacity: 0 }
  });

  useDisableBodyScroll(isDrawerOpen(id));

  useKeyHandler(document, 'Escape', 'keydown', () => {
    closeDrawer(id);
  });

  useEffect(() => {
    transRef.start({
      width: drawerDict[id] ? 700 : 0,
      opacity: drawerDict[id] ? 1 : 0
    });
  }, [drawerDict]);

  return transitions((style, isOpen) =>
    isOpen ? (
      createPortal(
        <>
          <animated.div
            onClick={() => {
              closeDrawer(id);
            }}
            className={clsx('drawer-background', className)}
            style={{ opacity: style.opacity }}
          />
          <animated.div
            className={clsx('slide-in-drawer', 'has-max-width', className)}
            style={{ width: style.width }}
          >
            <animated.div
              className="card"
              style={{
                padding: '2rem 2.5rem 2.5rem 2.5rem',
                opacity: style.opacity
              }}
            >
              <header className="px-4 pb-4">
                <div className="is-flex is-align-items-center has-border-bottom-width-1">
                  <p className="card-header-title p-0 pb-2 is-size-5 has-text-dark has-text-weight-semibold">
                    {title ?? 'Comments'}
                  </p>
                  <span
                    onClick={() => closeDrawer(id)}
                    className="card-header-icon p-0 pb-2 icon has-text-dark is-clickable"
                    aria-label="close-drawer"
                  >
                    <FontAwesomeIcon icon={faTimes} size="xl" />
                  </span>
                </div>
                {subTitle && (
                  <div className="has-text-weight-semibold">{subTitle}</div>
                )}
              </header>
              <div
                id="content"
                className="card-content px-4 py-0 is-flex is-flex-direction-column is-justify-content-space-between"
              >
                {children}
              </div>
            </animated.div>
          </animated.div>
        </>,
        document.body,
        id
      )
    ) : (
      <></>
    )
  );
};

export const DrawerProvider = ({ children }: PropsWithChildren) => {
  const [drawerDict, setDrawerDict] = useState<Record<string, boolean>>({});

  const toggleDrawer = (id: string) =>
    setDrawerDict((prev) => ({ ...prev, [id]: !prev[id] }));

  const isDrawerOpen = (id: string) => Boolean(drawerDict[id]);

  const openDrawer = (id: string) =>
    setDrawerDict((prev) => ({ ...prev, [id]: true }));

  const closeDrawer = (id: string) =>
    setDrawerDict((prev) => ({ ...prev, [id]: false }));

  return (
    <DrawerContext.Provider
      value={{
        isDrawerOpen,
        drawerDict,
        toggleDrawer,
        openDrawer,
        closeDrawer
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};
