import get from 'lodash/get';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FieldWrapperProps } from '../types';
import PsFieldWrapper from './ps-form-field-wrapper';
import PsValidator from './ps-validator';

type Props = {
  name: string;
  type?: string;
  entity?: any;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  form: any;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => any;
  placeholder?: string;
  isRequired?: boolean;
  maxLength?: number;
  validation?: 'email' | 'password' | 'iban';
  defaultValue?: string;
} & FieldWrapperProps;

const PsTextField: React.FunctionComponent<Props> = ({
  children,
  type = 'text',
  placeholder,
  name,
  entity,
  isDisabled = false,
  isReadOnly = false,
  form,
  onBlur,
  isRequired = false,
  maxLength,
  validation,
  defaultValue,
  ...rest
}) => {
  const [value, setValue] = useState<any>();
  const [defValue, setDefValue] = useState<string | undefined>();

  useEffect(() => {
    init();
  }, [entity, name, defaultValue]);

  const error = get(form.errors, name);
  const hasError = !!error;

  const init = () => {
    const value = get(entity, name);
    setValue(!!value ? value : defaultValue);
    setDefValue(!!value ? value : defaultValue);
    form.setValue(name, !!value ? value : defaultValue);
  };

  const validate = (): any => {
    if (!validation) {
      return {
        validate: {
          required: (value: any) =>
            isRequired ? !!value || 'This field is required!' : undefined
        }
      };
    } else {
      switch (validation) {
        case 'password':
          return {
            validate: {
              required: (value: any) =>
                isRequired ? !!value || 'This field is required!' : undefined,
              valid: (value: any) =>
                !value ||
                PsValidator.isPassword(value) ||
                'The password must consist of numbers and letters and should have from 6 to 16 characters'
            }
          };
        case 'email':
          return {
            validate: {
              required: (value: any) =>
                isRequired ? !!value || 'This field is required!' : undefined,
              valid: (value: any) =>
                !value ||
                PsValidator.isEmail(value) ||
                'Please insert a valid email!'
            }
          };
        case 'iban':
          return {
            validate: {
              required: (value: any) =>
                isRequired ? !!value || 'This field is required!' : undefined,
              valid: (value: any) =>
                !value ||
                PsValidator.isIbanLike(value) ||
                'Please insert a valid IBAN!'
            }
          };
        default:
          return {};
      }
    }
  };

  return (
    <PsFieldWrapper
      hasError={hasError}
      errorMessage={get(error, 'message')}
      {...rest}
    >
      <Controller
        name={name}
        control={form.control}
        defaultValue={defValue}
        render={() => (
          <input
            disabled={isDisabled}
            readOnly={isReadOnly}
            type={type}
            className={'input' + (hasError ? ' is-danger' : '')}
            placeholder={placeholder}
            name={name}
            defaultValue={value}
            maxLength={maxLength}
            onBlur={onBlur}
            ref={form.register(validate())}
          />
        )}
      />
      {children}
    </PsFieldWrapper>
  );
};

export default PsTextField;
