import React, { useImperativeHandle, useRef } from 'react';
import { PsPhoneFieldHandler, PsSelectFieldHandler } from '../handlers';
import { FieldWrapperProps, RefData } from '../types';
import usePsCache from './ps-cache';
import PsFieldWrapper from './ps-form-field-wrapper';
import PsSelectField from './ps-form-select-field';
import PsTextField from './ps-form-text-field';

type Props = {
  name: string;
  entity?: any;
  isDisabled?: boolean;
  form: any;
  placeholder?: string;
  isRequired?: boolean;
  fetchPrefixesApi: string;
  isPortal?: boolean;
  ref?: any;
} & FieldWrapperProps;

const PsPhoneField: React.FunctionComponent<Props> = React.forwardRef(
  (
    {
      children,
      placeholder,
      name,
      entity,
      isDisabled = false,
      form,
      isRequired = false,
      fetchPrefixesApi,
      isPortal = false,
      ...rest
    },
    ref: React.Ref<PsPhoneFieldHandler>
  ) => {
    useImperativeHandle(ref, () => {
      return {
        reset: reset
      };
    });

    const prefixes = usePsCache<RefData[]>('phone-prefixes', fetchPrefixesApi);

    const selectField = useRef<PsSelectFieldHandler>();

    const reset = () => {
      selectField.current!.reset();
    };

    return (
      <PsFieldWrapper hasError={false} {...rest}>
        <div className="columns is-vcentered is-variable is-1 no-m-bottom">
          <div
            className="column is-narrow"
            style={{ minWidth: '180px', paddingTop: 0 }}
          >
            {!prefixes.isEmpty && (
              <PsSelectField
                name={name + '.countryId'}
                entity={entity}
                form={form}
                isRequired={isRequired}
                isDisabled={isDisabled}
                options={prefixes.values}
                isClearable
                isPortal={isPortal}
                ref={selectField}
              />
            )}
          </div>
          <div className="column" style={{ paddingTop: 0 }}>
            <PsTextField
              entity={entity}
              form={form}
              isRequired={isRequired}
              placeholder="Insert number"
              name={name + '.number'}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      </PsFieldWrapper>
    );
  }
);

export default PsPhoneField;
