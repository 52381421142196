import { axiosBaseQuery, createAxiosInstance } from '@client';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const expenseEvaluationDocumentTranslationApi = createApi({
  baseQuery: axiosBaseQuery({
    customAxiosInstance: createAxiosInstance({
      responseType: 'blob'
    })
  }),
  reducerPath: 'expense-evaluation-document-translation-api',
  endpoints: (build) => ({
    fetchTranslatedPortExpenseDocument: build.query<
      Blob,
      {
        evaluationId: string;
        estimationId: string;
        fileItemId: number;
      }
    >({
      query: ({ evaluationId, estimationId, fileItemId }) => ({
        url: `/secured/download/evaluation/${evaluationId}/estimation/${estimationId}/attachment/${fileItemId}/translate`,
        method: 'GET'
      })
    })
  })
});

export const { useLazyFetchTranslatedPortExpenseDocumentQuery } =
  expenseEvaluationDocumentTranslationApi;
