class PsValidator {
  public static isPassword = (value: string): boolean => {
    const re = /^(?=.*[0-9])(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&+=]{6,16}$/;
    return re.test(value);
  };

  public static isEmail = (value: string): boolean => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(value);
  };

  public static isPositiveNumber = (value: string): boolean => {
    const re = /^[0-9]*$/;
    return re.test(value);
  };

  public static isIbanLike = (value: string): boolean => {
    const ibanLikeRegex = /^[A-Z]{2}[0-9A-Z ]*$/;
    return ibanLikeRegex.test(value);
  };
}

export default PsValidator;
