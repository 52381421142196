import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@client';
import { LogEntryResponse } from '@module/log/model/LogEntryResponse';

export const logEntriesApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['LogEntry'],
  reducerPath: 'log-entries_api',
  endpoints: (build) => ({
    fetchLogEntries: build.query<
      LogEntryResponse[],
      { id: string; type: string }
    >({
      query: ({ id, type }) => ({
        url: `/secured/log-entry/${type}/${id}/list`,
        method: 'GET'
      })
    })
  })
});

export type StateWithLogEntriesApi = {
  [logEntriesApi.reducerPath]: ReturnType<typeof logEntriesApi.reducer>;
};

export const { useFetchLogEntriesQuery } = logEntriesApi;
