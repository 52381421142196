import {
  StyleBackgroundAttributes,
  StyleContainerAttributes,
  StyleState,
  StyleStateAttributes
} from '../types';

class PsStyle {
  static styleStateAttributesInstance = {
    isLight: true,
    isLoading: true,
    isRounded: true,
    isStatic: true,
    isSmall: true,
    hasMaxWidth: true,
    hasSmallMaxWidth: true,
    isText: true,
    isOutlined: true,
    state: 'primary'
  };

  public static extractProperties = (props: any) => {
    let htmlProps: any = {};

    const styles = PsStyle.styleStateAttributesInstance;

    for (let key in props) {
      if (!(styles as any)[key]) {
        htmlProps[key] = props[key];
      }
    }

    return htmlProps;
  };

  public static applyLoading(props: any & StyleStateAttributes) {
    return props.isLoading ? ' is-loading' : '';
  }

  public static applyBaseState(state?: StyleState) {
    return !!state ? ' is-' + state : '';
  }

  public static applyState(props: any & StyleStateAttributes) {
    return (
      PsStyle.applyBaseState(props.state) +
      (props.isLight ? ' is-light' : '') +
      (props.isLoading ? ' is-loading' : '') +
      (props.isRounded ? ' is-rounded' : '') +
      (props.isStatic ? ' is-static' : '') +
      (props.isSmall ? ' is-small' : '') +
      (props.hasSmallMaxWidth ? ' has-small-min-width' : '') +
      (props.hasMaxWidth ? ' has-min-width' : '') +
      (props.isText ? ' is-text' : '') +
      (props.isOutlined ? ' is-outlined' : '') +
      (props.isActive ? ' is-active' : '')
    );
  }

  public static applyTextState(state: StyleState | undefined) {
    return ' has-text-' + (!state ? 'grey-lighter' : state);
  }

  public static applyBackground(props: any & StyleBackgroundAttributes) {
    return !!props.bg ? ' has-background-' + props.bg : '';
  }

  public static applyContainer(props: any & StyleContainerAttributes) {
    return (
      (props.hasRadius ? ' has-radius' : '') +
      (props.hasBorder ? ' has-border' : '') +
      (props.hasShadowNormal ? ' has-shadow-normal' : '') +
      (props.hasNoPadding ? ' is-paddingless' : '')
    );
  }

  public static applyClassName(className: string | undefined) {
    return !!className ? ' ' + className : '';
  }
}

export default PsStyle;
