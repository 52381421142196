import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@client';
import {
  AgentRatingSubmitDTO,
  AgentRatingResponseDTO,
  AgentRatingPerBuyerResponseDTO
} from '../../types';

export const agentRatingApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['AgentRatings'],
  reducerPath: 'agent-rating_api',
  endpoints: (build) => ({
    fetchAgentRating: build.query<number, { appointmentId: number }>({
      query: ({ appointmentId }) => ({
        url: `/secured/appointment/${appointmentId}/agent-rating`,
        method: 'GET'
      })
    }),
    submitRating: build.mutation<AgentRatingResponseDTO, AgentRatingSubmitDTO>({
      query: (payload) => ({
        url: `/secured/agent-rating`,
        method: 'POST',
        data: payload
      })
    }),
    fetchAgentAverageRating: build.query<
      AgentRatingPerBuyerResponseDTO,
      { agentId: number; buyerId: number }
    >({
      query: ({ agentId, buyerId }) => ({
        url: `/secured/buyers/${buyerId}/agents/${agentId}/average-rating`,
        method: 'GET'
      })
    }),
    fetchAgentRatings: build.query<
      AgentRatingResponseDTO[],
      { agentId: number; buyerId: number }
    >({
      query: ({ agentId, buyerId }) => ({
        url: `/secured/buyers/${buyerId}/agents/${agentId}/agent-ratings`,
        method: 'GET'
      })
    })
  })
});

export type AgentRatingState = {
  [agentRatingApi.reducerPath]: ReturnType<typeof agentRatingApi.reducer>;
};

export const {
  useFetchAgentRatingQuery,
  useLazyFetchAgentRatingQuery,
  useSubmitRatingMutation,
  useLazyFetchAgentAverageRatingQuery,
  useLazyFetchAgentRatingsQuery
} = agentRatingApi;
