import TableRequest from '../../../../../types/TableRequest';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@client';
import ListResponse from '../../../../../types/ListResponse';
import {
  KypVerifiersResponseDto,
  KypVerificationResponseDto
} from '@state/stores/admin/kyp/models';

interface FetchKypVerificationsRequest {
  params: TableRequest;
}

export const kypVerificationsApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['KYPVerifications'],
  reducerPath: 'KYP_verifications_api',
  endpoints: (build) => ({
    fetchKypVerifications: build.query<
      ListResponse<KypVerificationResponseDto>,
      FetchKypVerificationsRequest
    >({
      query: ({ params }) => ({
        url: `/secured/kyp-verifications`,
        method: 'GET',
        params
      })
    }),
    fetchKypVerifiers: build.query<KypVerifiersResponseDto[], void>({
      query: () => ({
        url: `/secured/kyp-verifications/admins`,
        method: 'GET'
      })
    })
  })
});

export const { useFetchKypVerificationsQuery, useFetchKypVerifiersQuery } =
  kypVerificationsApi;
