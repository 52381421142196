import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Filter,
  FiltersState,
  SortingState,
  TableState,
  filtersAdapter
} from '../../../../types';

export type CharterersState = TableState;

const initialCharterersState: CharterersState = {
  page: 0,
  size: 10,
  filters: filtersAdapter.getInitialState()
};

export const charterersTableSlice = createSlice({
  name: 'charterers-table',
  initialState: initialCharterersState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.page = payload.page;
    },
    updateSorting: (state, { payload }: PayloadAction<SortingState>) => {
      state.direction = payload.direction;
      state.property = payload.property;
      // reset page
      state.page = 0;
    },
    updateFilter: (state, { payload }: PayloadAction<Filter>) => {
      state.filters = filtersAdapter.upsertOne(state.filters, payload);
      // reset page
      state.page = 0;
    },
    clearFilter: (state, { payload }: PayloadAction<{ name: string }>) => {
      state.filters = filtersAdapter.removeOne(state.filters, payload.name);
      // reset page
      state.page = 0;
    },
    resetFilters: (state) => {
      state.filters = filtersAdapter.removeAll(state.filters);
      // reset page
      state.page = 0;
    }
  }
});

export const {
  updatePage: updateCharterersPage,
  updateSorting: updateCharterersSorting,
  updateFilter: updateCharterersFilter,
  clearFilter: clearCharterersFilter,
  resetFilters: resetCharterersFilters
} = charterersTableSlice.actions;

export type StateWithCharterersState = {
  [charterersTableSlice.name]: CharterersState;
};

export type ChartererVesselTypesState = SortingState & FiltersState;

const initialChartererVesselTypesState: ChartererVesselTypesState = {
  filters: filtersAdapter.getInitialState()
};

export const chartererVesselTypesTableSlice = createSlice({
  name: 'chartererVesselTypes-table',
  initialState: initialChartererVesselTypesState,
  reducers: {
    updateSorting: (state, { payload }: PayloadAction<SortingState>) => {
      state.direction = payload.direction;
      state.property = payload.property;
    },
    updateFilter: (state, { payload }: PayloadAction<Filter>) => {
      state.filters = filtersAdapter.upsertOne(state.filters, payload);
    },
    clearFilter: (state, { payload }: PayloadAction<{ name: string }>) => {
      state.filters = filtersAdapter.removeOne(state.filters, payload.name);
    },
    resetFilters: (state) => {
      state.filters = filtersAdapter.removeAll(state.filters);
    }
  }
});

export const {
  updateSorting: updateChartererVesselTypesSorting,
  updateFilter: updateChartererVesselTypesFilter,
  clearFilter: clearChartererVesselTypesFilter,
  resetFilters: resetChartererVesselTypesFilters
} = chartererVesselTypesTableSlice.actions;

export type StateWithChartererVesselTypesState = {
  [chartererVesselTypesTableSlice.name]: ChartererVesselTypesState;
};
