import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/query/react';
import { CompanySettingsResponse, RefData } from '../../types';

export const buyerApi = createApi({
  reducerPath: 'buyerApi',
  baseQuery: axiosBaseQuery({ baseUrl: 'secured/buyer' }),
  endpoints: (build) => ({
    loadBuyer: build.query<{ settings: CompanySettingsResponse }, number>({
      query: (buyerId) => ({ url: `/${buyerId}/load`, method: 'GET' })
    }),
    fetchBuyerVessels: build.query<RefData[], { buyerId: string }>({
      query: ({ buyerId }) => ({
        url: `/${buyerId}/vessels-ref-data`,
        method: 'GET'
      }),
      transformResponse: (buyerRegions: RefData[]) =>
        buyerRegions.sort((vesselA: RefData, vesselB: RefData) =>
          vesselA.label.localeCompare(vesselB.label)
        )
    })
  })
});

export const { useLoadBuyerQuery, useFetchBuyerVesselsQuery } = buyerApi;
