import { createSelector } from '@reduxjs/toolkit';
import { StateWithEvaluationPage, evaluationPageSlice } from './reducers';
import {
  StateWithEvaluationApi,
  evaluationApi,
  fetchBudgetStatusResponse
} from './api';

export const selectEvaluationPageState = (state: StateWithEvaluationPage) =>
  state[evaluationPageSlice.name];

export const selectEvaluationPageAgencyExpensesTotal = (
  state: StateWithEvaluationPage
) => selectEvaluationPageState(state).agencyExpensesTotal;

export const selectEvaluationPageHusbandryExpensesTotal = (
  state: StateWithEvaluationPage
) => selectEvaluationPageState(state).husbandryExpensesTotal;

export const selectEvaluationPagePortExpensesTotal = (
  state: StateWithEvaluationPage
) => selectEvaluationPageState(state).portExpensesTotal;

export const selectEvaluationPageTotalAmount = (
  state: StateWithEvaluationPage
) =>
  selectEvaluationPageAgencyExpensesTotal(state) +
  selectEvaluationPageHusbandryExpensesTotal(state) +
  selectEvaluationPagePortExpensesTotal(state);

export const selectEvaluationPageLinkedPDAs = (
  state: StateWithEvaluationPage
) => selectEvaluationPageState(state).linkedPDAs;

export const selectBudgetStatusResponse = createSelector(
  ({ data }: { data: fetchBudgetStatusResponse }) => data,
  (data) => data
);

export const selectEvaluationPageBudgetStatusItems = createSelector(
  selectBudgetStatusResponse,
  (data) => data.budgetCheckItems
);

export const selectEvaluationPageOutOfBudgetExpenses = createSelector(
  (data: { data: fetchBudgetStatusResponse }) => data,
  selectEvaluationPageBudgetStatusItems,
  ({ data }) =>
    Boolean(
      data.budgetCheckItems?.some(
        (budgetCheckItem) => budgetCheckItem.amount !== 0
      )
    )
);

export const selectBudgetStatusFromApiState = (
  state: StateWithEvaluationApi,
  evaluationId: number
) =>
  evaluationApi.endpoints.fetchBudgetStatus.select({
    evaluationId
  })(state).data;
