export const STORAGE_VERSION = 'v3';

export const versionedWebStorage = {
  setItem: <T>(name: string, value: T): void => {
    return localStorage.setItem(
      name,
      JSON.stringify({
        [STORAGE_VERSION]: value
      })
    );
  },
  getItem: <T>(name: string): T | null => {
    const val = localStorage.getItem(name);
    if (val === null) return null;

    const parsed = <{ [STORAGE_VERSION]: T | null }>JSON.parse(val);
    return parsed?.[STORAGE_VERSION] ?? null;
  },
  removeItem: (name: string) => {
    return localStorage.removeItem(name);
  }
};
