import get from 'lodash/get';
import has from 'lodash/has';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Controller } from 'react-hook-form';
import Creatable from 'react-select/creatable';
import { PsCreatableSelectFieldHandler } from '../handlers';
import { FieldWrapperProps, RefData } from '../types';
import PsFieldWrapper from './ps-form-field-wrapper';

type Props = {
  name: string;
  entity?: any;
  isDisabled?: boolean;
  form: any;
  onChange?: (value: RefData) => any;
  placeholder?: string;
  isRequired?: boolean;
  options: RefData[] | undefined;
  isClearable?: boolean;
  isSmall?: boolean;
  isPortal?: boolean;
  isExpanded?: boolean;
  noOptionsMessage?: string;
  onCreateOption?: (value: string) => any;
  getNewOptionData?: (value: string) => RefData;
  isValidNewOption?: (inputValue: string) => boolean;
  instanceId?: string | number;
  ref?: any;
} & FieldWrapperProps;

const PsCreatableSelectField: React.FunctionComponent<Props> = React.forwardRef(
  (
    {
      children,
      placeholder,
      name,
      entity,
      isDisabled = false,
      form,
      onChange,
      isRequired = false,
      options,
      isClearable = false,
      isSmall = false,
      isExpanded = false,
      isPortal = false,
      noOptionsMessage = 'No Options!',
      onCreateOption,
      getNewOptionData,
      isValidNewOption,
      instanceId,
      ...rest
    },
    ref: React.Ref<PsCreatableSelectFieldHandler>
  ) => {
    const [value, setValue] = useState<RefData | null>();
    const [defaultValue, setDefaultValue] = useState<RefData | null>();

    useImperativeHandle(ref, () => {
      return {
        reset: reset,
        clear: clear
      };
    });

    const getValue = () => {
      return has(entity, name) && !!options
        ? options.find((o) => o.value == get(entity, name))
        : null;
    };

    useEffect(() => {
      init();
    }, [entity, name]);

    const error = get(form.errors, name);
    const hasError = !!error;

    const init = () => {
      const value = getValue();
      setValue(value);
      setDefaultValue(value);
      form.setValue(name, get(value, 'value'));
    };

    const reset = () => {
      form.setValue(name, get(defaultValue, 'value'));
      setValue(defaultValue);
    };

    const clear = () => {
      form.setValue(name, null);
      setValue(null);
    };

    const customStyles = {
      menuPortal: (provided: any, state: any) => {
        const zIndex = '50 !important';
        return { ...provided, zIndex };
      }
    };

    return (
      <PsFieldWrapper
        hasError={hasError}
        errorMessage={get(error, 'message')}
        isSmall={isSmall}
        isExpanded={isExpanded}
        {...rest}
      >
        <Controller
          name={name}
          control={form.control}
          rules={{ required: isRequired }}
          defaultValue={defaultValue}
          render={() => (
            <Creatable
              isDisabled={isDisabled}
              value={value}
              getOptionValue={(o) => o.value}
              onChange={(selected: any) => {
                form.setValue(name, get(selected, 'value'));
                setValue(selected);
                if (onChange) onChange(selected);
              }}
              placeholder={placeholder}
              styles={customStyles}
              options={options}
              noOptionsMessage={() => noOptionsMessage}
              isClearable={isClearable}
              escapeClearsValue={isClearable}
              classNamePrefix="custom-select"
              menuPortalTarget={isPortal ? document.body : undefined}
              className={hasError ? 'is-danger' : ''}
              onCreateOption={onCreateOption}
              getNewOptionData={getNewOptionData}
              isValidNewOption={(inputValue) => {
                return !!isValidNewOption
                  ? !!inputValue && isValidNewOption(inputValue)
                  : !!inputValue;
              }}
              instanceId={instanceId}
            />
          )}
        />
      </PsFieldWrapper>
    );
  }
);

export default PsCreatableSelectField;
