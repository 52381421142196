import { refSplitApi } from './ref-api';

const phonePrefixesApi = refSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchPhonePrefixes: build.query<{ label: string; value: string }[], void>({
      query: () => ({ url: '/phone-country-prefixes', method: 'GET' })
    })
  }),
  overrideExisting: false
});

export const { useFetchPhonePrefixesQuery } = phonePrefixesApi;
