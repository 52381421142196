import { createApi } from '@reduxjs/toolkit/query/react';
import ListResponse, {
  NullableListResponse
} from '../../../../../../types/ListResponse';
import { DashboardPortCall } from '../../../../types';
import { axiosBaseQuery, isBaseQueryError } from '@client';
import { AppointmentRecipientResponse } from '@module/appointment/model/AppointmentRecipientResponse';
import { AppointmentResponse } from '@module/appointment/model/AppointmentResponse';
import { DashboardHistoryActionDTO } from '@module/dashboard/model/DashboardHistoryAction';
import { PortCallResponse } from '@module/port-call/model/PortCallResponse';
import { adminDaToolPortCallsSlice } from './reducers';
import TableRequest from '../../../../../../types/TableRequest';
import { EvaluationVerificationDTO } from '@state/stores/buyer-dashboard/EvaluationVerificationDTO';
import { AssignEvaluationDTO } from './models';

export const adminDaToolPortCallsApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['PortCalls', 'History'],
  reducerPath: 'admin_da-tool_port-calls_api',
  endpoints: (build) => ({
    fetchPortCalls: build.query<ListResponse<DashboardPortCall>, TableRequest>({
      query: (params) => ({
        url: `/secured/administrator/dashboard/port-calls`,
        method: 'GET',
        params
      }),
      transformResponse: (
        response: NullableListResponse<DashboardPortCall>
      ) => {
        return { ...response, items: response.items ?? [] };
      }
    }),
    fetchPortCall: build.query<PortCallResponse, { id: number }>({
      query: ({ id }) => ({
        url: `secured/port-call/${id}/load`,
        method: 'GET'
      })
    }),
    updatePortCall: build.mutation<
      PortCallResponse,
      Partial<DashboardPortCall> & Pick<DashboardPortCall, 'id'>
    >({
      query: (data) => ({
        url: `/secured/port-call/save-details`,
        method: 'POST',
        data
      }),
      transformErrorResponse: (error) => {
        return isBaseQueryError(error)
          ? error.data.message
          : 'Error while fetching/updating port call details';
      },
      async onQueryStarted(data, { dispatch, queryFulfilled, getState }) {
        // @ts-ignore
        const state = getState() as StateWithBuyerDashboard;
        // need these to match the query cache key so that we can update the cache below
        const paginationState =
          state[adminDaToolPortCallsSlice.name].pagination;

        try {
          const {
            data: { id, schedule, port }
          } = await queryFulfilled;

          dispatch(
            adminDaToolPortCallsApi.util.updateQueryData(
              'fetchPortCalls',
              { ...paginationState },
              ({ items, totalItems }) => ({
                totalItems,
                items: items.map((item) => {
                  if (item.id === id) {
                    return {
                      ...item,
                      eta: schedule.dateIn,
                      ets: schedule.dateOut,
                      port: port.label
                    };
                  }
                  return item;
                })
              })
            )
          );
        } catch {
          //
        }
      }
    }),
    sendReminder: build.mutation<
      AppointmentResponse,
      { appointmentId: number; portCallId: number } & TableRequest
    >({
      query: ({ appointmentId }) => ({
        url: `/secured/appointment/${appointmentId}/send-reminder`,
        method: 'POST'
      }),
      async onQueryStarted(
        { appointmentId, portCallId, ...pagination },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          adminDaToolPortCallsApi.util.updateQueryData(
            'fetchPortCalls',
            pagination,
            (draft) => {
              const appointment = draft.items
                .find((i) => i.id === portCallId)
                ?.appointments?.find((a) => a.id === appointmentId);

              if (appointment) {
                const { firstPokeDate, pokes, pokeLock } = appointment.observer;
                appointment.observer = {
                  pokeLock,
                  firstPokeDate: firstPokeDate
                    ? firstPokeDate
                    : new Date().toUTCString(),
                  lastPokeDate: new Date().toISOString(),
                  pokes: (pokes ?? 0) + 1,
                  state: 'UNREAD'
                };
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      }
    }),
    fetchAppointmentRecipient: build.query<
      AppointmentRecipientResponse,
      number
    >({
      query: (appointmentId) => ({
        url: `/secured/appointments/${appointmentId}/appointment-recipient`,
        method: 'GET'
      })
    }),
    fetchFirstLevelEvaluationFlowDepartments: build.query<
      EvaluationVerificationDTO[],
      string
    >({
      query: (evaluationIds) => ({
        url: `/secured/evaluations/active-flow-step?evaluationIds=${evaluationIds}`,
        method: 'GET'
      })
    }),
    fetchAppointmentHistory: build.query<DashboardHistoryActionDTO[], number>({
      query: (appointmentId) => ({
        url: `/secured/dashboard/history/${appointmentId}`,
        method: 'GET'
      }),
      providesTags: (_result, _error, appointmentId) => [
        {
          type: 'History',
          id: appointmentId
        }
      ]
    }),
    assignEvaluation: build.mutation<{}, AssignEvaluationDTO>({
      query: (data) => ({
        url: `/secured/evaluation/assign`,
        method: 'POST',
        data
      }),
      async onQueryStarted(
        {
          portCallId,
          appointmentId,
          pagination,
          evaluationId,
          assign,
          profile
        },
        { dispatch, queryFulfilled }
      ) {
        // optimistic updates:
        // https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#optimistic-updates
        const patchResult = dispatch(
          adminDaToolPortCallsApi.util.updateQueryData(
            'fetchPortCalls',
            pagination,
            (draft) => {
              const {
                id,
                fullName,
                role,
                department: { id: value, name: label }
              } = profile;

              const evaluation = draft.items
                .find((i) => i.id === portCallId)
                ?.appointments?.find((a) => a.id === appointmentId)
                ?.evaluations?.find((e) => e.id === evaluationId);

              if (evaluation) {
                evaluation.assigneeId = assign ? profile.id : null;
                evaluation.assignee = assign
                  ? { department: { label, value }, fullName, id, role }
                  : null;
              }
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: (_result, _error, { appointmentId }) => [
        { type: 'History', id: appointmentId }
      ]
    }),
    fetchAdminBuyerProfilesRefData: build.query<
      { label: string; value: string }[],
      { profileId: number | string }
    >({
      query: ({ profileId }) => ({
        url: `/secured/admin-profiles/${profileId}/buyer-profiles/companies`,
        method: 'GET'
      }),
      transformResponse: (response: { name: string; id: string }[]) => {
        return response.reduce((acc, item) => {
          const existingItem = acc.find((i) => i.value === item.id);
          return existingItem
            ? acc
            : [...acc, { label: item.name, value: item.id }];
        }, [] as { label: string; value: string }[]);
      }
    })
  })
});

export type StateWithAdminDaToolPortCallsApi = {
  [adminDaToolPortCallsApi.reducerPath]: ReturnType<
    typeof adminDaToolPortCallsApi.reducer
  >;
};

export const {
  useFetchPortCallsQuery: useFetchAdminPortCallsQuery,
  useSendReminderMutation: useAdminDataToolSendReminderMutation,
  useFetchAppointmentRecipientQuery: useFetchAdminAppointmentRecipientQuery,
  useFetchFirstLevelEvaluationFlowDepartmentsQuery:
    useFetchAdminFirstLevelEvaluationFlowDepartmentsQuery,
  useFetchAppointmentHistoryQuery: useFetchAdminAppointmentHistoryQuery,
  useFetchAdminBuyerProfilesRefDataQuery,
  useAssignEvaluationMutation: useAdminAssignEvaluationMutation
} = adminDaToolPortCallsApi;
