import { secureRefSplitApi } from './ref-api';

const voyageTypesApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchVoyageTypes: build.query<{ label: string; value: string }[], void>({
      query: () => ({
        url: '/voyage-types',
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchVoyageTypesQuery } = voyageTypesApi;
