import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ChartererResponse } from '../../types';
import ListResponse from '../../../../types/ListResponse';
import { SaveChartererDto } from '@module/charterer/model/SaveChartererDto';
import { ChartererVesselTypeResponse } from '@module/charterer/model/ChartererVesselTypeResponse';
import { ChartererVesselTypeDto } from '@module/charterer/model/ChartererVesselTypeDto';

interface ListRequest {
  page: number;
  size: number;
  order?: {
    isAsc: boolean;
    property: string;
  };
  filter?: {
    singleValueFilterItems: {
      name: string;
      value: string | number | boolean;
    }[];
  };
}

export const charterersApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Charterers', 'Charterer'],
  reducerPath: 'charterersApi',
  endpoints: (build) => ({
    fetchCharterersByVesselType: build.query<ChartererResponse[], string>({
      query: (vesselType) => ({
        url: `secured/ref/buyer/vessel/${vesselType}/charterers`,
        method: 'GET'
      }),
      providesTags: ['Charterers']
    }),
    suggestCharterer: build.mutation<
      ChartererResponse[],
      { name: string; vesselType: string }
    >({
      query: (data) => ({
        url: `/secured/charterer/suggest`,
        method: 'POST',
        data
      }),
      invalidatesTags: ['Charterers']
    }),
    fetchCharterers: build.query<ListResponse<ChartererResponse>, ListRequest>({
      query: ({ page, size, filter, order }) => ({
        url: `secured/charterers`,
        method: 'POST',
        data: {
          page,
          size,
          filter,
          order
        }
      })
    }),
    fetchCharterer: build.query<ChartererResponse, { chartererId: number }>({
      query: ({ chartererId }) => ({
        url: `/secured/charterer/${chartererId}/load`,
        method: 'GET'
      }),
      providesTags: ['Charterer']
    }),
    createCharterer: build.mutation<ChartererResponse, SaveChartererDto>({
      query: (data) => ({
        url: `secured/charterer/save`,
        method: 'POST',
        data
      }),
      invalidatesTags: ['Charterer']
    }),
    updateCharterer: build.mutation<ChartererResponse, SaveChartererDto>({
      query: (data) => ({
        url: `secured/charterer/save`,
        method: 'POST',
        data
      }),
      invalidatesTags: ['Charterer']
    }),
    createChartererVesselType: build.mutation<
      ChartererVesselTypeResponse,
      ChartererVesselTypeDto
    >({
      query: (data) => ({
        url: `secured/charterer-vessel-type/save`,
        method: 'POST',
        data
      }),
      invalidatesTags: ['Charterer']
    }),
    updateChartererVesselType: build.mutation<
      ChartererVesselTypeResponse,
      ChartererVesselTypeDto
    >({
      query: (data) => ({
        url: `secured/charterer-vessel-type/save`,
        method: 'POST',
        data
      }),
      invalidatesTags: ['Charterer']
    })
  })
});

export const {
  useFetchCharterersByVesselTypeQuery,
  useSuggestChartererMutation,
  useFetchCharterersQuery,
  useFetchChartererQuery,
  useCreateChartererMutation,
  useUpdateChartererMutation,
  useCreateChartererVesselTypeMutation,
  useUpdateChartererVesselTypeMutation
} = charterersApi;
