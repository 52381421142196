import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@client';
import {
  AppointmentPreview,
  AppointmentPreviewResponse
} from '@module/appointment/model/AppointmentPreviewResponse';
import { getPrincipalDetails } from '../helpers/helpers';

export const appointmentPreviewResponseApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: '/secured/appointment' }),
  reducerPath: 'appointment-previewResponse_api',
  endpoints: (build) => ({
    fetchAppointmentOverview: build.query<
      AppointmentPreview,
      {
        appointmentId: number;
      }
    >({
      query: ({ appointmentId }) => ({
        url: `/${appointmentId}/official-preview`,
        method: 'GET'
      }),
      // Integrate transformResponse here
      transformResponse: (response: AppointmentPreviewResponse) => {
        const { displayLegalEntityAsPrincipal, ...rest } = response;
        return {
          ...rest,
          pricipalDetails: getPrincipalDetails(
            rest,
            response.displayLegalEntityAsPrincipal
          )
        };
      }
    })
  })
});

export type StateWithAppointmentPreviewResponseApi = {
  [appointmentPreviewResponseApi.reducerPath]: ReturnType<
    typeof appointmentPreviewResponseApi.reducer
  >;
};

export const { useFetchAppointmentOverviewQuery } =
  appointmentPreviewResponseApi;
