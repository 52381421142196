import {
  StateWithBuyerPaymentInstructionsState,
  buyerPaymentInstructionsTableSlice,
  BuyerPaymentInstructionsState
} from './reducers';
import { filtersAdapter } from '../../../../../types';

export const selectBuyerPaymentInstructionsState = (
  state: StateWithBuyerPaymentInstructionsState
) => state[buyerPaymentInstructionsTableSlice.name];

const filterSelectors =
  filtersAdapter.getSelectors<BuyerPaymentInstructionsState>(
    (state) => state.filters
  );

export const selectBuyerPaymentInstructionsFilters = (
  state: StateWithBuyerPaymentInstructionsState
) => {
  return filterSelectors.selectAll(selectBuyerPaymentInstructionsState(state));
};

export const selectBuyerPaymentInstructionsFilterBy =
  (name: string) => (state: StateWithBuyerPaymentInstructionsState) => {
    return filterSelectors.selectById(
      selectBuyerPaymentInstructionsState(state),
      name
    );
  };
