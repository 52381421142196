import React from 'react';

const PageLoader = React.memo(() => {
  return (
    <React.Fragment>
      <div className="pageloader is-active" id="loader">
        <span className="title">Harbor Lab is loading...</span>
      </div>
    </React.Fragment>
  );
});

export default PageLoader;
