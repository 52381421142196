import get from 'lodash/get';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { PsMultiSelectFieldHandler } from '../handlers';
import { FieldWrapperProps, RefData } from '../types';
import PsFieldWrapper from './ps-form-field-wrapper';

type Props = {
  name: string;
  entity?: any;
  isDisabled?: boolean;
  form: any;
  onChange?: (value: RefData[]) => any;
  placeholder?: string;
  isRequired?: boolean;
  options: RefData[] | undefined;
  isClearable?: boolean;
  isSmall?: boolean;
  isPortal?: boolean;
  noOptionsMessage?: string;
  ref?: any;
} & FieldWrapperProps;

const PsMultiSelectField: React.FunctionComponent<Props> = React.forwardRef(
  (
    {
      children,
      placeholder,
      name,
      entity,
      isDisabled = false,
      form,
      onChange,
      isRequired = false,
      options,
      isClearable = false,
      isSmall = false,
      isPortal = false,
      noOptionsMessage = 'No Options!',
      ...rest
    },
    ref: React.Ref<PsMultiSelectFieldHandler>
  ) => {
    const [value, setValue] = useState<RefData[] | null>();
    const [defaultValue, setDefaultValue] = useState<RefData[] | null>();

    useImperativeHandle(ref, () => {
      return {
        reset: reset
      };
    });

    const getValues = (): RefData[] | null => {
      if (has(entity, name) && !!options) {
        const values: RefData[] = [];
        let entityValues: string[] = get(entity, name);
        if (!isArray(entityValues)) entityValues = [entityValues];
        entityValues.forEach((ev) => {
          const option = options.find((o) => o.value == ev);
          if (!!option) values.push(option);
        });
        return values;
      }
      return null;
    };

    useEffect(() => {
      init();
    }, [entity, name]);

    const error = get(form.errors, name);
    const hasError = !!error;

    const init = () => {
      const value = getValues();
      setValue(value);
      setDefaultValue(value);
      form.setValue(name, !!value ? value.map((v) => v.value) : null);
    };

    const reset = () => {
      form.setValue(
        name,
        !!defaultValue ? defaultValue.map((v) => v.value) : null
      );
      setValue(defaultValue);
    };

    const customStyles = {
      menuPortal: (provided: any, state: any) => {
        const zIndex = '50 !important';
        return { ...provided, zIndex };
      }
    };

    return (
      <PsFieldWrapper
        hasError={hasError}
        errorMessage={get(error, 'message')}
        isSmall={isSmall}
        {...rest}
      >
        <Controller
          name={name}
          control={form.control}
          rules={{ required: isRequired }}
          defaultValue={defaultValue}
          render={() => (
            <Select
              isDisabled={isDisabled}
              value={value}
              getOptionValue={(o) => o.value}
              //@ts-ignore
              onChange={(selections: any[]) => {
                form.setValue(
                  name,
                  !!selections ? selections.map((s) => s.value) : null
                );
                setValue(selections);
                if (onChange) onChange(selections);
              }}
              placeholder={placeholder}
              styles={customStyles}
              options={options}
              noOptionsMessage={() => noOptionsMessage}
              isClearable={isClearable}
              classNamePrefix="custom-select"
              menuPortalTarget={isPortal ? document.body : undefined}
              isMulti
              className={hasError ? 'is-danger' : ''}
            />
          )}
        />
      </PsFieldWrapper>
    );
  }
);

export default PsMultiSelectField;
