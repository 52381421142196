import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import set from 'lodash/set';

export interface EvaluationSectionSelections {
  isAll: boolean;
  selected: number[];
}

export interface EvaluationSelectionsState
  extends Record<string, EvaluationSectionSelections> {
  PORT_EXPENSES: EvaluationSectionSelections;
  AGENCY_EXPENSES: EvaluationSectionSelections;
  HUSBANDRY_EXPENSES: EvaluationSectionSelections;
}

const initialSectionSelection = { isAll: false, selected: [] };

const initialEvaluationSelectionsState: EvaluationSelectionsState = {
  PORT_EXPENSES: initialSectionSelection,
  AGENCY_EXPENSES: initialSectionSelection,
  HUSBANDRY_EXPENSES: initialSectionSelection
};

export const evaluationSelectionsSlice = createSlice({
  name: 'evaluationSelections',
  initialState: initialEvaluationSelectionsState,
  reducers: {
    add: (state, action: PayloadAction<{ section: string; id: number }>) => {
      const sectionSelections: EvaluationSectionSelections = get(
        state,
        action.payload.section
      );
      if (!sectionSelections.selected.includes(action.payload.id)) {
        const copy = cloneDeep(sectionSelections);
        copy.selected.push(action.payload.id);
        set(state, action.payload.section, copy);
      }
    },
    remove: (state, action: PayloadAction<{ section: string; id: number }>) => {
      const sectionSelections: EvaluationSectionSelections = get(
        state,
        action.payload.section
      );
      const copy = cloneDeep(sectionSelections);
      const idx = copy.selected.findIndex((s) => s == action.payload.id);
      if (idx > -1) {
        copy.selected.splice(idx, 1);
        copy.isAll = false;
        set(state, action.payload.section, copy);
      }
    },
    set: (
      state,
      action: PayloadAction<{
        section: string;
        ids: number[];
      }>
    ) => {
      const sectionSelections: EvaluationSectionSelections = get(
        state,
        action.payload.section
      );
      const copy = cloneDeep(sectionSelections);
      copy.isAll = action.payload.ids.length > 0;
      copy.selected = action.payload.ids;
      set(state, action.payload.section, copy);
    },
    clear: (state, action: PayloadAction<string>) => {
      const sectionSelections: EvaluationSectionSelections = get(
        state,
        action.payload
      );
      const copy = cloneDeep(sectionSelections);
      copy.isAll = false;
      copy.selected = [];
      set(state, action.payload, copy);
    },
    clearAll: (state) => {
      state.PORT_EXPENSES = initialSectionSelection;
      state.AGENCY_EXPENSES = initialSectionSelection;
      state.HUSBANDRY_EXPENSES = initialSectionSelection;
    }
  }
});

export const {
  clearAll: clearAllEvalSelections,
  clear: clearEvalSelection,
  add: addEvalSelections,
  remove: removeEvalSelections,
  set: setEvaluationSelections
} = evaluationSelectionsSlice.actions;

type StateWithEvaluationSelections = {
  [evaluationSelectionsSlice.name]: EvaluationSelectionsState;
};

export const selectEvaluationSelectionsState = (
  state: StateWithEvaluationSelections
) => state[evaluationSelectionsSlice.name];

export const selectEvalSelectionsPortExpenses = (
  state: StateWithEvaluationSelections
) => selectEvaluationSelectionsState(state).PORT_EXPENSES;

export const selectEvalSelectionsAgencyExpenses = (
  state: StateWithEvaluationSelections
) => selectEvaluationSelectionsState(state).AGENCY_EXPENSES;

export const selectEvalSelectionsHusbandryExpenses = (
  state: StateWithEvaluationSelections
) => selectEvaluationSelectionsState(state).HUSBANDRY_EXPENSES;

export const selectSelectedEvalPortExpenses = (
  state: StateWithEvaluationSelections
) => selectEvalSelectionsPortExpenses(state).selected ?? [];

export const selectSelectedEvalAgencyExpenses = (
  state: StateWithEvaluationSelections
) => selectEvalSelectionsAgencyExpenses(state).selected ?? [];

export const selectSelectedEvalHusbandryExpenses = (
  state: StateWithEvaluationSelections
) => selectEvalSelectionsHusbandryExpenses(state).selected ?? [];

export const selectAllSelectedEvalExpenses = createSelector(
  selectSelectedEvalPortExpenses,
  selectSelectedEvalAgencyExpenses,
  selectSelectedEvalHusbandryExpenses,
  (portExpenses, agencyExpenses, husbandryExpenses) => [
    ...portExpenses,
    ...agencyExpenses,
    ...husbandryExpenses
  ]
);
