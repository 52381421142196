import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { PortEstimatorCalculatorExecutionResultResponse } from '../../types/PortEstimatorCalculatorExecutionResultResponse';
import { RefData } from '../../types/RefData';
import { PortEstimatorParametersResponse } from '../../types/PortEstimatorParametersResponse';

export const portCostEstimatorApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: 'secured/port-estimator-calculator'
  }),
  reducerPath: 'portCostEstimatorApi',
  endpoints: (build) => ({
    portCostEstimatorExecute: build.mutation<
      PortEstimatorCalculatorExecutionResultResponse,
      {
        vesselId: number;
        portId: string;
        activityTypeIds: number[];
        advanced: boolean;
        berthId: number;
        terminalId: number;
        modelVessel: boolean;
      }
    >({
      query: (data) => ({ url: `/execute`, method: 'POST', data })
    }),
    portCostEstimatorFilterVessels: build.query<
      RefData[],
      { text: string; isModelVessel: boolean }
    >({
      query: ({ text, isModelVessel }) => ({
        url: `/filter-vessels?text=${text}&isModelVessel=${isModelVessel}`,
        method: 'GET'
      })
    }),
    portCostEstimatorLoadConfig: build.query<
      PortEstimatorParametersResponse,
      { portIdValue: string; vesselIdValue: number; isModelVessel: boolean }
    >({
      query: ({ portIdValue, vesselIdValue, isModelVessel }) => ({
        url: `/port/${portIdValue}/vessel/${vesselIdValue}/load-configuration?isModelVessel=${isModelVessel}`,
        method: 'GET'
      })
    })
  })
});

export const {
  useLazyPortCostEstimatorFilterVesselsQuery,
  usePortCostEstimatorExecuteMutation,
  usePortCostEstimatorLoadConfigQuery
} = portCostEstimatorApi;
