import { secureRefSplitApi } from './ref-api';

const expenseEvalInspectionDiscrepancyReasonsApi =
  secureRefSplitApi.injectEndpoints({
    endpoints: (build) => ({
      fetchExpenseEvalInspectionDiscrepancyReasons: build.query<
        { label: string; value: string }[],
        void
      >({
        query: () => ({
          url: '/expense-evaluation-inspection/discrepancy-reasons',
          method: 'GET'
        })
      })
    }),
    overrideExisting: false
  });

export const { useFetchExpenseEvalInspectionDiscrepancyReasonsQuery } =
  expenseEvalInspectionDiscrepancyReasonsApi;
