import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AppointmentActivityTypeConfigurationResponse } from '@state/types/AppointmentActivityTypeConfigurationResponse';

export const activityTypeConfigApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ActivityTypesConfig'],
  reducerPath: 'activity-types-configuration_api',
  endpoints: (build) => ({
    fetchActivityTypeConfig: build.query<
      AppointmentActivityTypeConfigurationResponse[],
      void
    >({
      query: () => ({
        url: `/secured/appointment/activity-types`,
        method: 'GET'
      })
    })
  })
});

export const { useFetchActivityTypeConfigQuery } = activityTypeConfigApi;
