import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Filter,
  SortingState,
  TableState,
  filtersAdapter
} from '../../../types';

export type AdminProfilesState = TableState;

const initialState: AdminProfilesState = {
  page: 0,
  size: 10,
  filters: filtersAdapter.getInitialState()
};

export const adminProfilesTableSlice = createSlice({
  name: 'admin-profiles-table',
  initialState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.page = payload.page;
    },
    updateSorting: (state, { payload }: PayloadAction<SortingState>) => {
      state.direction = payload.direction;
      state.property = payload.property;
      // reset page
      state.page = 0;
    },
    updateFilter: (state, { payload }: PayloadAction<Filter>) => {
      state.filters = filtersAdapter.upsertOne(state.filters, payload);
      // reset page
      state.page = 0;
    },
    clearFilter: (state, { payload }: PayloadAction<{ name: string }>) => {
      state.filters = filtersAdapter.removeOne(state.filters, payload.name);
      // reset page
      state.page = 0;
    },
    resetFilters: (state) => {
      state.filters = filtersAdapter.removeAll(state.filters);
      // reset page
      state.page = 0;
    }
  }
});

export const {
  updatePage: updateAdminProfilesPage,
  updateSorting: updateAdminProfilesSorting,
  updateFilter: updateAdminProfilesFilter,
  clearFilter: clearAdminProfilesFilter,
  resetFilters: resetAdminProfilesFilters
} = adminProfilesTableSlice.actions;

export type StateWithAdminProfilesState = {
  [adminProfilesTableSlice.name]: AdminProfilesState;
};
