import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { PagingRequest, PagingResponse, VoyageResponse } from '../../types';

export const vesselApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'secured/vessel' }),
  reducerPath: 'vessel-api',
  endpoints: (build) => ({
    fetchVoyagesOfVessel: build.mutation<
      PagingResponse<VoyageResponse>,
      { vesselId: number; pagingRequest: PagingRequest }
    >({
      query: (options) => {
        return {
          method: 'POST',
          url: `/${options.vesselId}/voyages`,
          data: options.pagingRequest
        };
      }
    })
  })
});

export const { useFetchVoyagesOfVesselMutation } = vesselApi;
