import { axiosBaseQuery } from '@client';
import { AccountingCodeFormValues } from '@containers';
import { AccountingCodeResponse } from '../../types/AccountingCodeResponse';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const adminAccountingCodesApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['AdminAccountingCodes'],
  reducerPath: 'admin-accounting-codes_api',
  endpoints: (build) => ({
    fetchAdminAccountingCode: build.query<AccountingCodeResponse, number>({
      query: (accountingCodeId) => ({
        url: `/secured/accounting-code/${accountingCodeId}/load`,
        method: 'GET'
      }),
      providesTags: ['AdminAccountingCodes']
    }),
    submitAccountingCode: build.mutation<
      AccountingCodeResponse,
      AccountingCodeFormValues
    >({
      query: (payload) => ({
        url: '/secured/accounting-code/save',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: ['AdminAccountingCodes']
    })
  })
});

export type StateWithAdminAccountingCodesApi = {
  [adminAccountingCodesApi.reducerPath]: ReturnType<
    typeof adminAccountingCodesApi.reducer
  >;
};

export const {
  useFetchAdminAccountingCodeQuery,
  useSubmitAccountingCodeMutation
} = adminAccountingCodesApi;
