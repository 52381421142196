import { createAsyncThunk } from '@reduxjs/toolkit';
import client from '@client';
import { cacheDict } from '@common';

export const fetchCachedItemValues = createAsyncThunk<
  unknown[],
  keyof typeof cacheDict
>('cache/fetchItems', (key) => {
  return new Promise((resolve, reject) => {
    client.get<unknown[]>({
      url: cacheDict[key],
      onSuccess: ({ data }) => {
        resolve(data);
      },
      onError: (error) => {
        reject(error);
      }
    });
  });
});
