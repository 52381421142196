import { createSelector } from '@reduxjs/toolkit';
import { adminDaToolPortCallsApi } from './adminDaToolPortCallsApi';
import {
  StateWithAdminDaToolPortCalls,
  AdminDaToolPortCallsState,
  adminDaToolPortCallsSlice
} from './reducers';
import { filtersAdapter } from '../../../../types';

type fetchPortCallsResponse =
  typeof adminDaToolPortCallsApi.endpoints.fetchPortCalls.Types.ResultType;

export const selectAdminDaToolPortCallsState = (
  state: StateWithAdminDaToolPortCalls
) => state[adminDaToolPortCallsSlice.name];

export const selectAdminDaToolPortCallsPaginationState = (
  state: StateWithAdminDaToolPortCalls
) => selectAdminDaToolPortCallsState(state).table;

const filterSelectors = filtersAdapter.getSelectors<
  AdminDaToolPortCallsState['table']
>((state) => state.filters);

export const selectAdminDaToolPortCallsFilterState = (
  state: StateWithAdminDaToolPortCalls
) =>
  filterSelectors.selectAll(selectAdminDaToolPortCallsPaginationState(state));

export const selectAdminDaToolPortCallsAppointmentDrawerState = (
  state: StateWithAdminDaToolPortCalls
) => selectAdminDaToolPortCallsState(state).drawer;

export const selectAdminDaToolPortCallsDrawerAppointmentId = (
  state: StateWithAdminDaToolPortCalls
) => selectAdminDaToolPortCallsAppointmentDrawerState(state)?.appointmentId;

export const selectAdminDaToolPortCallsDrawerPortCallId = (
  state: StateWithAdminDaToolPortCalls
) => selectAdminDaToolPortCallsAppointmentDrawerState(state)?.portCallId;

export const selectAdminDaToolPortCallsDrawerBuyerId = (
  state: StateWithAdminDaToolPortCalls
) => selectAdminDaToolPortCallsAppointmentDrawerState(state)?.buyerId;

const selectPortCallsResponse = createSelector(
  ({ data }: { data: fetchPortCallsResponse }) => data,
  (data) => data
);

const selectPortCalls = createSelector(
  selectPortCallsResponse,
  (data) => data.items
);

export const selectAdminDaToolPortCallById = createSelector(
  (_res: { data: fetchPortCallsResponse }, id: number) => id,
  selectPortCalls,
  (id, portCalls) => (portCalls ?? []).find((p) => p.id === id)
);

export const selectAdminDaToolPortCallBuyerId = createSelector(
  selectAdminDaToolPortCallById,
  (portCall) => portCall?.buyerId ?? null
);

const selectAdminDaToolPortCallAppointments = createSelector(
  selectAdminDaToolPortCallById,
  (portCall) => portCall?.appointments ?? []
);

export const selectAdminDaToolAppointmentById = createSelector(
  (
    _res: { data: fetchPortCallsResponse },
    portCallId: number,
    appointmentId: number
  ) => ({ portCallId, appointmentId }),
  selectAdminDaToolPortCallAppointments,
  ({ appointmentId }, appointments) => {
    return appointments.find((appointment) => appointment.id === appointmentId);
  }
);

const selectAdminDaToolEvaluations = createSelector(
  (
    _res: { data: fetchPortCallsResponse },
    portCallId: number,
    appointmentId: number
  ) => ({ portCallId, appointmentId }),
  selectAdminDaToolAppointmentById,
  (_, appointment) => appointment?.evaluations ?? []
);

export const selectAdminDaToolEvaluation = createSelector(
  (
    _res: { data: fetchPortCallsResponse },
    portCallId: number,
    appointmentId: number,
    evaluationId: number
  ) => ({ portCallId, appointmentId, evaluationId }),
  selectAdminDaToolEvaluations,
  ({ evaluationId }, evaluations) => {
    return evaluations?.find((e) => e.id === evaluationId);
  }
);

export const selectAdminDaToolAgentRating = createSelector(
  (
    _res: { data: fetchPortCallsResponse },
    portCallId: number,
    appointmentId: number
  ) => ({ portCallId, appointmentId }),
  selectAdminDaToolAppointmentById,
  (_, appointment) => {
    const criteriaRatings = appointment?.agentRating?.criteriaRatings ?? [];
    return (
      criteriaRatings.reduce((acc, { rating }) => acc + rating, 0) /
      (criteriaRatings.length || 1)
    );
  }
);
