import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/query/react';

export const integrationsApi = createApi({
  reducerPath: 'integrationsApi',
  baseQuery: axiosBaseQuery({ baseUrl: `secured/integration` }),
  endpoints: (build) => ({
    fetchIntegTaskFlows: build.query<string[], void>({
      query: () => ({ url: `/task/flows`, method: 'GET' })
    })
  })
});

export const { useFetchIntegTaskFlowsQuery } = integrationsApi;
