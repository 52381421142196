import { createApi } from '@reduxjs/toolkit/query/react';
import { dynamicBaseGraphqlQuery } from './dynamicBaseGraphqlQuery';

export const daAppBaseGraphqlApi = createApi({
  tagTypes: ['PORTCALL'],
  baseQuery: dynamicBaseGraphqlQuery({
    baseUrl: 'da-app/graphql'
  }),
  endpoints: () => ({}),
  reducerPath: 'da-app-api'
});
