import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/query/react';

// initialize an empty api service that we'll inject endpoints into later as needed
export const secureRefSplitApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'secured/ref' }),
  reducerPath: 'secure-ref-api',
  endpoints: () => ({})
});

export const secureSplitApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'secured/' }),
  reducerPath: 'secure-api',
  endpoints: () => ({})
});

export const refSplitApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'ref' }),
  reducerPath: 'ref-api',
  endpoints: () => ({})
});
