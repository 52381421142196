import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FieldWrapperProps } from '../types';
import PsFieldLabel from './ps-form-field-label';

type Props = FieldWrapperProps;

const error = (hasError: any | undefined, message: string | undefined) => {
  return hasError ? (
    <p className="help is-danger">
      {message ? message : 'This field is required'}
    </p>
  ) : null;
};

const tooltip = (tooltip: string | undefined) => {
  return tooltip ? (
    <sup
      className="has-text-weight-light has-text-warning tooltip is-tooltip-multiline"
      data-tooltip={tooltip}
    >
      <FontAwesomeIcon icon={faInfoCircle} />
    </sup>
  ) : null;
};

const classNames = (
  hasIconsLeft: boolean,
  hasIconsRight: boolean,
  isSmall: boolean,
  isExpanded: boolean
) => {
  return (
    'control' +
    (hasIconsLeft ? ' has-icons-left' : '') +
    (hasIconsRight ? ' has-icons-right' : '') +
    (isSmall ? ' smaller' : '') +
    (isExpanded ? ' is-expanded' : '')
  );
};

export const PsFieldWrapper: React.FunctionComponent<Props> = ({
  children,
  htmlFor,
  hasError,
  errorMessage,
  tooltipMessage,
  hasIconsLeft = false,
  hasIconsRight = false,
  label,
  subLabel,
  className = 'field',
  footer,
  isSmall = false,
  isExpanded = false,
  unwrap = false
}) => {
  const contents = () => {
    return (
      <React.Fragment>
        <PsFieldLabel htmlFor={htmlFor} label={label} subLabel={subLabel}>
          {tooltip(tooltipMessage)}
        </PsFieldLabel>
        <div
          className={classNames(
            hasIconsLeft,
            hasIconsRight,
            isSmall,
            isExpanded
          )}
        >
          {children}
        </div>
        {error(hasError, errorMessage)}
        {footer}
      </React.Fragment>
    );
  };

  return unwrap ? contents() : <div className={className}>{contents()}</div>;
};

export default PsFieldWrapper;
