import { axiosBaseQuery } from '@client';
import { ExpenseEvaluationFormulaResponse } from '@module/expense-evaluation/model/ExpenseEvaluationFormulaResponse';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { FormulaAnalyserFeedbackForm } from './models';

export const expenseEvaluationFormulaApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'expense-evaluation-formula_api',
  endpoints: (build) => ({
    fetchExpenseEvaluationFormula: build.query<
      ExpenseEvaluationFormulaResponse,
      {
        evaluationId: string;
        expenseEvaluationId: number;
        exchangeRate?: number;
      }
    >({
      query: ({ evaluationId, expenseEvaluationId, exchangeRate }) => ({
        url: `/secured/evaluation/${evaluationId}/expense/${expenseEvaluationId}/analyze`,
        method: 'GET',
        params: {
          exchangeRate: !!exchangeRate ? String(exchangeRate) : undefined
        }
      })
    }),
    rateExpenseEvaluationFormula: build.mutation<
      void,
      FormulaAnalyserFeedbackForm
    >({
      query: (data) => ({
        url: `/secured/formula/analyzer/feedback`,
        method: 'POST',
        data
      })
    })
  })
});

export const {
  useLazyFetchExpenseEvaluationFormulaQuery,
  useRateExpenseEvaluationFormulaMutation
} = expenseEvaluationFormulaApi;
