export enum SecuredPath {
  SECURED = 'secured'
}

export enum ProfilePath {
  BUYER = 'buyer',
  AGENT = 'agent',
  VENDOR = 'vendor',
  ADMIN = 'admin'
}

export enum AgentProductPath {
  SETTINGS = '/secured/agent/settings',
  DA_TOOL = '/secured/agent/da-tool'
}

export enum VendorProductPath {
  SETTINGS = '/secured/vendor/settings',
  DA_TOOL = '/secured/vendor/da-tool'
}

export enum BuyerProductPath {
  SETTINGS = '/secured/buyer/settings',
  DA_TOOL = '/secured/buyer/da-tool',
  POSITION_LIST = '/secured/buyer/position-list',
  PORT_ESTIMATOR = '/secured/buyer/port-estimator',
  PORT_DIRECTORY = '/secured/buyer/port-directory',
  FINANCIAL = '/secured/buyer/financial',
  AGENT_DIRECTORY = '/secured/buyer/agent-directory',
  CLAIMS = '/secured/buyer/claims',
  DASHBOARD = '/secured/buyer/dashboard/port-calls'
}

export enum AdminPath {
  BASE = '/secured/admin',
  SUPPORT = '/secured/admin/support',
  REOPEN_DA = '/secured/admin/reopen-da',
  PORT_ANALYSIS = '/secured/admin/port-analysis',
  ESTIMATION_API = '/secured/admin/estimation-api',
  FINANCIAL = '/secured/admin/financial',
  KYC = '/secured/admin/know-your-customer',
  SITE_CONTENT = '/secured/admin/site-content',
  METADATA = '/secured/admin/metadata',
  INTEGRATION = '/secured/admin/integration',
  SYSTEM = '/secured/admin/system-management'
}

export enum ModulePath {
  SETTINGS = 'settings',
  DA_TOOL = 'da-tool',
  POSITION_LIST = 'position-list',
  PORT_ESTIMATOR = 'port-estimator',
  PORT_DIRECTORY = 'port-directory',
  AGENT_DIRECTORY = 'agent-directory',
  CLAIMS = 'claims',
  DASHBOARD = 'dashboard'
}
