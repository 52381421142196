import { axiosBaseQuery } from '@client';
import { VoyageForm } from '@module/voyage/model/VoyageForm';
import { VoyageResponseWithForm } from '@module/voyage/model/VoyageResponseWithForm';
import { VoyageResponse } from '@module/voyage/model/voyageResponse';
import { createApi } from '@reduxjs/toolkit/query/react';

export const voyageApi = createApi({
  reducerPath: 'voyage-api',
  baseQuery: axiosBaseQuery({ baseUrl: '/secured/buyer/voyage' }),
  endpoints: (build) => ({
    fetchVoyage: build.query<VoyageResponse, { voyageId: string }>({
      query: ({ voyageId }) => ({
        url: `/${voyageId}/load`,
        method: 'GET'
      })
    }),
    updateVoyage: build.mutation<VoyageResponseWithForm, VoyageForm>({
      query: (data) => ({
        url: `/save`,
        method: 'POST',
        data
      })
    })
  })
});

export const { useLazyFetchVoyageQuery, useUpdateVoyageMutation } = voyageApi;
