import {
  Filter,
  filtersAdapter,
  SortingState,
  TableState
} from '../../../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type KYPVerificationsState = TableState;

const initialState: KYPVerificationsState = {
  page: 0,
  size: 10,
  filters: filtersAdapter.getInitialState()
};

export const kypVerificationsTableSlice = createSlice({
  name: 'kyp-verifications-table',
  initialState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.page = payload.page;
    },
    updateSorting: (state, { payload }: PayloadAction<SortingState>) => {
      state.direction = payload.direction;
      state.property = payload.property;
      state.page = 0;
    },
    updateFilter: (state, { payload }: PayloadAction<Filter>) => {
      state.filters = filtersAdapter.upsertOne(state.filters, payload);
      state.page = 0;
    },
    clearFilter: (state, { payload }: PayloadAction<{ name: string }>) => {
      state.filters = filtersAdapter.removeOne(state.filters, payload.name);
      state.page = 0;
    },
    resetFiltersAndSorting: (state) => {
      state.filters = filtersAdapter.removeAll(state.filters);
      state.direction = undefined;
      state.property = undefined;
      state.page = 0;
    }
  }
});

export const {
  updatePage: updateKypVerificationsPage,
  updateSorting: updateKypVerificationsSorting,
  updateFilter: updateKypVerificationsFilter,
  clearFilter: clearKypVerificationsFilter,
  resetFiltersAndSorting: resetKypVerificationsFiltersAndSorting
} = kypVerificationsTableSlice.actions;

export type StateWithKypVerificationsState = {
  [kypVerificationsTableSlice.name]: KYPVerificationsState;
};
