import { ProformaExpenseResponse } from '@module';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AgentProformaState {
  portExpenses: ProformaExpenseResponse[];
}

const initialState: AgentProformaState = {
  portExpenses: []
};

export const agentProformaSlice = createSlice({
  name: 'agent-proforma',
  initialState,
  reducers: {
    setAgentProformaPortExpenses: (
      state: AgentProformaState,
      action: PayloadAction<ProformaExpenseResponse[]>
    ) => {
      state.portExpenses = action.payload;
    }
  }
});

export const { setAgentProformaPortExpenses } = agentProformaSlice.actions;

export type StateWithAgentProforma = {
  [agentProformaSlice.name]: AgentProformaState;
};

export const selectAgentProformaState = (state: StateWithAgentProforma) =>
  state[agentProformaSlice.name];

export const selectAgentProformaPortExpenses = (
  state: StateWithAgentProforma
) => selectAgentProformaState(state).portExpenses;
