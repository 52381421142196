import { getEnvironmentVariables } from '@common';
import { SerializedError } from '@reduxjs/toolkit';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, {
  AxiosError,
  AxiosHeaders,
  AxiosInstance,
  AxiosRequestConfig,
  isAxiosError,
  ResponseType
} from 'axios';
import { Messages } from '../app/sitemap/_common/messages/messages';
import {
  authInterceptor,
  errorHandlingInterceptor,
  InterceptorType
} from './restfull-client';

export const createAxiosInstance = (
  {
    baseURLParam,
    errorInterceptor,
    token,
    responseType
  }: {
    baseURLParam?: string;
    errorInterceptor?: InterceptorType;
    token?: string;
    responseType?: ResponseType;
  } = {
    baseURLParam: undefined,
    errorInterceptor: errorHandlingInterceptor(),
    token: ''
  }
) => {
  const { API_URL } = getEnvironmentVariables();
  const baseURL = baseURLParam ?? `${API_URL}/da-app`;
  const headers = new AxiosHeaders();
  headers.setAccept('*/*');
  headers.set('Cache-Control', 'max-age=0');
  headers.setContentType('application/json');

  if (token) {
    headers.setAuthorization(`Bearer ${token}`);
  }

  const axiosInstance = axios.create({
    baseURL,
    headers,
    responseType
  });
  if (!token) {
    axiosInstance.interceptors.request.use(
      authInterceptor.onRequest.onFulfilled
    );
  }
  axiosInstance.interceptors.response.use(
    undefined,
    (errorInterceptor ?? errorHandlingInterceptor()).onResponse.onRejected
  );
  return axiosInstance;
};

const defaultAxiosInstance = createAxiosInstance();

export type BaseQueryError = { status?: number; data: { message: string } };

export const isBaseQueryError = (error: any): error is BaseQueryError =>
  'data' in error && Boolean(error.data.message);

export const hasError = (
  result:
    | {
        data: unknown | undefined;
      }
    | { error?: BaseQueryError | SerializedError | undefined }
): result is { error: BaseQueryError | SerializedError | undefined } =>
  'error' in result;

export const axiosBaseQuery =
  (
    {
      baseUrl,
      customAxiosInstance
    }: { baseUrl?: string; customAxiosInstance?: AxiosInstance } = {
      baseUrl: undefined,
      customAxiosInstance: undefined
    }
  ): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    BaseQueryError
  > =>
  async ({ url, method, data, params, headers }) => {
    const axiosInstance = customAxiosInstance ?? defaultAxiosInstance;
    try {
      const result = await axiosInstance({
        url: (baseUrl ?? '') + url,
        method,
        data,
        params,
        headers
      });
      return { data: result.data };
    } catch (err) {
      if (isAxiosError(err)) {
        const message = err.response?.data?.message;
        return {
          error: {
            status: err.response?.status,
            data: { message: Messages.parse(message) }
          }
        };
      } else {
        return {
          data: Messages.list().common.error.general
        };
      }
    }
  };
