import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const expenseEvaluationInspectionApi = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: '/secured/expense-evaluation-inspection'
  }),
  reducerPath: 'expense-evaluation-inspect_api',
  endpoints: (build) => ({
    expenseEvalInspectResolve: build.mutation<
      {},
      {
        id: string;
        discrepancyReasonIds: string[];
        discrepancyReasonComment: string | undefined;
      }
    >({
      query: (payload) => ({
        url: '/resolve',
        method: 'POST',
        data: payload
      })
    }),
    expenseEvalInspectBulkResolve: build.mutation<
      {},
      {
        ids: string[];
        discrepancyReasonIds: string[];
        discrepancyReasonComment: string | undefined;
      }
    >({
      query: (payload) => ({
        url: '/bulk-resolve',
        method: 'POST',
        data: payload
      })
    })
  })
});

export const {
  useExpenseEvalInspectResolveMutation,
  useExpenseEvalInspectBulkResolveMutation
} = expenseEvaluationInspectionApi;
