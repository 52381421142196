import React, { PropsWithChildren } from 'react';
import { FieldLabelProps } from '../types';

type Props = FieldLabelProps & PropsWithChildren;

const PsFieldLabel: React.FunctionComponent<Props> = ({
  children,
  htmlFor,
  label,
  subLabel
}) => {
  return label ? (
    <label className="label" htmlFor={htmlFor}>
      {label}
      {!!subLabel && (
        <small className="has-text-weight-normal has-text-grey">
          &nbsp;{subLabel}
        </small>
      )}
      {!!children && <React.Fragment>&nbsp;{children}</React.Fragment>}
    </label>
  ) : null;
};

export default PsFieldLabel;
