export enum Product {
  DA_TOOL = 'DA_TOOL',
  POSITION_LIST = 'POSITION_LIST',
  PORT_ESTIMATOR = 'PORT_ESTIMATOR',
  PORT_DIRECTORY = 'PORT_DIRECTORY',
  FINANCIAL = 'FINANCIAL',
  AGENT_DIRECTORY = 'AGENT_DIRECTORY',
  CLAIMS = 'CLAIMS',
  DASHBOARD = 'DA_DASHBOARD',
  SOF_EXCHANGE = 'SOF_EXCHANGE'
}
