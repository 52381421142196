import { secureRefSplitApi } from './ref-api';

const integConfigApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchIntegConfig: build.query<{ label: string; value: string }[], void>({
      query: () => ({
        url: '/integration-config-list',
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchIntegConfigQuery } = integConfigApi;
