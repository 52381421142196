import { combineReducers } from 'redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VoyageResponse } from '../../../module/voyage/model/voyageResponse';
import { Operators } from '../../../module/operator/model/Operators';
import { VesselCrewResponse } from '../../../module/vessel/model/VesselCrewResponse';
import { Navigation } from '../../../module/voyage/model/Navigation';

export interface LoadVoyageState {
  isLoading: boolean;
  voyage?: VoyageResponse;
  navigation?: Navigation;
  operators?: Operators[];
  crew?: VesselCrewResponse[];
}

export interface PortCallServicesState {
  isProcessing: boolean;
}

export interface PortCallViewState {
  isLoading: boolean;
  isProcessing: boolean;
  expandedPortCalls: string[];
}

const initialPortCallServicesState: PortCallServicesState = {
  isProcessing: false
};
export const portCallServicesSlice = createSlice({
  name: 'portCallServices',
  initialState: initialPortCallServicesState,
  reducers: {
    isProcessing: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    }
  }
});

const initialLoadVoyageState: LoadVoyageState = {
  isLoading: false
};

export const loadVoyageSlice = createSlice({
  name: 'loadVoyage',
  initialState: initialLoadVoyageState,
  reducers: {
    isLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setVoyage: (state, action: PayloadAction<VoyageResponse>) => {
      state.voyage = action.payload;
    },
    setNavigation: (state, action: PayloadAction<Navigation>) => {
      state.navigation = action.payload;
    },
    setOperators: (state, action: PayloadAction<Operators[]>) => {
      state.operators = action.payload;
    },
    setCrew: (state, action: PayloadAction<VesselCrewResponse[]>) => {
      state.crew = action.payload;
    },
    reset: (state) => {
      state.voyage = undefined;
      state.navigation = undefined;
      state.operators = undefined;
      state.crew = undefined;
      state.isLoading = false;
    }
  }
});

export const { reset: resetVoyage } = loadVoyageSlice.actions;

const initialPortCallViewState: PortCallViewState = {
  isLoading: false,
  isProcessing: false,
  expandedPortCalls: []
};
export const portCallViewSlice = createSlice({
  name: 'portCallView',
  initialState: initialPortCallViewState,
  reducers: {
    inProcess: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    isLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    expandItem: (state, action: PayloadAction<string>) => {
      state.expandedPortCalls = [];
      state.expandedPortCalls.push(action.payload);
    },
    collapseItem: (state, action: PayloadAction<string>) => {
      state.expandedPortCalls = [];
    }
  }
});

export const {
  expandItem: portCallViewExpandItem,
  collapseItem: portCallViewCollapseItem
} = portCallViewSlice.actions;

export const voyageStateName = 'voyage';

export const voyageReducer = combineReducers({
  [loadVoyageSlice.name]: loadVoyageSlice.reducer,
  [portCallViewSlice.name]: portCallViewSlice.reducer,
  [portCallServicesSlice.name]: portCallServicesSlice.reducer
});

export type StateWithVoyage = {
  [voyageStateName]: ReturnType<typeof voyageReducer>;
};
