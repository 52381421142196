import { selectAuthProfile, selectIntercomHash, useAppSelector } from '@state';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { UseScriptStatus } from './useCookieConsentBanner';

export const useInitIntercom = (autoBlockCookiesStatus?: UseScriptStatus) => {
  const profile = useAppSelector(selectAuthProfile);
  const intercomHash = useAppSelector(selectIntercomHash);
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    if (autoBlockCookiesStatus === 'ready') {
      boot(
        profile && intercomHash
          ? {
              userId: profile.id.toString(),
              name: profile.name + ' ' + profile.surname,
              email: profile.email,
              createdAt: new Date().getDate(),
              userHash: intercomHash
            }
          : undefined
      );
      return () => {
        shutdown();
      };
    }
  }, [autoBlockCookiesStatus, profile, intercomHash, boot, shutdown]);
};
