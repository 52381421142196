import { SESSION, webStorage } from '@common';
import {
  PreloadedState,
  configureStore,
  createListenerMiddleware
} from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunkMiddleware from 'redux-thunk';
import { middlewares, rootReducer } from './reducers';
import { registerAgentRatingActionListeners } from './stores/agent-rating';
import { registerSofListeners } from './stores/statement-of-facts/actionListeners';
import {
  AuthenticationState,
  authenticationSlice,
  initialAuthState,
  registerSessionListeners,
  sessionStateName
} from './stores/user';

export type RootState = ReturnType<typeof rootReducer>;

const whitelistPersist: (keyof RootState)[] = [
  'buyer-dashboard',
  'agent-proforma',
  'metadata'
];

const persistConfig = {
  key: 'root',
  storage,
  whitelist: whitelistPersist as string[]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const createPreloadedState = (): PreloadedState<RootState> => {
  const authState: AuthenticationState = {
    ...(webStorage.getItem<AuthenticationState>(SESSION) ?? initialAuthState)
  };

  return { [sessionStateName]: { [authenticationSlice.name]: authState } };
};

export const listenerMiddleware = createListenerMiddleware<RootState>();
registerSessionListeners(listenerMiddleware);
registerAgentRatingActionListeners(listenerMiddleware);
registerSofListeners(listenerMiddleware);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
      })
        .prepend(listenerMiddleware.middleware)
        .concat(middlewares)
        .concat(thunkMiddleware)
  });
};
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
