import { WebSession } from '@module/web-session/model/WebSession';
import { mainRestApi } from '../rest-api';

export const authApi = mainRestApi.injectEndpoints({
  endpoints: (build) => ({
    getUserProfile: build.query<WebSession, void>({
      query: () => ({
        url: '/secured/auth/claims',
        method: 'GET'
      })
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: '/secured/auth/logout',
        method: 'POST'
      })
    }),
    refreshUserClaims: build.query<WebSession, void>({
      query: () => ({
        url: '/secured/auth/claims',
        method: 'GET'
      })
    })
  })
});

export const {
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useLogoutMutation,
  useRefreshUserClaimsQuery
} = authApi;
