import { RefData } from '../../types';
import { secureRefSplitApi } from './ref-api';

const rolesApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchRoles: build.query<RefData[], void>({
      query: () => ({ url: '/roles', method: 'GET' })
    })
  }),
  overrideExisting: false
});

export const { useFetchRolesQuery } = rolesApi;
