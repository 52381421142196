import { createSlice } from '@reduxjs/toolkit';
import { fetchCachedItemValues } from './operations';

// Define a type for the slice state
export interface CacheState {
  items: Record<
    string,
    {
      isLoading: boolean;
      values: unknown[];
      error: { message?: string };
    }
  >;
}

// Define the initial state using that type
const initialState: CacheState = {
  items: {}
};

const cacheSlice = createSlice({
  name: 'cache',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCachedItemValues.pending, (state, action) => {
      const prevState = state.items[action.meta.arg] ?? {};
      state.items[action.meta.arg] = {
        ...prevState,
        isLoading: true
      };
    });
    builder.addCase(fetchCachedItemValues.fulfilled, (state, action) => {
      const prevState = state.items[action.meta.arg] ?? {};
      state.items[action.meta.arg] = {
        ...prevState,
        isLoading: false,
        values: action.payload
      };
    });
    builder.addCase(fetchCachedItemValues.rejected, (state, action) => {
      const prevState = state.items[action.meta.arg] ?? {};
      state.items[action.meta.arg] = {
        ...prevState,
        isLoading: false,
        error: { message: action.error.message }
      };
    });
  }
});

export default cacheSlice.reducer;
