import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@client';
import { PortLogInclusionResponse } from '@state';

export const portlogApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'secured/portlog' }),
  reducerPath: 'portlog-api',
  endpoints: (build) => {
    return {
      submitInclusionOfDepartmentToPortlog: build.mutation<
        PortLogInclusionResponse,
        { portlogId: number; shouldInclude: boolean }
      >({
        query: ({ portlogId, shouldInclude }) => ({
          url: `/${portlogId}/include-department`,
          params: { include: shouldInclude },
          method: 'POST'
        })
      })
    };
  }
});

export type stateWithPortLogApi = {
  [portlogApi.reducerPath]: ReturnType<typeof portlogApi.reducer>;
};

export const { useSubmitInclusionOfDepartmentToPortlogMutation } = portlogApi;
