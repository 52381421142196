import {
  EvaluationAvailableToRelateProforma,
  EvaluationAvailableToRelateProformaResponse
} from '../../../module/evaluation/model/EvaluationAvailableToRelateProformaResponse';

export const addSelectedValueToLinkedPDAs = (
  linkedPDAs: EvaluationAvailableToRelateProformaResponse,
  relationIds?: Set<string>
): EvaluationAvailableToRelateProforma => {
  const relationIdsSet = new Set(relationIds);
  return linkedPDAs.map((proforma) => ({
    ...proforma,
    selected: relationIdsSet.has(String(proforma.id))
  }));
};

export const getSelectedPDAsIds = (
  linkedPDAs: EvaluationAvailableToRelateProforma
) => linkedPDAs.filter((PDA) => PDA.selected).map((PDA) => PDA.id);
