import { createSelector } from '@reduxjs/toolkit';
import { selectWizardAppointmentState } from '../appointment';
import { selectAuthCompany } from '../user';
import { getPrincipalDetails } from '../helpers';

export const selectPricipalDetails = createSelector(
  [selectWizardAppointmentState, selectAuthCompany],
  (state, company) => {
    if (!state.preview) return undefined;

    return getPrincipalDetails(
      state.preview,
      Boolean(company?.settings.shouldDisplayLegalEntityAsPrincipal)
    );
  }
);
