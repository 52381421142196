import Cookies from 'js-cookie';

const usePsCookies = () => {
  return {
    // @ts-ignore
    get: (name: string = 'da-app-security') => {
      return Cookies.get(name);
    },
    // @ts-ignore
    has: (name: string = 'da-app-security') => {
      return Cookies.get(name) != null;
    },
    // @ts-ignore
    set: (value: string, days: number, name: string = 'da-app-security') => {
      Cookies.set(name, value, { expires: days });
    },
    // @ts-ignore
    remove: (name: string = 'da-app-security') => {
      Cookies.remove(name);
    }
  };
};

export default usePsCookies;
