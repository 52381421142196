import { createApi } from '@reduxjs/toolkit/query/react';
import ListResponse from '../../../../../types/ListResponse';
import { axiosBaseQuery } from '@client';
import { ProfileResponse } from '@module/profile/model/ProfileResponse';
import {
  ProfileDetailsRequestDto,
  ProfileLoginDetailsRequestDto
} from './models';

interface ListRequest {
  page: number;
  size: number;
  order?: {
    isAsc: boolean;
    property: string;
  };
  filter?: {
    singleValueFilterItems: {
      name: string;
      value: string | number | boolean;
    }[];
  };
}

export const adminProfilesApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['AdminProfiles'],
  reducerPath: 'admin-profiles_api',
  endpoints: (build) => ({
    fetchAdminProfiles: build.query<ListResponse<ProfileResponse>, ListRequest>(
      {
        query: ({ page, size, filter, order }) => ({
          url: `secured/admin-profiles/all`,
          method: 'POST',
          data: {
            page,
            size,
            filter,
            order
          }
        })
      }
    ),
    createProfile: build.mutation<ProfileResponse, ProfileDetailsRequestDto>({
      query: (data) => ({
        url: `secured/admin-profiles/save`,
        method: 'POST',
        data
      })
    }),
    fetchAdminProfile: build.query<ProfileResponse, { profileId: number }>({
      query: ({ profileId }) => ({
        url: `/secured/admin-profile/${profileId}/load`,
        method: 'GET'
      })
    }),
    updateProfile: build.mutation<ProfileResponse, ProfileDetailsRequestDto>({
      query: (data) => ({
        url: `secured/admin-profiles/save`,
        method: 'POST',
        data
      })
    }),
    updateProfileLoginDetails: build.mutation<
      ProfileResponse,
      ProfileLoginDetailsRequestDto
    >({
      query: (data) => ({
        url: '/secured/profile/save-login-details',
        method: 'POST',
        data
      })
    }),
    switchMode: build.mutation<ProfileResponse, { profileId: number | string }>(
      {
        query: ({ profileId }) => ({
          url: `/secured/admin-profiles/${profileId}/mode-switch`,
          method: 'POST'
        })
      }
    )
  })
});

export type StateWithAdminProfilesApi = {
  [adminProfilesApi.reducerPath]: ReturnType<typeof adminProfilesApi.reducer>;
};

export const {
  useFetchAdminProfilesQuery,
  useLazyFetchAdminProfilesQuery,
  useCreateProfileMutation,
  useFetchAdminProfileQuery,
  useUpdateProfileMutation,
  useUpdateProfileLoginDetailsMutation,
  useSwitchModeMutation
} = adminProfilesApi;
