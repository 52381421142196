import { refSplitApi } from './ref-api';

const phoneCountryPrefixesApi = refSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchPhoneCountriesPrefixes: build.query<
      { label: string; value: string }[],
      void
    >({
      query: () => ({ url: '/phone-country-prefixes', method: 'GET' })
    })
  }),
  overrideExisting: false
});

export const { useFetchPhoneCountriesPrefixesQuery } = phoneCountryPrefixesApi;
