import { createApi } from '@reduxjs/toolkit/query/react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { axiosBaseQuery } from '@client';
import { CountNotificationsResponse } from './model/CountNotificationsResponse';
import { RootState } from '../../store';
import ListResponse from '../../../../types/ListResponse';
import { NotificationResponse } from './model/NotificationResponse';
import { PagingRequest } from 'components';
import { notificationsSlice } from './reducers';
import {
  disconnectSocket,
  subscribeToTopic
} from '../../../../common/utils/setup-sse';

const updateNotificationsCountCache = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  updateRecipe: Recipe<CountNotificationsResponse>
) => {
  dispatch(
    notificationsApi.util.updateQueryData(
      'fetchNotificationsCount',
      undefined,
      updateRecipe
    )
  );
};

const reFetchNotifications = (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  getState: () => RootState
) => {
  const {
    [notificationsSlice.name]: { pagination, drawerOpened }
  } = getState();

  const paginationObject = {
    page: 0,
    size: pagination.pageSize * (pagination.page + 1),
    filter: {
      ALL: undefined,
      UNREAD: {
        singleValueFilterItems: [
          {
            name: 'unread',
            value: true
          }
        ],
        arrayFilterItems: []
      },
      EVALUATION: {
        singleValueFilterItems: [
          {
            name: 'type',
            value: 'EVALUATION'
          }
        ],
        arrayFilterItems: []
      },
      ASSIGNMENT: {
        singleValueFilterItems: [
          {
            name: 'type',
            value: 'ASSIGNMENT'
          }
        ],
        arrayFilterItems: []
      }
    }[pagination.filter]
  };
  dispatch(
    notificationsApi.endpoints.fetchNotifications.initiate(paginationObject, {
      forceRefetch: true
    })
  );
};

export const notificationsApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'notifications_api',
  endpoints: (build) => ({
    subscribeToNotifications: build.query<
      { received: string | undefined },
      void
    >({
      queryFn: () => {
        return new Promise((resolve) => {
          resolve({
            data: { received: undefined }
          });
        });
      },
      async onCacheEntryAdded(
        _,
        {
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          updateCachedData,
          dispatch
        }
      ) {
        const state = getState() as RootState;
        const {
          session: {
            authentication: { profile, token }
          }
        } = state;

        const profileIid = profile?.id;

        if (profileIid && token) {
          try {
            await cacheDataLoaded;

            const handleNotification = (event: any) => {
              if (event) {
                const { eventMessage } = {
                  profileId: event.profileId,
                  eventId: event.eventId,
                  eventType: event.eventType,
                  eventMessage: event.eventMessage
                } as {
                  profileId: string;
                  eventId: number;
                  eventType: string;
                  eventMessage: any;
                };

                // Update cached data
                updateCachedData((draft) => {
                  draft.received = eventMessage;
                });

                // Update notifications count in your Redux store
                updateNotificationsCountCache(dispatch, (draft) => {
                  draft.count = draft.count + 1;
                });

                // Re-fetch notifications
                reFetchNotifications(dispatch, getState as () => RootState);
              }
            };

            // Subscribe to notifications with the defined listener function
            subscribeToTopic(
              token,
              profile.id,
              'NOTIFICATIONS',
              handleNotification
            );
          } catch {
            //
          }
          await cacheEntryRemoved;
          disconnectSocket();
        }
      }
    }),
    fetchNotifications: build.query<
      ListResponse<NotificationResponse>,
      PagingRequest
    >({
      query: (pagingRequest) => ({
        url: `/secured/notification/list`,
        method: 'POST',
        data: pagingRequest
      }),
      // change the cache key to ignore page
      serializeQueryArgs: ({ queryArgs }) => {
        const { page, ...others } = queryArgs;
        const pagingRequest = { ...others, page: 0 };
        return pagingRequest;
      },
      // merge incoming items with cached
      merge: (currentCache, newResponse, { arg }) => {
        if (currentCache && arg.page > 0) {
          return {
            ...newResponse,
            items: [...currentCache.items, ...newResponse.items]
          };
        }
        return newResponse;
      }
    }),
    fetchNotificationsCount: build.query<CountNotificationsResponse, void>({
      query: () => ({
        url: `/secured/notification/unread`,
        method: 'GET'
      })
    }),
    markAllNotificationsRead: build.mutation<NotificationResponse[], void>({
      query: () => ({
        url: `/secured/notification/read-all`,
        method: 'POST'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;

          reFetchNotifications(dispatch, getState as () => RootState);

          updateNotificationsCountCache(dispatch, (draft) => {
            draft.count = 0;
          });
        } catch {
          //
        }
      }
    }),
    markNotificationMessageRead: build.mutation<
      NotificationResponse,
      { notificationId: number }
    >({
      query: ({ notificationId }) => ({
        url: `/secured/notification/read`,
        method: 'POST',
        data: notificationId.toString()
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;

          reFetchNotifications(dispatch, getState as () => RootState);

          updateNotificationsCountCache(dispatch, (draft) => {
            draft.count = draft.count - 1;
          });
        } catch {
          //
        }
      }
    }),

    findUrlId: build.mutation<string, { entityId: string; type: string }>({
      query: ({ entityId, type }) => ({
        url: `/secured/notification/router/${type}`,
        method: 'POST',
        data: entityId
      })
    })
  })
});

export type StateWithNotificationsApi = {
  [notificationsApi.reducerPath]: ReturnType<typeof notificationsApi.reducer>;
};

export const {
  useSubscribeToNotificationsQuery,
  useFetchNotificationsQuery,
  useFetchNotificationsCountQuery,
  useMarkAllNotificationsReadMutation,
  useMarkNotificationMessageReadMutation,
  useFindUrlIdMutation
} = notificationsApi;
