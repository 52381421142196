import { createApi } from '@reduxjs/toolkit/query/react';
import { RequestForQuotationComment } from '@state';
import { axiosBaseQuery } from '@client';

export const requestForQuotationApi = createApi({
  reducerPath: 'request-for-quotation-api',
  tagTypes: ['commentsApi-quotation'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    fetchComments: builder.query<
      RequestForQuotationComment[],
      { requestForQuotationId: string; expenseId: string }
    >({
      providesTags: ['commentsApi-quotation'],
      query: ({ requestForQuotationId, expenseId }) => ({
        method: 'GET',
        url: `secured/request-for-quotation/${requestForQuotationId}/expense/${expenseId}/comments`
      })
    }),
    postComment: builder.mutation<
      RequestForQuotationComment[],
      { requestForQuotationId: string; expenseId: string; commentText: string }
    >({
      invalidatesTags: ['commentsApi-quotation'],
      query: ({ requestForQuotationId, expenseId, commentText }) => ({
        method: 'POST',
        url: `secured/request-for-quotation/${requestForQuotationId}/expense/${expenseId}/comments`,
        data: { commentText }
      })
    })
  })
});

export type StateWithRequestForQuotationApi = {
  [requestForQuotationApi.reducerPath]: ReturnType<
    typeof requestForQuotationApi.reducer
  >;
};

// Export the hooks for the query endpoints
export const {
  useFetchCommentsQuery,
  usePostCommentMutation,
  useLazyFetchCommentsQuery
} = requestForQuotationApi;
