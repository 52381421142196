import {
  AppointmentResponseBuyerAndBillingCompanyDetails,
  PrincipalEntityDetails
} from '@state';

export const getPrincipalDetails = (
  response: AppointmentResponseBuyerAndBillingCompanyDetails,
  displayLegalEntityAsPrincipal: boolean
): PrincipalEntityDetails => {
  return displayLegalEntityAsPrincipal
    ? {
        city: response.billingCompany.city,
        country: response.billingCompany.country,
        name: response.billingCompany.name,
        postcode: response.billingCompany.postcode,
        street: response.billingCompany.street,
        fax: '-',
        operationsContactEmail: '-',
        phone: '-',
        siteUrl: '-'
      }
    : {
        city: response.buyer.city,
        country: response.buyer.country,
        name: response.buyer.name,
        postcode: response.buyer.postcode,
        street: response.buyer.street,
        fax: response.buyer.fax,
        operationsContactEmail: response.buyer.operationsContactEmail,
        phone: response.buyer.phone1,
        siteUrl: response.buyer.siteUrl
      };
};
