import get from 'lodash/get';
import React, {
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { Controller, type UseFormMethods } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { PsAsyncSelectFieldHandler } from '../handlers';
import { FieldWrapperProps, RefData } from '../types';
import PsFieldWrapper from './ps-form-field-wrapper';

type Props<T = {}> = {
  name: string;
  entity?: T;
  isDisabled?: boolean;
  isClearable?: boolean;
  form: UseFormMethods;
  onChange?: (value: RefData) => void;
  placeholder?: string;
  validation?: 'none' | 'required';
  filter: (filter: string) => Promise<RefData[]>;
  find?: (id: string) => Promise<RefData>;
  isRequired?: boolean;
  noOptionsMessage?: string;
  isPortal?: boolean;
  ref?: MutableRefObject<PsAsyncSelectFieldHandler>;
  ariaLabel: string;
} & FieldWrapperProps;

const PsAsyncSelectField = React.forwardRef<PsAsyncSelectFieldHandler, Props>(
  (
    {
      placeholder,
      name,
      entity,
      isDisabled = false,
      isClearable = false,
      form,
      onChange,
      isRequired = false,
      filter,
      find,
      noOptionsMessage = 'Insert text to search!',
      isPortal = false,
      ariaLabel,
      ...rest
    },
    ref
  ) => {
    const [value, setValue] = useState<RefData | null>();
    const [defaultValue, setDefaultValue] = useState<RefData | null>();

    useImperativeHandle(ref, () => {
      return {
        reset: reset
      };
    });

    useEffect(() => {
      if (!!entity && !!name && !!find) {
        init();
      }
    }, [entity, name, find]);

    const init = () => {
      if (find) {
        find(get(entity, name))
          .then((value) => {
            setValue(value);
            setDefaultValue(value);
            form.setValue(name, get(value, 'value'));
          })
          .catch((err) => {
            //
          });
      }
    };

    const reset = () => {
      form.setValue(name, get(defaultValue, 'value'));
      setValue(defaultValue);
    };

    const error = get(form.errors, name);
    const hasError = !!error;

    const customStyles = {
      menuPortal: (provided: any, state: any) => {
        const zIndex = 'z-index: 50 !important';
        return { ...provided, zIndex };
      }
    };

    return (
      <PsFieldWrapper
        hasError={hasError}
        errorMessage={get(error, 'message')}
        {...rest}
      >
        <Controller
          name={name}
          control={form.control}
          rules={{ required: isRequired }}
          defaultValue={defaultValue}
          render={() => (
            <AsyncSelect
              name={name}
              isDisabled={isDisabled}
              isClearable={isClearable}
              value={value}
              onChange={(selected: any) => {
                form.setValue(name, get(selected, 'value'));
                setValue(selected);
                if (onChange) onChange(selected);
              }}
              loadOptions={filter}
              noOptionsMessage={() => noOptionsMessage}
              classNamePrefix="custom-select"
              styles={customStyles}
              placeholder={placeholder}
              menuPortalTarget={isPortal ? document.body : undefined}
              className={hasError ? 'is-danger' : ''}
              aria-label={ariaLabel}
            />
          )}
        />
      </PsFieldWrapper>
    );
  }
);

export default PsAsyncSelectField;
