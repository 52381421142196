import React, {
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef
} from 'react';
import RichTextEditor from 'react-rte';
import { PsRichTextHandler } from '../handlers';
import PsStyle from './ps-style';

interface Props {
  html: string;
  disabled?: boolean;
  className?: string;
  onChange?: (val: string) => void;
  ref?: MutableRefObject<PsRichTextHandler>;
}

const PsRichText = React.forwardRef<PsRichTextHandler, Props>(
  (
    { html, className, disabled = false, onChange },
    ref: React.Ref<PsRichTextHandler>
  ) => {
    const [editorState, setEditorState] = React.useState(
      RichTextEditor.createEmptyValue()
    );

    const editor = useRef<RichTextEditor>(null);

    useEffect(() => {
      setEditorState(RichTextEditor.createValueFromString(html, 'html'));
    }, [html]);

    useEffect(() => {
      if (!!onChange) onChange(getContent());
    }, [editorState]);

    useImperativeHandle(ref, () => {
      return {
        content: getContent(),
        // @ts-ignore
        focus: () => editor.current!._focus(),
        clear: () => setEditorState(RichTextEditor.createEmptyValue()),
        reset: () =>
          setEditorState(RichTextEditor.createValueFromString(html, 'html')),
        isEmpty: isEmpty()
      };
    });

    const getContent = () => {
      return !isEmpty() ? editorState.toString('html') : '';
    };

    const isEmpty = () => {
      return !editorState.getEditorState().getCurrentContent().hasText();
    };

    const toolbarConfig: any = {
      display: [
        'INLINE_STYLE_BUTTONS',
        'BLOCK_TYPE_BUTTONS',
        'HISTORY_BUTTONS'
      ],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
      ]
    };

    return (
      <div
        className={
          'editor has-max-height is-fs-15' + PsStyle.applyClassName(className)
        }
        style={{ wordBreak: 'break-all' }}
      >
        <RichTextEditor
          ref={editor}
          disabled={disabled}
          value={editorState}
          toolbarConfig={toolbarConfig}
          editorClassName="editor"
          placeholder="Write your message..."
          onChange={setEditorState}
        />
      </div>
    );
  }
);

export default PsRichText;
