import { secureRefSplitApi } from './ref-api';

const expenseEvalVerificationDiscrepancyTypesApi =
  secureRefSplitApi.injectEndpoints({
    endpoints: (build) => ({
      fetchExpenseEvalVerificationDiscrepancyTypes: build.query<
        { label: string; value: string }[],
        void
      >({
        query: () => ({
          url: '/expense-evaluation-verification-discrepancy-types',
          method: 'GET'
        })
      })
    }),
    overrideExisting: false
  });

export const { useFetchExpenseEvalVerificationDiscrepancyTypesQuery } =
  expenseEvalVerificationDiscrepancyTypesApi;
