import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Filter,
  FiltersState,
  SortingState,
  filtersAdapter
} from '../../../../types';

export type PortTerminalsState = SortingState & FiltersState;

const initialState: PortTerminalsState = {
  filters: filtersAdapter.getInitialState()
};

export const portTerminalsTableSlice = createSlice({
  name: 'port-analysis-port-terminals-table',
  initialState,
  reducers: {
    updateSorting: (state, { payload }: PayloadAction<SortingState>) => {
      state.direction = payload.direction;
      state.property = payload.property;
    },
    updateFilter: (state, { payload }: PayloadAction<Filter>) => {
      state.filters = filtersAdapter.upsertOne(state.filters, payload);
    },
    clearFilter: (state, { payload }: PayloadAction<{ name: string }>) => {
      state.filters = filtersAdapter.removeOne(state.filters, payload.name);
    },
    resetFilters: (state) => {
      state.filters = filtersAdapter.removeAll(state.filters);
    }
  }
});

export const {
  updateSorting: updatePortTerminalsSorting,
  updateFilter: updatePortTerminalsFilter,
  clearFilter: clearPortTerminalsFilter,
  resetFilters: resetPortTerminalsFilters
} = portTerminalsTableSlice.actions;

export type StateWithPortTerminalsState = {
  [portTerminalsTableSlice.name]: PortTerminalsState;
};
