import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@client';
import RuleDTO, {
  ProfileRulesSubmitDTO,
  RegionRuleSubmitDTO
} from '../../types/RuleDTO';
import ListRequest from '../../../../types/ListRequest';
import ListResponse from '../../../../types/ListResponse';
import { ProfileResponse } from '@module/profile/model/ProfileResponse';
import { RefData } from 'components';

interface FetchAdminRulesRequest extends ListRequest {
  buyerId: number;
}

export const adminRulesApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['AdminRules'],
  reducerPath: 'admin-rules_api',
  endpoints: (build) => ({
    fetchRules: build.query<ListResponse<RuleDTO>, FetchAdminRulesRequest>({
      query: ({ page, pageSize, buyerId, ordering }) => ({
        url: 'secured/rules',
        method: 'GET',
        params: {
          page: page,
          size: pageSize,
          buyerId: buyerId,
          property: ordering?.property,
          direction: ordering?.direction
        }
      }),
      providesTags: ['AdminRules']
    }),
    fetchEntityTypes: build.query<RefData[], void>({
      query: () => ({
        url: 'secured/rule/entity-types',
        method: 'GET'
      })
    }),
    submitRule: build.mutation<RuleDTO, RegionRuleSubmitDTO>({
      query: (payload) => ({
        url: '/secured/rule',
        method: 'PUT',
        data: payload
      }),
      invalidatesTags: ['AdminRules']
    }),
    submitRuleProfile: build.mutation<ProfileResponse, ProfileRulesSubmitDTO>({
      query: (payload) => ({
        url: '/secured/profile/rules',
        method: 'post',
        data: payload
      })
    }),
    editRule: build.mutation<RuleDTO, RegionRuleSubmitDTO>({
      query: (payload) => ({
        url: '/secured/rule',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: ['AdminRules']
    }),
    fetchRule: build.query<RuleDTO, number>({
      query: (ruleId) => ({
        url: `/secured/rule/${ruleId}`,
        method: 'GET'
      }),
      providesTags: ['AdminRules']
    })
  })
});

export type StateWithAdminRulesApi = {
  [adminRulesApi.reducerPath]: ReturnType<typeof adminRulesApi.reducer>;
};

export const {
  useFetchRulesQuery,
  useLazyFetchRulesQuery,
  useSubmitRuleMutation,
  useLazyFetchEntityTypesQuery,
  useFetchRuleQuery,
  useLazyFetchRuleQuery,
  useEditRuleMutation,
  useSubmitRuleProfileMutation,
  useFetchEntityTypesQuery
} = adminRulesApi;
