import { IMessages, MessageFormat } from './common';

export const en: IMessages = {
  CANNOT_CREATE_PROFORMA_SAILING_DATE_NULL_FOR_FINAL_OR_INVOICE:
    'Please edit the values of the DA to add a sailing date so that you can proceed with the FDA submission.',
  CANNOT_SPLIT_AMOUNT_GREATER_THAN_REMAINING_OR_ZERO:
    'Cannot split amount greater than remaining or zero',
  CANNOT_SUBMIT_NO_PROFORMA_EXPENSE_IS_QUOTED:
    'Please quote at least one expense!',
  CANNOT_SUBMIT_PROFORMA_EXPENSE_WITH_NO_PARAMETERS_FULFILLED:
    'There are quoted expenses with missing parameters!',
  CANNOT_SUBMIT_MISSING_ATTACHMENT: 'Please add at least one attachment!',
  CANNOT_SUBMIT_MISSING_BANK_ACCOUNT: 'Please select bank account!',
  EVALUATION_PENDING_APPROVAL_EXPENSES:
    'There are expenses that are pending verification!',
  CANNOT_VERIFY_MISSING_ACCOUNTING_CODE:
    'Please add "Description/Account" in order to approve expense!',
  CANNOT_CREATE_APPOINTMENT_ALREADY_EXISTS:
    'There is already an appointment for this company!',
  CANNOT_VERIFY_CHARTERERS_AMOUNT_GREATER_THAN_REMAINING:
    'The charterers amount cannot be greater than the amount',
  CANNOT_SPLIT_CHARTERERS_AMOUNT_GREATER_THAN_REMAINING:
    'The charterers amount cannot be greater than the amount',
  CANNOT_SAVE_VERIFICATION_WRONG_DISCREPANCY_AMOUNT:
    'Please provide a valid discrepancy amount',
  CANNOT_SAVE_VERIFICATION_NO_DISCREPANCY_TYPE:
    'Please provide discrepancy type to verify expense',
  SESSION_EXPIRED: 'Your session has expired. Please log-in again.',
  settings: {
    common: {
      accountDetails: {
        success: 'Your profile has been saved successfully!',
        error: { phonePrefix: 'Please insert a phone prefix' }
      },
      accountSecurity: {
        success: 'Your password has been saved successfully!'
      },
      companyDetails: {
        success: 'Your company profile has been saved successfully!',
        error: { countryId: 'Please select a country' }
      }
    },
    agent: {
      ports: {
        save: { success: 'Ports have been saved successfully!' }
      },
      expertise: {
        save: { success: 'Expertise has been saved successfully!' }
      },
      email: {
        save: { success: 'Email has been saved successfully' },
        create: { success: 'Email has been created successfully!' },
        delete: { success: 'Email has been deleted!' },
        error: {
          notValid: 'This is not a valid email',
          noSelectedPort: 'Please select at least one port',
          emailExists: 'This email already exists'
        }
      }
    },
    buyer: {
      billingCompanies: {
        save: { success: 'Billing company has been saved successfully!' },
        create: { success: 'Billing company has been created successfully!' }
      },
      buyerContact: {
        save: { success: 'Buyer contact has been saved successfully!' },
        create: { success: 'Buyer contact has been created successfully!' },
        error: { phonePrefix: 'Please insert a phone prefix' }
      },
      buyerProfile: {
        save: { success: 'Buyer profile has been saved successfully!' },
        create: { success: 'Buyer profile has been created successfully!' },
        error: {
          usernameExists: 'There is already a user with this Email (Username)',
          vesselNotFound: 'One of the assigned vessels does not exist'
        }
      },
      vessel: {
        save: { success: 'Vessel has been saved successfully!' },
        create: { success: 'Vessel has been created successfully!' },
        error: {
          billingCompanyNotExists:
            'The selected billing company does not exist!'
        }
      }
    }
  },
  login: {
    error: 'Username or password is wrong. Please try again!'
  },
  common: {
    error: {
      general:
        'There was an error processing your request. Please try again later or contact support',
      form: 'Form submission error: There are validation errors in your form',
      validate: 'Please check all required fields!',
      field: {
        required: 'This field is required',
        email: 'Please insert a valid email',
        number: 'Please insert a valid number',
        password:
          'The password must consist of numbers and letters and should have from 6 to 16 characters',
        cvv: 'Please insert a valid cvv',
        vat: 'Please insert a valid vat',
        date: 'Please insert a valid date',
        ccNumber: 'Please insert a valid credit card number',
        maxLength: new MessageFormat('Maximum {0} characters are allowed!'),
        range: new MessageFormat('Please enter an amount up to {0}!')
      },
      attachment: {
        type: new MessageFormat(
          'Please add a file with one of the following types: {0}'
        )
      }
    }
  },
  registration: {
    usernameToolTip:
      'You will need access to this email address to verify your account.',
    passwordToolTip:
      'Password must contain letters & numbers in a length from 6 to 16 characters!',
    success: {
      save: 'Your company profile has been saved successfully!'
    },
    error: {
      terms: 'Please accept the terms to continue',
      passwordsNoMatch: 'Passwords do not match',
      mailGroup1NoMatch: 'First RFQs & Orders Email does not match',
      mailGroup2NoMatch: 'Second RFQs & Orders Email does not match',
      sameEmails: 'Please use different emails',
      userAlreadyExists: 'There is already a user registered with this email',
      companyAlreadyExists:
        'There is already a company registered with this name',
      phonePrefix: 'Please insert a phone prefix',
      country: 'Country is not defined',
      password:
        'Password must contain letters & numbers in a length from 6 to 16 characters!'
    },
    verify: {
      success: {
        email: 'Email has been sent successfully'
      },
      error: {
        email: 'Email could not be sent at the moment. Please contact support!'
      }
    },
    ports: {
      error: {
        empty: 'Please add at least one port!'
      }
    },
    categories: {
      error: {
        empty: 'Please add at least one category!'
      }
    }
  },
  voyage: {
    success: {
      save: 'Voyage saved successfully!'
    },
    error: {
      save: {
        voyageNumberExists:
          'Voyage Number already exists. Please use another one.'
      }
    }
  },
  buyerDashboard: {
    reminderSent: 'Reminder has been sent successfully!',
    noMatchPortCalls: 'No items match the filtering criteria',
    emptyPortCalls: 'There are no active port calls at the moment.',
    appointmentSeen: 'Appointment is Seen',
    appointmentUnSeen: 'Appointment is Unseen',
    sendReminder: 'Send reminder',
    sendReminderForAppointment: new MessageFormat(
      'Sent reminder for appointment {0}'
    ),
    noPendingActionsForAppointment:
      'No further actions are expected for this appointment'
  },
  portCall: {
    success: {
      save: 'Port call saved successfully!',
      cancel: 'Port call cancelled successfully!',
      delete: 'Port call deleted!'
    },
    error: {
      noAgents: 'Please add at least one agent!',
      noAgentOrVendor: 'Please add at least one agent or vendor!',
      noActivities: 'Please add at least one activity!',
      exists:
        'There is already a port call for this vessel, for this port with the same voyage number!',
      dateRange: 'Please insert an ETS date after the ETA date',
      laycanDate: 'Please insert a laycan end date after the laycan start date',
      invalidEmails: 'There are invalid emails in your form!'
    }
  },
  appointment: {
    success: {
      reminderSent: 'Reminder has been sent successfully!',
      cancel: 'Appointment has been cancelled!',
      delete: 'Appointment has been deleted!',
      send: 'Appointments have been sent successfully!'
    },
    error: {
      reminderSent:
        'You have reached the limit of the number of reminders you can send for this appointment!',
      deleteOnlyDraft: 'You can only delete draft appointments!',
      cannotCancelWithFDAs:
        'Appointment contains FDAs and cannot be cancelled!',
      cancel: 'Failed to cancel appointment!',
      reject: 'Failed to reject appointment!'
    },
    emailConfiguration: {
      success: {
        approve: 'Email configuration approved successfully!'
      },
      error: {
        notAllApproved: 'All Emails should be approved before submitting!'
      }
    },
    proforma: {
      success: {
        create: new MessageFormat(
          'Draft {0} has been initialized successfully!'
        ),
        saveDraft: 'Draft has been saved successfully!',
        submit: new MessageFormat('{0} submitted successfully!'),
        delete: 'Proforma has been deleted!',
        withdraw: 'Proforma has been withdrawn!'
      },
      error: {
        noItems: 'Please quote at least one item!',
        additionalItems:
          'Please define the name and the amount in all additional items!',
        existsDraft: 'Please submit or delete the existing draft!',
        editOnlyDraft:
          'You cannot edit a submitted proforma. Please create a new one!',
        form: {
          noCurrency: 'Please select the currency!',
          noRate: 'Please add the rate!',
          noTerminal: 'Please select the terminal!',
          noBerth: 'Please select the berth',
          noBankAccount: 'Please select the bank account',
          newItem: {
            noName: 'There are new items without name!',
            noAmount: 'There are new items without amount!'
          }
        },
        noAttachments: new MessageFormat(
          '{0} requires at least one file attached'
        ),
        noStatementOfFacts: new MessageFormat(
          '{0} requires the statement of facts file'
        ),
        noPreInvoiceAttachments: new MessageFormat(
          '{0} requires at least one pre-invoice file attached'
        ),
        invalidFileSizes:
          'The files you are trying to upload have invalid size',
        expense: {
          noAttachment: {
            generic:
              'Please provide attachments for all expenses that you have quoted',
            bankCharges:
              'Please note that, in order to be re-imbursed for the PDA remittance bank charges, you have to attach the relevant bank payment documents.'
          }
        }
      }
    },
    approve: {
      success: {
        restore: 'Item restored successfully!',
        save: 'Item approved successfully!',
        recalculate: 'Calculation finished successfully!',
        savePaymentValues: 'Payment values saved successfully!',
        saveItemParams: 'Parameters saved successfully!',
        itemSplit: 'Item split successfully!',
        itemSplitAndApprove: 'Item split and approved successfully!',
        itemReject: 'Item rejected successfully!',
        itemEdit: 'Item edited successfully!',
        saveDraft: 'Draft saved successfully!',
        approve: new MessageFormat('{0} approved successfully!'),
        reject: new MessageFormat('{0} rejected successfully!'),
        delete: new MessageFormat('{0} deleted!'),
        complete: new MessageFormat('{0} completed successfully!')
      },
      error: {
        cannotBeApproved: 'This item cannot be approved at the moment!',
        noDiscrepancy: 'You need to define the discrepancy for this item!',
        noAccountsForFinal:
          "Please define the 'Account Code' and 'Sub Account\n' for this item!",
        cannotAddSplit: 'Split Item amount exceeds total amount!',
        approve: 'Please make sure that all items are verified!'
      },
      warning: {
        remittedAmount: {
          belowThreshold: new MessageFormat(
            'Remitted amount is less than {0}% of the total approved amount!'
          ),
          moreThanTotalApproved:
            'Remitted amount is more than the total approved amount!'
        }
      }
    }
  },
  assignment: {
    success: {
      create: 'Port log has been created successfully!',
      update: 'Port log has been updated successfully!',
      submit: 'Port log has been submitted successfully!',
      noEntries: 'Your department currently has no port log entries',
      cancel: 'Port log has been cancelled successfully!'
    },
    error: {
      assignmentExists: 'There is already a port log for this port call!',
      portLogDepartmentNotExists:
        'The selected department port log does not exist!',
      portLogDepartmentEntryNotExists:
        'The selected department port log entry does not exist!',
      submitEmpty: 'Port logs has no entries!',
      recipientNotAgent: 'Port log recipient is not an agent!'
    }
  },
  requests: {
    success: {
      saveDraft: 'Draft requests have been saved successfully!',
      send: 'Requests have been sent successfully!',
      reminderSent: 'Reminder has been sent successfully!',
      delete: 'Draft requests have been deleted!',
      cancel: 'Requests have been cancelled!'
    },
    error: {
      noActivities: 'Please select at least one activity!',
      noAgents: 'Please select at least one agent!',
      doDraftRequests: 'There is no draft request to submit.',
      noSection: 'Please select at least one expense type!',
      reminderSent: 'You have reached the limit of the number of reminders!',
      noHusbandryExpense: 'Please select at least one husbandry expense!',
      noChangesDone: 'You have not done any change!',
      noExpensesQuoted: 'You have not quoted any expense!',
      noSelectedEmail: 'Please select at least one email.'
    }
  },
  notes: {
    success: {
      save: 'Note has been saved successfully!',
      delete: 'Note has been deleted!'
    }
  },
  message: {
    success: {
      send: 'Message sent successfully!'
    },
    error: {
      send: 'Message cannot be sent at the moment!'
    }
  }
};
