import { secureRefSplitApi } from './ref-api';

const continents = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchContinents: build.query<{ label: string; value: string }[], void>({
      query: () => ({ url: '/continents', method: 'GET' })
    })
  }),
  overrideExisting: false
});

export const { useFetchContinentsQuery } = continents;
