import { secureRefSplitApi } from './ref-api';

const activePortsApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchActivePorts: build.query<
      { label: string; value: string }[],
      { filter: string }
    >({
      query: ({ filter }) => ({
        url: '/filter/active-ports',
        method: 'GET',
        params: {
          filter
        }
      })
    })
  }),
  overrideExisting: false
});

export const { useLazyFetchActivePortsQuery } = activePortsApi;
