export interface IMessages {
  CANNOT_CREATE_PROFORMA_SAILING_DATE_NULL_FOR_FINAL_OR_INVOICE: string;
  CANNOT_SUBMIT_NO_PROFORMA_EXPENSE_IS_QUOTED: string;
  CANNOT_SUBMIT_PROFORMA_EXPENSE_WITH_NO_PARAMETERS_FULFILLED: string;
  CANNOT_SUBMIT_MISSING_ATTACHMENT: string;
  CANNOT_SUBMIT_MISSING_BANK_ACCOUNT: string;
  EVALUATION_PENDING_APPROVAL_EXPENSES: string;
  CANNOT_VERIFY_MISSING_ACCOUNTING_CODE: string;
  CANNOT_CREATE_APPOINTMENT_ALREADY_EXISTS: string;
  CANNOT_VERIFY_CHARTERERS_AMOUNT_GREATER_THAN_REMAINING: string;
  CANNOT_SPLIT_CHARTERERS_AMOUNT_GREATER_THAN_REMAINING: string;
  CANNOT_SAVE_VERIFICATION_WRONG_DISCREPANCY_AMOUNT: string;
  CANNOT_SPLIT_AMOUNT_GREATER_THAN_REMAINING_OR_ZERO: string;
  CANNOT_SAVE_VERIFICATION_NO_DISCREPANCY_TYPE: string;
  SESSION_EXPIRED: string;
  settings: {
    common: {
      accountDetails: {
        success: string;
        error: { phonePrefix: string };
      };
      accountSecurity: { success: string };
      companyDetails: {
        success: string;
        error: { countryId: string };
      };
    };
    agent: {
      ports: {
        save: { success: string };
      };
      expertise: {
        save: { success: string };
      };
      email: {
        save: { success: string };
        create: { success: string };
        delete: { success: string };
        error: {
          notValid: string;
          noSelectedPort: string;
          emailExists: string;
        };
      };
    };
    buyer: {
      billingCompanies: {
        save: { success: string };
        create: { success: string };
      };
      buyerContact: {
        save: { success: string };
        create: { success: string };
        error: { phonePrefix: string };
      };
      buyerProfile: {
        save: { success: string };
        create: { success: string };
        error: { usernameExists: string; vesselNotFound: string };
      };
      vessel: {
        save: { success: string };
        create: { success: string };
        error: { billingCompanyNotExists: string };
      };
    };
  };

  login: {
    error: string;
  };
  common: {
    error: {
      general: string;
      form: string;
      validate: string;
      field: {
        required: string;
        email: string;
        number: string;
        password: string;
        cvv: string;
        vat: string;
        date: string;
        ccNumber: string;
        maxLength: MessageFormat;
        range: MessageFormat;
      };
      attachment: {
        type: MessageFormat;
      };
    };
  };
  registration: {
    usernameToolTip: string;
    passwordToolTip: string;
    success: {
      save: string;
    };
    error: {
      terms: string;
      passwordsNoMatch: string;
      mailGroup1NoMatch: string;
      mailGroup2NoMatch: string;
      sameEmails: string;
      userAlreadyExists: string;
      companyAlreadyExists: string;
      phonePrefix: string;
      country: string;
      password: string;
    };
    verify: {
      success: {
        email: string;
      };
      error: {
        email: string;
      };
    };
    ports: {
      error: {
        empty: string;
      };
    };
    categories: {
      error: {
        empty: string;
      };
    };
  };
  voyage: {
    success: {
      save: string;
    };
    error: {
      save: {
        voyageNumberExists: string;
      };
    };
  };
  portCall: {
    success: {
      save: string;
      cancel: string;
      delete: string;
    };
    error: {
      noAgents: string;
      noAgentOrVendor: string;
      noActivities: string;
      invalidEmails: string;
      exists: string;
      dateRange: string;
      laycanDate: string;
    };
  };
  buyerDashboard: {
    reminderSent: string;
    noMatchPortCalls: string;
    emptyPortCalls: string;
    appointmentSeen: string;
    appointmentUnSeen: string;
    sendReminder: string;
    sendReminderForAppointment: MessageFormat;
    noPendingActionsForAppointment: string;
  };
  appointment: {
    success: {
      reminderSent: string;
      cancel: string;
      delete: string;
      send: string;
    };
    error: {
      reminderSent: string;
      deleteOnlyDraft: string;
      cannotCancelWithFDAs: string;
      cancel: string;
      reject: string;
    };
    emailConfiguration: {
      success: {
        approve: string;
      };
      error: {
        notAllApproved: string;
      };
    };
    proforma: {
      success: {
        create: MessageFormat;
        saveDraft: string;
        submit: MessageFormat;
        delete: string;
        withdraw: string;
      };
      error: {
        noItems: string;
        additionalItems: string;
        existsDraft: string;
        editOnlyDraft: string;
        form: {
          noCurrency: string;
          noRate: string;
          noTerminal: string;
          noBerth: string;
          noBankAccount: string;
          newItem: {
            noName: string;
            noAmount: string;
          };
        };
        noAttachments: MessageFormat;
        noStatementOfFacts: MessageFormat;
        noPreInvoiceAttachments: MessageFormat;
        invalidFileSizes: string;
        expense: {
          noAttachment: {
            generic: string;
            bankCharges: string;
          };
        };
      };
    };
    approve: {
      success: {
        restore: string;
        save: string;
        recalculate: string;
        savePaymentValues: string;
        saveItemParams: string;
        itemSplit: string;
        itemSplitAndApprove: string;
        itemReject: string;
        itemEdit: string;
        saveDraft: string;
        approve: MessageFormat;
        reject: MessageFormat;
        delete: MessageFormat;
        complete: MessageFormat;
      };
      error: {
        cannotBeApproved: string;
        noDiscrepancy: string;
        noAccountsForFinal: string;
        cannotAddSplit: string;
        approve: string;
      };
      warning: {
        remittedAmount: {
          belowThreshold: MessageFormat;
          moreThanTotalApproved: string;
        };
      };
    };
  };
  assignment: {
    success: {
      create: string;
      update: string;
      noEntries: string;
      submit: string;
      cancel: string;
    };
    error: {
      assignmentExists: string;
      portLogDepartmentNotExists: string;
      portLogDepartmentEntryNotExists: string;
      submitEmpty: string;
      recipientNotAgent: string;
    };
  };
  requests: {
    success: {
      saveDraft: string;
      send: string;
      reminderSent: string;
      delete: string;
      cancel: string;
    };
    error: {
      noActivities: string;
      noAgents: string;
      doDraftRequests: string;
      noSection: string;
      reminderSent: string;
      noHusbandryExpense: string;
      noChangesDone: string;
      noExpensesQuoted: string;
      noSelectedEmail: string;
    };
  };
  message: {
    success: {
      send: string;
    };
    error: {
      send: string;
    };
  };
  notes: {
    success: {
      save: string;
      delete: string;
    };
  };
}

export class MessageFormat {
  text: string;

  constructor(text: string) {
    this.text = text;
  }

  get(...params: any[]): string {
    return this.text.replace(/\{(\d+)\}/g, function () {
      return params[arguments[1]];
    });
  }
}
