import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type SanitizedRfQPage = {
  code?: string;
  requestCaseId?: number;
};

const initialState: SanitizedRfQPage = {
  code: undefined,
  requestCaseId: undefined
};

export const sanitizedRfQPageSlice = createSlice({
  name: 'sanitizedRfQPage',
  initialState,
  reducers: {
    updatePageData: (
      state,
      { payload }: PayloadAction<{ code: string; requestCaseId: number }>
    ) => {
      state.code = payload.code;
      state.requestCaseId = payload.requestCaseId;
    },
    resetPageData: (state) => {
      state.code = undefined;
      state.requestCaseId = undefined;
    }
  }
});

export const {
  updatePageData: updateSanitizedRfQPageData,
  resetPageData: resetSanitizedRfQPageData
} = sanitizedRfQPageSlice.actions;

export type StateWithSanitizedRfQPage = {
  [sanitizedRfQPageSlice.name]: SanitizedRfQPage;
};
