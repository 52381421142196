import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  Filter,
  SortingState,
  TableState,
  filtersAdapter
} from '../../../../../types';

export type BuyerTeamsState = TableState;

const initialState: BuyerTeamsState = {
  page: 0,
  size: 10,
  filters: filtersAdapter.getInitialState()
};

export const buyerTeamsTableSlice = createSlice({
  name: 'buyer-teams-table',
  initialState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.page = payload.page;
    },
    updateSorting: (state, { payload }: PayloadAction<SortingState>) => {
      state.direction = payload.direction;
      state.property = payload.property;
      state.page = 0;
    },
    updateFilter: (state, { payload }: PayloadAction<Filter>) => {
      state.filters = filtersAdapter.upsertOne(state.filters, payload);
      state.page = 0;
    },
    clearFilter: (state, { payload }: PayloadAction<{ name: string }>) => {
      state.filters = filtersAdapter.removeOne(state.filters, payload.name);
      state.page = 0;
    },
    resetFilters: (state) => {
      state.filters = filtersAdapter.removeAll(state.filters);
      state.page = 0;
    }
  }
});

export const {
  updatePage: updateBuyerTeamsPage,
  updateSorting: updateBuyerTeamsSorting,
  updateFilter: updateBuyerTeamsFilter,
  clearFilter: clearBuyerTeamsFilter,
  resetFilters: resetBuyerTeamsFilters
} = buyerTeamsTableSlice.actions;

export type StateWithBuyerTeamsState = {
  [buyerTeamsTableSlice.name]: BuyerTeamsState;
};
