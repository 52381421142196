import { refSplitApi } from './ref-api';

const countriesApi = refSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchCountries: build.query<{ label: string; value: string }[], void>({
      query: () => ({ url: '/countries', method: 'GET' })
    })
  }),
  overrideExisting: false
});

export const { useFetchCountriesQuery } = countriesApi;
