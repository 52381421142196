import { axiosBaseQuery } from '@client';
import { BuyerPaymentInstructionResponse } from '@module/buyer/model/BuyerPaymentInstructionResponse';
import { createApi } from '@reduxjs/toolkit/dist/query/react';

export const paymentInstructionsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'secured' }),
  reducerPath: 'payment-instructions_api',
  endpoints: (build) => ({
    fetchPaymentInstructions: build.query<
      BuyerPaymentInstructionResponse[],
      { buyerId: string }
    >({
      query: ({ buyerId }) => ({
        url: `/buyers/${buyerId}/payment-instructions`,
        method: 'GET'
      })
    }),
    fetchAllPaymentInstructions: build.query<
      BuyerPaymentInstructionResponse[],
      void
    >({
      query: () => ({
        url: `/payment-instructions`,
        method: 'GET'
      })
    })
  })
});

export const {
  useFetchPaymentInstructionsQuery,
  useFetchAllPaymentInstructionsQuery
} = paymentInstructionsApi;
