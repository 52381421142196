import { RefData } from '../../types';
import { secureRefSplitApi } from './ref-api';

const buyerDepartmentsApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchBuyerDepartments: build.query<RefData[], void>({
      query: () => ({ url: '/buyer-departments', method: 'GET' })
    }),
    fetchBuyerEligibleDepartments: build.query<
      RefData[],
      { buyerId: string | undefined }
    >({
      query: ({ buyerId }) => ({
        url: '/buyer-eligible-departments',
        method: 'GET',
        params: {
          buyerId
        }
      })
    })
  }),
  overrideExisting: false
});

export const {
  useFetchBuyerDepartmentsQuery,
  useFetchBuyerEligibleDepartmentsQuery
} = buyerDepartmentsApi;
