import { useEffect } from 'react';

export const useDisableBodyScroll = (open: boolean) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add('is-clipped');
    } else {
      document.body.classList.remove('is-clipped');
    }

    return () => {
      document.body.classList.remove('is-clipped');
    };
  }, [open]);
};
