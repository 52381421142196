import {
  createSelector,
  createSlice,
  isAnyOf,
  PayloadAction
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { CompanyResponse } from '../../../module/company/model/CompanyResponse';
import { ProfileResponse } from '../../../module/profile/model/ProfileResponse';
import { authApi } from '../restApi/secured/authApi';
import {
  createAfterLoginPath,
  createDAToolBasePath,
  createProfilePath,
  createSettingsBasePath
} from './helpers';

// States' definition
export interface AuthenticationState {
  token?: string | null;
  profile?: ProfileResponse | null;
  company?: CompanyResponse | null;
  adminBuyerCompanies?: CompanyResponse[] | null;
  intercomHash?: string | null;
}

export interface RegistrationState {
  isProcessing: boolean;
  message: string;
  status: string;
}

export interface ForgetPasswordState {
  email: string;
  isProcessing: boolean;
}

export interface ChangePasswordState {
  key: string;
  password: string;
  confirmPassword: string;
  isProcessing: boolean;
}

export interface RequestDemoState {
  isProcessing: boolean;
}

export interface ContactState {
  isProcessing: boolean;
}

export interface SurveyState {
  showNpsSurvey: boolean;
}

export const initialAuthState: AuthenticationState = {
  profile: null,
  company: null,
  adminBuyerCompanies: null,
  token: null
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    set: (
      state,
      action: PayloadAction<{
        profile: ProfileResponse;
        company: CompanyResponse;
        token: string;
      }>
    ) => {
      state.token = action.payload.token;
      state.profile = action.payload.profile;
      state.company = action.payload.company;
    },
    setToken: (
      state,
      {
        payload: { token }
      }: PayloadAction<{
        token: string;
      }>
    ) => {
      state.token = token;
    },
    setProfile: (state, action: PayloadAction<ProfileResponse>) => {
      state.profile = action.payload;
    },
    setCompany: (state, action: PayloadAction<CompanyResponse>) => {
      state.company = action.payload;
    },
    clear: (state) => {
      state.token = null;
      state.profile = null;
    },
    clearToken: (state) => {
      state.token = null;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        authApi.endpoints.getUserProfile.matchFulfilled,
        authApi.endpoints.refreshUserClaims.matchFulfilled
      ),
      (state, { payload: { profile, company, intercomHash } }) => {
        state.profile = profile;
        state.company = company;
        state.intercomHash = intercomHash;
      }
    );

    builder.addMatcher(
      isAnyOf(
        authApi.endpoints.logout.matchFulfilled,
        authApi.endpoints.logout.matchRejected
      ),
      (state) => {
        state.profile = null;
        state.token = null;
        state.company = null;
        state.intercomHash = null;
      }
    );
  }
});

export const { setToken, setProfile } = authenticationSlice.actions;

// Initial state needs to be TYPED
// https://redux-toolkit.js.org/usage/usage-with-typescript#defining-the-initial-state-type
const initialRegistrationState: RegistrationState = {
  isProcessing: false,
  message: '',
  status: ''
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState: initialRegistrationState,
  reducers: {
    inProcess: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    setStatusWithMessage: (
      state,
      action: PayloadAction<{
        message: string;
        status: 'SUCCESS' | 'FAILED' | 'NONE';
      }>
    ) => {
      state.message = action.payload.message;
      state.status = action.payload.status;
    },
    setStatus: (
      state,
      action: PayloadAction<'SUCCESS' | 'FAILED' | 'NONE'>
    ) => {
      state.message = '';
      state.status = action.payload;
    }
  }
});

const initialRequestDemoState: RequestDemoState = { isProcessing: false };

export const requestDemoSlice = createSlice({
  name: 'request-demo',
  initialState: initialRequestDemoState,
  reducers: {
    inProcess: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    }
  }
});

const initialContactSlice: ContactState = {
  isProcessing: false
};

export const contactSlice = createSlice({
  name: 'contact',
  initialState: initialContactSlice,
  reducers: {
    inProcess: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    }
  }
});

const initialForgetPasswordSlice: ForgetPasswordState = {
  email: '',
  isProcessing: false
};

export const forgetPasswordSlice = createSlice({
  name: 'forget-password',
  initialState: initialForgetPasswordSlice,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    inProcess: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    }
  }
});

const initialChangePasswordState: ChangePasswordState = {
  key: '',
  password: '',
  confirmPassword: '',
  isProcessing: false
};

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState: initialChangePasswordState,
  reducers: {
    set: (
      state,
      action: PayloadAction<{
        key: string;
        password: string;
        confirmPassword: string;
      }>
    ) => {
      state.key = action.payload.key;
      state.password = action.payload.password;
      state.confirmPassword = action.payload.confirmPassword;
    },
    inProcess: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    }
  }
});

const initSurveyState: SurveyState = {
  showNpsSurvey: false
};

export const surveySlice = createSlice({
  name: 'survey',
  initialState: initSurveyState,
  reducers: {
    setShowNpsSurvey: (state, action: PayloadAction<boolean>) => {
      state.showNpsSurvey = action.payload;
    }
  }
});

export const { setShowNpsSurvey } = surveySlice.actions;

export const sessionReducer = combineReducers({
  [authenticationSlice.name]: authenticationSlice.reducer,
  [registrationSlice.name]: registrationSlice.reducer,
  [forgetPasswordSlice.name]: forgetPasswordSlice.reducer,
  [changePasswordSlice.name]: changePasswordSlice.reducer,
  [contactSlice.name]: contactSlice.reducer,
  [surveySlice.name]: surveySlice.reducer
});

export const sessionStateName = 'session';

export type SessionState = ReturnType<typeof sessionReducer>;

// The purpose of this type is to use at session/user selectors.
// As far as these selectors are concerned the state needs to include the SessionState
// and not the whole RootState object
export type StateWithSession = {
  [sessionStateName]: SessionState;
};

export const selectAuthState = (state: StateWithSession) =>
  state[sessionStateName][authenticationSlice.name];

export const selectAuthProfile = (state: StateWithSession) =>
  selectAuthState(state).profile;

export const selectAdminBuyerCompany =
  (buyerCompanyId: string) =>
  (state: StateWithSession): CompanyResponse | null | undefined => {
    return selectAuthState(state).adminBuyerCompanies?.find(
      (company) => company.id.toString() === buyerCompanyId.toString()
    );
  };

export const selectAuthCompany = (state: StateWithSession) =>
  selectAuthState(state).company;
export const selectAuthToken = (state: StateWithSession) =>
  selectAuthState(state).token;
export const selectIntercomHash = (state: StateWithSession) =>
  selectAuthState(state).intercomHash;

export const selectIsAuthenticated = (state: StateWithSession) =>
  !!selectAuthToken(state) && !!selectAuthProfile(state);

export const selectProfilePath = (state: StateWithSession) => {
  const profile = selectAuthProfile(state);
  return createProfilePath(profile);
};

export const selectAfterLoginPath = (state: StateWithSession) => {
  const profile = selectAuthProfile(state);
  return createAfterLoginPath(profile);
};

export const selectDAToolBasePath = (state: StateWithSession) => {
  const profile = selectAuthProfile(state);
  return createDAToolBasePath(profile);
};

export const selectSettingsBasePath = (state: StateWithSession) => {
  const profile = selectAuthProfile(state);
  return createSettingsBasePath(profile);
};

export const selectUserRules = (state: StateWithSession) =>
  selectAuthProfile(state)?.rules ?? [];

export const selectUserRegionRules = createSelector(selectUserRules, (rules) =>
  rules.filter((r) => r.ruleType === 'REGION_RULE')
);

export const selectAuthorities = createSelector(
  selectAuthProfile,
  (profile) => profile?.authorities ?? []
);

export const selectProducts = createSelector(
  selectAuthProfile,
  (profile) => profile?.products ?? []
);
