import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@client';
import { FinancialPaymentResponse } from '@module/financial/model/FinancialPaymentResponse';
import {
  RequestApprovalRequestDto,
  CashFlowAccountPaymentsSearch,
  FinancialCashFlowAccountDetailResponse
} from './models';
import { FinancialCashFlowAccountResponse } from '@module/financial/model/FinancialCashFlowAccountResponse';

export const financialPaymentsApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['FinancialPayments'],
  reducerPath: 'financial-payments_api',
  endpoints: (build) => ({
    fetchPayment: build.query<FinancialPaymentResponse, { paymentId: number }>({
      query: ({ paymentId }) => ({
        url: `/secured/financial/payments/${paymentId}`,
        method: 'GET'
      })
    }),
    fetchPayments: build.query<
      FinancialPaymentResponse[],
      { paymentIds: number[] }
    >({
      queryFn: async ({ paymentIds }, _api, _extraOptions, baseQuery) => {
        const promises = paymentIds.map(async (paymentId) => {
          return await baseQuery({
            url: `/secured/financial/payments/${paymentId}`,
            method: 'GET'
          });
        });

        const result = await Promise.all(promises);

        return { data: result.map((r) => r.data as FinancialPaymentResponse) };
      }
    }),
    makePayment: build.mutation<FinancialPaymentResponse, FormData>({
      query: (data) => ({
        url: '/secured/financial/payment/pay',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: financialPayment } = await queryFulfilled;
          dispatch(
            financialPaymentsApi.util.updateQueryData(
              'fetchPayment',
              { paymentId: financialPayment.id },
              (draft) => {
                Object.assign(draft, financialPayment);
              }
            )
          );
        } catch {
          //
        }
      }
    }),
    makeBulkPayment: build.mutation<FinancialPaymentResponse[], FormData>({
      query: (data) => ({
        url: '/secured/financial/payments/bulk-pay',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data: financialPayments } = await queryFulfilled;
        dispatch(
          financialPaymentsApi.util.updateQueryData(
            'fetchPayments',
            { paymentIds: financialPayments.map((p) => p.id) },
            (draft) => {
              Object.assign(draft, financialPayments);
            }
          )
        );
      }
    }),
    makeAdjustment: build.mutation<FinancialPaymentResponse, FormData>({
      query: (data) => ({
        url: '/secured/financial/payment/adjust',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: financialPayment } = await queryFulfilled;
          dispatch(
            financialPaymentsApi.util.updateQueryData(
              'fetchPayment',
              { paymentId: financialPayment.id },
              (draft) => {
                Object.assign(draft, financialPayment);
              }
            )
          );
        } catch {
          //
        }
      }
    }),
    requestApprovalAutomaticPayment: build.mutation<
      FinancialPaymentResponse,
      { paymentId: number; data: RequestApprovalRequestDto }
    >({
      query: ({ paymentId, data }) => ({
        url: `/secured/financial/payments/${paymentId}/request-approval`,
        method: 'POST',
        data
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: financialPayment } = await queryFulfilled;
          dispatch(
            financialPaymentsApi.util.updateQueryData(
              'fetchPayment',
              { paymentId: financialPayment.id },
              (draft) => {
                Object.assign(draft, financialPayment);
              }
            )
          );
        } catch {
          //
        }
      }
    }),
    approveAutomaticPayment: build.mutation<
      FinancialPaymentResponse,
      { paymentId: number }
    >({
      query: ({ paymentId }) => ({
        url: `/secured/financial/payments/${paymentId}/approve`,
        method: 'POST'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: financialPayment } = await queryFulfilled;
          dispatch(
            financialPaymentsApi.util.updateQueryData(
              'fetchPayment',
              { paymentId: financialPayment.id },
              (draft) => {
                Object.assign(draft, financialPayment);
              }
            )
          );
        } catch {
          //
        }
      }
    }),
    backForReviewAutomaticPayment: build.mutation<
      FinancialPaymentResponse,
      { paymentId: number }
    >({
      query: ({ paymentId }) => ({
        url: `/secured/financial/payments/${paymentId}/back-for-review`,
        method: 'POST'
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: financialPayment } = await queryFulfilled;
          dispatch(
            financialPaymentsApi.util.updateQueryData(
              'fetchPayment',
              { paymentId: financialPayment.id },
              (draft) => {
                Object.assign(draft, financialPayment);
              }
            )
          );
        } catch {
          //
        }
      }
    }),
    bulkApproveAutomated: build.mutation<FinancialPaymentResponse[], number[]>({
      query: (paymentIds) => ({
        url: `/secured/financial/payments/bulk-approve`,
        method: 'POST',
        data: paymentIds
      }),
      async onQueryStarted(paymentIds, { dispatch, queryFulfilled }) {
        try {
          const { data: financialPayments } = await queryFulfilled;
          dispatch(
            financialPaymentsApi.util.updateQueryData(
              'fetchPayments',
              { paymentIds },
              (draft) => {
                Object.assign(draft, financialPayments);
              }
            )
          );
        } catch {
          //
        }
      }
    }),
    verifyBulkPayment: build.mutation<FinancialPaymentResponse, FormData>({
      query: (data) => ({
        url: `/secured/financial/payments/verify-bulk-payment`,
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    }),
    fetchCashFlowAccounts: build.query<
      FinancialCashFlowAccountResponse[],
      { buyerId: number }
    >({
      query: ({ buyerId }) => ({
        url: `/secured/buyer/${buyerId}/buyer-cash-flow-accounts`,
        method: 'GET'
      })
    }),
    fetchCashFlowAccountPayments: build.query<
      FinancialCashFlowAccountDetailResponse[],
      CashFlowAccountPaymentsSearch
    >({
      query: ({ buyerId, fromDate, toDate }) => ({
        url: `/secured/financial/buyer/${buyerId}/cash-flow-account-payments`,
        method: 'GET',
        params: {
          fromDate,
          toDate
        }
      })
    })
  })
});

export type StateWithFinancialPaymentsApi = {
  [financialPaymentsApi.reducerPath]: ReturnType<
    typeof financialPaymentsApi.reducer
  >;
};

export const {
  useFetchPaymentQuery,
  useFetchPaymentsQuery,
  useMakePaymentMutation,
  useMakeAdjustmentMutation,
  useRequestApprovalAutomaticPaymentMutation,
  useApproveAutomaticPaymentMutation,
  useBackForReviewAutomaticPaymentMutation,
  useVerifyBulkPaymentMutation,
  useBulkApproveAutomatedMutation,
  useMakeBulkPaymentMutation,
  useLazyFetchCashFlowAccountPaymentsQuery,
  useFetchCashFlowAccountsQuery
} = financialPaymentsApi;
