import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@client';
import {
  AssignmentResponse,
  AssignmentSummaryResponse,
  PortLogResponse
} from '@state';
import { PortLogGeneralCommentsForm } from '@module/assignment/model/PortLogGeneralCommentsForm';

export const assignmentApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'secured/assignment' }),
  reducerPath: 'assignment-api',
  tagTypes: ['Assignment', 'AssignmentSummary', 'InterntalPortLog'],
  endpoints: (build) => {
    return {
      fetchAssignment: build.query<
        AssignmentResponse,
        { assignmentId: string }
      >({
        query: ({ assignmentId }) => ({
          url: `/${assignmentId}/load`,
          method: 'GET'
        }),
        providesTags: ['Assignment']
      }),
      fetchSummary: build.query<
        AssignmentSummaryResponse,
        { assignmentId: string }
      >({
        query: ({ assignmentId }) => ({
          url: `/${assignmentId}/load-summary`,
          method: 'GET'
        }),
        providesTags: ['AssignmentSummary']
      }),
      fetchPublicPortLog: build.query<
        PortLogResponse,
        {
          assignmentId: string;
        }
      >({
        query: ({ assignmentId }) => ({
          url: `/${assignmentId}/load-public-port-log`,
          method: 'GET'
        })
      }),
      fetchInternalPortLog: build.query<
        PortLogResponse,
        {
          assignmentId: string;
        }
      >({
        query: ({ assignmentId }) => ({
          url: `/${assignmentId}/load-internal-port-log`,
          method: 'GET'
        }),
        providesTags: ['InterntalPortLog']
      }),
      submitPortLog: build.mutation<
        AssignmentResponse,
        { assignmentId: string }
      >({
        query: ({ assignmentId }) => ({
          url: `/${assignmentId}/submit-port-log`,
          method: 'POST'
        }),
        invalidatesTags: ['AssignmentSummary']
      }),
      submitPortLogGeneralComments: build.mutation<
        PortLogResponse,
        { assignmentId: string; payload: PortLogGeneralCommentsForm }
      >({
        query: ({ assignmentId, payload }) => ({
          url: `/${assignmentId}/save-internal-port-log-general-comments`,
          method: 'POST',
          data: payload
        }),
        async onQueryStarted({ assignmentId }, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(
              assignmentApi.util.updateQueryData(
                'fetchInternalPortLog',
                { assignmentId },
                () => data
              )
            );
          } catch {
            //
          }
        }
      })
    };
  }
});

export type stateWithAssignmentApi = {
  [assignmentApi.reducerPath]: ReturnType<typeof assignmentApi.reducer>;
};

export const {
  useFetchAssignmentQuery,
  useFetchSummaryQuery,
  useFetchPublicPortLogQuery,
  useFetchInternalPortLogQuery,
  useSubmitPortLogMutation,
  useSubmitPortLogGeneralCommentsMutation
} = assignmentApi;

export const invalidateInternalPortLog = assignmentApi.util.invalidateTags([
  'InterntalPortLog'
]);

export const invalidateAssignment = assignmentApi.util.invalidateTags([
  'Assignment'
]);
