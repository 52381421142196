import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BuyerDashboardFilterPagination } from '../../types';

export type BuyerDashboardState = {
  pagination: BuyerDashboardFilterPagination;
  drawer?: { portCallId: number; appointmentId: number };
};

const initialState: BuyerDashboardState = {
  pagination: { page: 0, pageSize: 5 }
};

export const buyerDashboardSlice = createSlice({
  name: 'buyer-dashboard',
  initialState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.pagination.page = payload.page;
    },
    updateFilter: (
      state,
      {
        payload
      }: PayloadAction<Record<string, string | number | boolean | null>>
    ) => {
      state.pagination.filter = Object.entries({
        ...state.pagination.filter,
        ...payload
      }).reduce(
        (acc, [key, val]) => (!!val ? { ...acc, [key]: val } : acc),
        {}
      );
      // reset page
      state.pagination.page = 0;
    },
    resetFilters: (state) => {
      const myPendingTasks = !!state.pagination.filter?.['myPendingTasks'];
      state.pagination.filter = { myPendingTasks };
      // reset page
      state.pagination.page = 0;
    },
    updateDrawer: (
      state,
      { payload }: PayloadAction<{ portCallId: number; appointmentId: number }>
    ) => {
      state.drawer = payload;
    }
  }
});

export const {
  updatePage: updateBuyerDashboardPage,
  updateFilter: updateBuyerDashboardFilter,
  resetFilters: resetBuyerDashboardFilters,
  updateDrawer: updateBuyerDashboardDrawer
} = buyerDashboardSlice.actions;

export type StateWithBuyerDashboard = {
  [buyerDashboardSlice.name]: BuyerDashboardState;
};
