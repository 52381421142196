import { ProfileResponse } from '@module/profile/model/ProfileResponse';
import { mainRestApi } from '../rest-api';

export const accountApi = mainRestApi.injectEndpoints({
  endpoints: (build) => ({
    acceptTermsCondition: build.mutation<
      ProfileResponse,
      { accepted: boolean; documentVersion?: string | undefined }
    >({
      query: ({ accepted, documentVersion = '1.0' }) => ({
        url: '/secured/account/accept-terms-and-conditions',
        method: 'POST',
        data: {
          accepted,
          documentVersion
        }
      })
    })
  })
});

export const { useAcceptTermsConditionMutation } = accountApi;
