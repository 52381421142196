import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@client';
import {
  AdminRegionEditDTO,
  AdminRegionResponseDTO,
  AdminRegionSubmitDTO
} from '../../types/RegionDTO';
import ListRequest from '../../../../types/ListRequest';
import ListResponse from '../../../../types/ListResponse';
import { RefData } from 'components';

interface FetchAdminRegionsRequest extends ListRequest {
  buyerId: number;
}

export const adminRegionsApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['AdminRegions'],
  reducerPath: 'admin-regions_api',
  endpoints: (build) => ({
    fetchRegions: build.query<
      ListResponse<AdminRegionResponseDTO>,
      FetchAdminRegionsRequest
    >({
      query: ({ page, pageSize, buyerId, ordering }) => ({
        url: 'secured/regions',
        method: 'GET',
        params: {
          page: page,
          size: pageSize,
          buyerId: buyerId,
          property: ordering?.property,
          direction: ordering?.direction
        }
      }),
      providesTags: ['AdminRegions']
    }),
    fetchRegion: build.query<AdminRegionResponseDTO, { regionId: number }>({
      query: ({ regionId }) => ({
        url: `/secured/region/${regionId}`,
        method: 'GET'
      }),
      providesTags: ['AdminRegions']
    }),
    submitRegion: build.mutation<AdminRegionResponseDTO, AdminRegionSubmitDTO>({
      query: (payload) => ({
        url: '/secured/region',
        method: 'PUT',
        data: payload
      }),
      invalidatesTags: ['AdminRegions']
    }),
    editRegion: build.mutation<AdminRegionResponseDTO, AdminRegionEditDTO>({
      query: (payload) => ({
        url: '/secured/region',
        method: 'POST',
        data: payload
      }),
      invalidatesTags: ['AdminRegions']
    }),
    fetchActiveRegions: build.query<RefData[], number>({
      query: (buyerId) => ({
        url: `/secured/ref/buyer/${buyerId}/regions`,
        method: 'GET',
        params: {
          state: 'ACTIVE'
        }
      })
    })
  })
});

export type StateWithAdminRegionsApi = {
  [adminRegionsApi.reducerPath]: ReturnType<typeof adminRegionsApi.reducer>;
};

export const {
  useSubmitRegionMutation,
  useEditRegionMutation,
  useFetchRegionsQuery,
  useLazyFetchRegionsQuery,
  useFetchActiveRegionsQuery,
  useLazyFetchActiveRegionsQuery,
  useFetchRegionQuery,
  useLazyFetchRegionQuery
} = adminRegionsApi;
