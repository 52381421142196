import { hotjar } from 'react-hotjar';
import { UseScriptStatus } from '../../../app/sitemap/_common/hooks/useCookieConsentBanner';
import { useEffect } from 'react';

// in case these options change we need to update them here
// hotjarSiteId is picked up by hotjar's page
// hotjarSnippetVersion is picked up by hotjar's page
const hotjarSiteId = 3353951;
const hotjarSnippetVersion = 6;
const debugMode = process.env.NODE_ENV !== 'production';

export const useHotjar = (autoBlockCookiesStatus?: UseScriptStatus) => {
  useEffect(() => {
    if (autoBlockCookiesStatus === 'ready') {
      hotjar.initialize(hotjarSiteId, hotjarSnippetVersion, debugMode);
    }
  }, [autoBlockCookiesStatus]);
};

/**
 * define the userId and user properties to identify the user
 * @param userId userId of the logged in user (could be username for example)
 * @param userProperties user properties to identify the user as an object
 *
 * @example
 * hotjarIdentify('userId', { userProperty: 'value' });
 *
 */
export const hotjarIdentify = (userId: string, userProperties: any) => {
  if (hotjar.initialized()) {
    hotjar.identify(userId, userProperties);
  }
};

/**
 * track an event in hotjar in order to show a survey or start a recording
 * @param eventName name of the event to be tracked by hotjar
 *
 * @example
 * hotjarEvent('open_nps_survey');
 * hotjarEvent(); // which is identical to the above due to the default value
 */
export const hotjarEvent = (eventName: string = 'open_nps_survey') => {
  if (hotjar.initialized()) {
    hotjar.event(eventName);
  }
};

/**
 * track a state change in hotjar
 * @param path path of the current page
 * @example
 * hotjarStateChange('/home');
 * hotjarStateChange('/my/page');
 */
export const hotjarStateChange = (path: string) => {
  if (hotjar.initialized()) {
    hotjar.stateChange(path);
  }
};
