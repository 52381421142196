import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PagingState, SortingState } from '@state/types';

type adminRegionsState = PagingState & SortingState;

const initialState: adminRegionsState = {
  page: 0,
  size: 10
};

export const adminRegionsSlice = createSlice({
  name: 'admin-regions',
  initialState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.page = payload.page;
    },
    updateOrder: (
      state,
      {
        payload
      }: PayloadAction<{ property: string; direction: 'ASC' | 'DESC' }>
    ) => {
      state.property = payload.property;
      state.direction = payload.direction;
      state.page = 0;
    }
  }
});

export const {
  updatePage: updateAdminRegionsPage,
  updateOrder: updateAdminRegionsOrder
} = adminRegionsSlice.actions;

export type StateWithAdminRegions = {
  [adminRegionsSlice.name]: adminRegionsState;
};
