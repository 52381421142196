import { secureRefSplitApi } from './ref-api';

const activityTypesEstimationActiveApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchActivityTypesEstimationActive: build.query<
      { label: string; value: string }[],
      void
    >({
      query: () => ({
        url: '/activity-types/list-estimation-active',
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchActivityTypesEstimationActiveQuery } =
  activityTypesEstimationActiveApi;
