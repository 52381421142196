import { secureRefSplitApi } from './ref-api';

const activeExpensesCatalogueItemsApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchActiveExpensesCatalogueItems: build.query<
      { label: string; value: string }[],
      void
    >({
      query: () => ({
        url: '/active-expenses-catalogue-items',
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const { useFetchActiveExpensesCatalogueItemsQuery } =
  activeExpensesCatalogueItemsApi;
