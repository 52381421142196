import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PagingState,
  FiltersState,
  filtersAdapter,
  Filter
} from '../../../../types';

export type AdminDaToolPortCallsState = {
  table: PagingState & FiltersState;
  drawer?: { portCallId: number; appointmentId: number; buyerId: number };
};

const initialState: AdminDaToolPortCallsState = {
  table: {
    page: 0,
    size: 5,
    filters: filtersAdapter.getInitialState()
  }
};

export const adminDaToolPortCallsSlice = createSlice({
  name: 'admin_da-tool_port-calls',
  initialState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.table.page = payload.page;
    },
    updateFilter: (state, { payload }: PayloadAction<Filter>) => {
      filtersAdapter.upsertOne(state.table.filters, payload);
      state.table.page = 0;
    },
    updateFilters: (state, { payload }: PayloadAction<Filter[]>) => {
      filtersAdapter.upsertMany(state.table.filters, payload);
      state.table.page = 0;
    },
    clearFilter: (state, { payload }: PayloadAction<{ name: string }>) => {
      filtersAdapter.removeOne(state.table.filters, payload.name);
      state.table.page = 0;
    },
    resetFilters: (state) => {
      // reset all but myPendingTasks
      filtersAdapter.removeMany(
        state.table.filters,
        state.table.filters.ids.filter((i) => i !== 'myPendingTasks')
      );
      state.table.page = 0;
    },
    updateDrawer: (
      state,
      {
        payload
      }: PayloadAction<{
        portCallId: number;
        appointmentId: number;
        buyerId: number;
      }>
    ) => {
      state.drawer = payload;
    }
  }
});

export const {
  updatePage: updateAdminDaToolPortCallsPage,
  updateFilter: updateAdminDaToolPortCallsFilter,
  updateFilters: updateAdminDaToolPortCallsFilters,
  resetFilters: resetAdminDaToolPortCallsFilters,
  updateDrawer: updateAdminDaToolPortCallsDrawer
} = adminDaToolPortCallsSlice.actions;

export type StateWithAdminDaToolPortCalls = {
  [adminDaToolPortCallsSlice.name]: AdminDaToolPortCallsState;
};
