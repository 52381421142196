import {
  kypVerificationsTableSlice,
  StateWithKypVerificationsState,
  KYPVerificationsState
} from './reducers';
import { filtersAdapter } from '../../../types';

export const selectKypVerificationsState = (
  state: StateWithKypVerificationsState
) => state[kypVerificationsTableSlice.name];

const filterSelectors = filtersAdapter.getSelectors<KYPVerificationsState>(
  (state) => state.filters
);

export const selectKypVerificationsFilters = (
  state: StateWithKypVerificationsState
) => {
  return filterSelectors.selectAll(selectKypVerificationsState(state));
};

export const selectKypVerificationsFilterBy =
  (name: string) => (state: StateWithKypVerificationsState) => {
    return filterSelectors.selectById(selectKypVerificationsState(state), name);
  };
