import { en } from './en';
import { IMessages } from './common';

export class Messages {
  static list(): IMessages {
    return en;
  }

  static parse(key: string | undefined): string {
    try {
      if (!key) {
        return Messages.list().common.error.general;
      }
      //@ts-ignore
      return key
        .replace(/\[([^\]]+)]/g, '.$1')
        .split('.')
        .reduce(function (o, p) {
          //@ts-ignore
          return o[p] || key;
        }, Messages.list());
    } catch (e) {
      return Messages.list().common.error.general;
    }
  }
}
