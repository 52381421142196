export enum Authority {
  //  -- Settings section authorities
  SETTINGS_EDIT_COMPANY = 'SETTINGS_EDIT_COMPANY',
  SETTINGS_EDIT_USER = 'SETTINGS_EDIT_USER',
  SETTINGS_EDIT_BILLING_COMPANIES = 'SETTINGS_EDIT_BILLING_COMPANIES',
  SETTINGS_EDIT_VESSEL = 'SETTINGS_EDIT_VESSEL',
  //------------------------------------------------------------------

  // -- DA product authorities ---------------------------------
  DA_VIEW = 'DA_VIEW',

  // -- DA  dashboard
  DA_PORT_CALL_DASHBOARD = 'DA_PORT_CALL_DASHBOARD',

  DA_DASHBOARD_APPOINTMENTS_ACTIVE = 'DA_DASHBOARD_APPOINTMENTS_ACTIVE',
  DA_DASHBOARD_APPOINTMENTS_DRAFT = 'DA_DASHBOARD_APPOINTMENTS_DRAFT',
  DA_DASHBOARD_APPOINTMENTS_INACTIVE = 'DA_DASHBOARD_APPOINTMENTS_INACTIVE',

  DA_DASHBOARD_ASSIGNMENTS_ACTIVE = 'DA_DASHBOARD_ASSIGNMENTS_ACTIVE',
  DA_DASHBOARD_ASSIGNMENTS_INACTIVE = 'DA_DASHBOARD_ASSIGNMENTS_INACTIVE',

  DA_DASHBOARD_DISBURSEMENTS_FORM_DRAFT = 'DA_DASHBOARD_DISBURSEMENTS_FORM_DRAFT',
  DA_DASHBOARD_DISBURSEMENTS_FORM_PENDING_APPROVAL = 'DA_DASHBOARD_DISBURSEMENTS_FORM_PENDING_APPROVAL',
  DA_DASHBOARD_DISBURSEMENTS_FORM_COMPLETED = 'DA_DASHBOARD_DISBURSEMENTS_FORM_COMPLETED',
  DA_DASHBOARD_DISBURSEMENTS_FORM_PENDING_MY_APPROVAL = 'DA_DASHBOARD_DISBURSEMENTS_FORM_PENDING_MY_APPROVAL',
  DA_DASHBOARD_DISBURSEMENTS_EXPENSES_PENDING_MY_APPROVAL = 'DA_DASHBOARD_DISBURSEMENTS_EXPENSES_PENDING_MY_APPROVAL',
  DA_DASHBOARD_DISBURSEMENTS_CASE_OVERVIEW = 'DA_DASHBOARD_DISBURSEMENTS_CASE_OVERVIEW',

  DA_DASHBOARD_REQUESTS_ACTIVE = 'DA_DASHBOARD_REQUESTS_ACTIVE',
  DA_DASHBOARD_REQUESTS_DRAFT = 'DA_DASHBOARD_REQUESTS_DRAFT',
  DA_DASHBOARD_REQUESTS_INACTIVE = 'DA_DASHBOARD_REQUESTS_INACTIVE',

  // -- Da evaluation and evaluation expenses
  DA_EVALUATION_VIEW = 'DA_EVALUATION_VIEW',
  DA_EVALUATION_VERIFY = 'DA_EVALUATION_VERIFY',
  DA_EVALUATION_DELETE = 'DA_EVALUATION_DELETE',

  DA_EVALUATION_CHARTERER_ACCOUNT_REPORT_VIEW = 'DA_EVALUATION_CHARTERER_ACCOUNT_REPORT_VIEW',
  DA_EVALUATION_CHARTERER_ACCOUNT_REPORT_SUBMIT = 'DA_EVALUATION_CHARTERER_ACCOUNT_REPORT_SUBMIT',
  DA_EVALUATION_PREFILL_AND_VERIFY_BALANCE = 'DA_EVALUATION_PREFILL_AND_VERIFY_BALANCE',

  DA_EXPENSE_EVALUATION_VIEW = 'DA_EXPENSE_EVALUATION_VIEW',
  DA_EXPENSE_EVALUATION_EDIT = 'DA_EXPENSE_EVALUATION_EDIT',

  DA_EXPENSE_CHAT_GPT_FORMULA_VIEW = 'DA_EXPENSE_CHAT_GPT_FORMULA_VIEW',

  DA_EXPENSE_EVALUATION_RESTORE = 'DA_EXPENSE_EVALUATION_RESTORE',
  DA_EXPENSE_EVALUATION_SPLIT = 'DA_EXPENSE_EVALUATION_SPLIT',
  DA_EXPENSE_EVALUATION_VERIFY = 'DA_EXPENSE_EVALUATION_VERIFY',
  DA_EXPENSE_EVALUATION_SPLIT_AND_APPROVE = 'DA_EXPENSE_EVALUATION_SPLIT_AND_APPROVE',
  DA_EXPENSE_EVALUATION_VERIFICATION_REVERT = 'DA_EXPENSE_EVALUATION_VERIFICATION_REVERT',

  DA_EXPENSE_CHILD_EVALUATION_DELETE = 'DA_EXPENSE_CHILD_EVALUATION_DELETE',

  DA_EXPENSE_SPLIT_EVALUATION_OVERRIDE_VERIFY = 'DA_EXPENSE_SPLIT_EVALUATION_OVERRIDE_VERIFY',
  DA_EXPENSE_SPLIT_EVALUATION_VERIFICATION_REVERT_OVERRIDE = 'DA_EXPENSE_SPLIT_EVALUATION_VERIFICATION_REVERT_OVERRIDE',

  DA_EXPENSE_SPLIT_EVALUATION_VIEW = 'DA_EXPENSE_SPLIT_EVALUATION_VIEW',
  DA_EXPENSE_SPLIT_EVALUATION_DIVIDE = 'DA_EXPENSE_SPLIT_EVALUATION_DIVIDE',
  DA_EXPENSE_SPLIT_EVALUATION_VERIFY = 'DA_EXPENSE_SPLIT_EVALUATION_VERIFY',
  DA_EXPENSE_SPLIT_EVALUATION_VERIFICATION_REVERT = 'DA_EXPENSE_SPLIT_EVALUATION_VERIFICATION_REVERT',
  DA_EXPENSE_SPLIT_EVALUATION_REALLOCATE = 'DA_EXPENSE_SPLIT_EVALUATION_REALLOCATE',

  DA_ATTRIBUTED_EXPENSES_DETAILS_VIEW = 'DA_ATTRIBUTED_EXPENSES_DETAILS_VIEW',

  //-- Da Voyage
  DA_VOYAGE_CREATE = 'DA_VOYAGE_CREATE',
  DA_TBN_VOYAGE_CREATE = 'DA_TBN_VOYAGE_CREATE',
  DA_VOYAGE_EDIT = 'DA_VOYAGE_EDIT',
  DA_VOYAGE_NUMBER_EDIT = 'DA_VOYAGE_NUMBER_EDIT',

  //-- Da Port Call
  DA_PORT_CALL_VIEW_IMPORTED = 'DA_PORT_CALL_VIEW_IMPORTED',
  DA_PORT_CALL_CREATE = 'DA_PORT_CALL_CREATE',
  DA_PORT_CALL_EDIT = 'DA_PORT_CALL_EDIT',
  DA_PORT_CALL_DELETE_DRAFT = 'DA_PORT_CALL_DELETE_DRAFT',
  DA_PORT_CALL_CANCEL = 'DA_PORT_CALL_CANCEL',
  DA_PORT_CALL_IN_PROCESS_EDIT = 'DA_PORT_CALL_IN_PROCESS_EDIT',

  // -- Da appointment
  DA_APPOINTMENT_CREATE = 'DA_APPOINTMENT_CREATE',
  DA_APPOINTMENT_CANCEL = 'DA_APPOINTMENT_CANCEL',
  DA_APPOINTMENT_DELETE_DRAFT = 'DA_APPOINTMENT_DELETE_DRAFT',

  // -- Da request for quotation
  DA_REQUESTS_VIEW = 'DA_REQUESTS_VIEW',
  DA_REQUESTS_CREATE = 'DA_REQUESTS_CREATE',
  DA_REQUESTS_DELETE = 'DA_REQUESTS_DELETE',
  DA_REQUESTS_CANCEL = 'DA_REQUESTS_CANCEL',

  // -- Da Assignment/Port log
  DA_ASSIGNMENT_INTERNAL_PORT_LOG_DEPARTMENT_EDIT = 'DA_ASSIGNMENT_INTERNAL_PORT_LOG_DEPARTMENT_EDIT',
  DA_ASSIGNMENT_INTERNAL_PORT_LOG_DEPARTMENT_VIEW = 'DA_ASSIGNMENT_INTERNAL_PORT_LOG_DEPARTMENT_VIEW',
  DA_ASSIGNMENT_INTERNAL_PORT_LOG_GENERAL_COMMENTS_EDIT = 'DA_ASSIGNMENT_INTERNAL_PORT_LOG_GENERAL_COMMENTS_EDIT',
  DA_ASSIGNMENT_INTERNAL_PORT_LOG_VIEW = 'DA_ASSIGNMENT_INTERNAL_PORT_LOG_VIEW',
  DA_ASSIGNMENT_PUBLIC_PORT_LOG_VIEW = 'DA_ASSIGNMENT_PUBLIC_PORT_LOG_VIEW',
  DA_ASSIGNMENT_CREATE = 'DA_ASSIGNMENT_CREATE',
  DA_ASSIGNMENT_SUBMIT = 'DA_ASSIGNMENT_SUBMIT',
  DA_ASSIGNMENT_CANCEL = 'DA_ASSIGNMENT_CANCEL',
  //------------------------------------------------------------------

  // -- Position list authorities ---------------------------------
  POSITION_LIST_VIEW = 'POSITION_LIST_VIEW',

  POSITION_LIST_VOYAGE_VIEW = 'POSITION_LIST_VOYAGE_VIEW',
  POSITION_LIST_VOYAGE_CREATE = 'POSITION_LIST_VOYAGE_CREATE',
  POSITION_LIST_VOYAGE_EDIT = 'POSITION_LIST_VOYAGE_EDIT',
  POSITION_LIST_VOYAGE_FREIGHT_FIELDS_EDIT = 'POSITION_LIST_VOYAGE_FREIGHT_FIELDS_EDIT',

  POSITION_LIST_PORT_CALL_CREATE = 'POSITION_LIST_PORT_CALL_CREATE',
  POSITION_LIST_PORT_CALL_DETAILS_VIEW = 'POSITION_LIST_PORT_CALL_DETAILS_VIEW',
  POSITION_LIST_PORT_CALL_ACTIVITIES_VIEW = 'POSITION_LIST_PORT_CALL_ACTIVITIES_VIEW',
  POSITION_LIST_PORT_CALL_SERVICES_VIEW = 'POSITION_LIST_PORT_CALL_SERVICES_VIEW',
  POSITION_LIST_PORT_CALL_NOTES_VIEW = 'POSITION_LIST_PORT_CALL_NOTES_VIEW',
  POSITION_LIST_PORT_CALL_DETAILS_EDIT = 'POSITION_LIST_PORT_CALL_DETAILS_EDIT',
  POSITION_LIST_PORT_CALL_ACTIVITIES_EDIT = 'POSITION_LIST_PORT_CALL_ACTIVITIES_EDIT',
  POSITION_LIST_PORT_CALL_SERVICES_EDIT = 'POSITION_LIST_PORT_CALL_SERVICES_EDIT',
  POSITION_LIST_PORT_CALL_NOTES_EDIT = 'POSITION_LIST_PORT_CALL_NOTES_EDIT',

  POSITION_LIST_SHOW_VESSEL_POSITION = 'POSITION_LIST_SHOW_VESSEL_POSITION',
  POSITION_LIST_SHOW_MAP = 'POSITION_LIST_SHOW_MAP',
  //------------------------------------------------------------------

  // -- Port Estimator authorities ---------------------------------
  PORT_ESTIMATOR_VIEW = 'PORT_ESTIMATOR_VIEW',
  PORT_ADVANCED_ESTIMATOR_VIEW = 'PORT_ADVANCED_ESTIMATOR_VIEW',
  //------------------------------------------------------------------

  // -- Port Directory authorities ---------------------------------
  PORT_DIRECTORY_VIEW = 'PORT_DIRECTORY_VIEW',
  //------------------------------------------------------------------

  // -- Financial authorities ---------------------------------
  FINANCIAL_VIEW = 'FINANCIAL_VIEW',
  FINANCIAL_EXPENSES_VIEW = 'FINANCIAL_EXPENSES_VIEW',
  FINANCIAL_CASHFLOW_VIEW = 'FINANCIAL_CASHFLOW_VIEW',
  FINANCIAL_CALENDAR_VIEW = 'FINANCIAL_CALENDAR_VIEW',
  FINANCIAL_PAYMENTS_VIEW = 'FINANCIAL_PAYMENTS_VIEW',
  FINANCIAL_BALANCES_VIEW = 'FINANCIAL_BALANCES_VIEW',
  FINANCIAL_PAYMENTS_OVERVIEW = 'FINANCIAL_PAYMENTS_OVERVIEW',
  FINANCIAL_PAYMENTS_OVERVIEW_SUBMIT = 'FINANCIAL_PAYMENTS_OVERVIEW_SUBMIT',
  PAYMENT_ORDER_REVIEWER = 'PAYMENT_ORDER_REVIEWER',
  PAYMENT_ORDER_APPROVER = 'PAYMENT_ORDER_APPROVER',
  //------------------------------------------------------------------

  // -- Agent Directory authorities ---------------------------------
  AGENT_DIRECTORY_VIEW = 'AGENT_DIRECTORY_VIEW',
  AGENT_DIRECTORY_COMPANY_PROFILE_VIEW = 'AGENT_DIRECTORY_COMPANY_PROFILE_VIEW',
  //------------------------------------------------------------------

  // -- Claims authorities --------------------------------------------
  CLAIMS_VIEW = 'CLAIMS_VIEW',
  CLAIMS_SET_TIME_BAR = 'CLAIMS_SET_TIME_BAR',
  CLAIMS_VIEW_APPOINTMENT_OVERVIEW = 'CLAIMS_VIEW_APPOINTMENT_OVERVIEW',
  CLAIMS_SEND = 'CLAIMS_SEND',
  CLAIMS_CLOSE = 'CLAIMS_CLOSE',
  //------------------------------------------------------------------

  // -- SUPPLIER SUBSCRIBED AUTHORITIES ---------------------------------
  COPY_DATA_FROM_PREVIOUS_PDAS = 'COPY_DATA_FROM_PREVIOUS_PDAS',
  AUTOMATED_CALCULATIONS_FOR_PDAS = 'AUTOMATED_CALCULATIONS_FOR_PDAS',
  CLOSE_APPOINTMENT = 'CLOSE_APPOINTMENT',
  WITHDRAW_PROFORMA = 'WITHDRAW_PROFORMA',

  // -- Support authorities -----------------------------------------
  ADMIN_SUPPORT_VIEW = 'ADMIN_SUPPORT_VIEW',
  ADMIN_SUPPORT_SUPPLIER_BANK_ACCOUNT_VIEW = 'ADMIN_SUPPORT_SUPPLIER_BANK_ACCOUNT_VIEW',
  ADMIN_SUPPORT_SUPPLIER_BANK_ACCOUNT_EDIT = 'ADMIN_SUPPORT_SUPPLIER_BANK_ACCOUNT_EDIT',
  ADMIN_SUPPORT_SUPPLIER_PRIMARY_KYC_VERIFIER = 'PRIMARY_KYC_VERIFIER',
  ADMIN_SUPPORT_SUPPLIER_SECONDARY_KYC_VERIFIER = 'SECONDARY_KYC_VERIFIER',
  ADMIN_SUPPORT_TASKS = 'ADMIN_SUPPORT_TASKS',

  // -- KYC authorities -----------------------------------------
  ADMIN_KYC_VIEW = 'ADMIN_KYC_VIEW',

  // -- Verbal Verification Authorities ----------------------------
  ADMIN_SUPPLIER_BANK_ACCOUNT_SECOND_APPROVAL = 'ADMIN_SUPPLIER_BANK_ACCOUNT_SECOND_APPROVAL',

  // -- Port Analysis authorities -----------------------------------
  ADMIN_PORT_ANALYSIS_VIEW = 'ADMIN_PORT_ANALYSIS_VIEW',

  // -- Estimation API authorities ----------------------------------
  ADMIN_ESTIMATION_API_VIEW = 'ADMIN_ESTIMATION_API_VIEW',

  // -- Disbursements authorities -----------------------------------
  ADMIN_DISBURSEMENTS_VIEW = 'ADMIN_DISBURSEMENTS_VIEW',

  // -- Audit authorities -------------------------
  ADMIN_AUDIT_VIEW = 'ADMIN_AUDIT_VIEW',

  // -- System authorities -------------------------
  ADMIN_SYSTEM_VIEW = 'ADMIN_SYSTEM_VIEW',

  // -- Financial authorities -------------------------
  ADMIN_FINANCIAL_VIEW = 'ADMIN_FINANCIAL_VIEW',

  // -- Metadata authorities -----------------------------------
  ADMIN_METADATA_VIEW = 'ADMIN_METADATA_VIEW',

  // -- Admin integration authorities
  ADMIN_INTEGRATION_VIEW = 'ADMIN_INTEGRATION_VIEW',

  ADMIN_REOPEN_DA = 'ADMIN_REOPEN_DA',

  ADMIN_FINANCIAL_ADJUST_BALANCE = 'ADMIN_FINANCIAL_ADJUST_BALANCE',

  ADMIN_PCE_VESON_PORTS = 'ADMIN_PCE_VESON_PORTS',

  // -- Agent Ratings authorities -----------------------------------------
  SUBMIT_AGENT_RATINGS = 'SUBMIT_AGENT_RATINGS',
  VIEW_AGENT_RATINGS = 'VIEW_AGENT_RATINGS',

  // -- Budget status authorities -----------------------------------------
  DA_EVALUATION_BUDGET_CHECK_VIEW = 'DA_EVALUATION_BUDGET_CHECK_VIEW',
  DA_EVALUATION_BUDGET_CHECK_PERFORM = 'DA_EVALUATION_BUDGET_CHECK_PERFORM',
  DA_EVALUATION_RETRY_TASK = 'DA_EVALUATION_RETRY_TASK',

  // -- DA submit payment request --------------------------------------------
  DA_EVALUATION_ADDITIONAL_PAYMENT_REQUEST_SUBMIT = 'DA_EVALUATION_ADDITIONAL_PAYMENT_REQUEST_SUBMIT',

  // -- Statement Of Facts ---------------------------------------------
  VIEW_STATEMENT_OF_FACTS = 'VIEW_STATEMENT_OF_FACTS',
  EDIT_STATEMENT_OF_FACTS = 'EDIT_STATEMENT_OF_FACTS',
  INVITE_SOF_COLLABORATORS = 'INVITE_SOF_COLLABORATORS'
}
