import React from 'react';

export enum LoadingTemplate {
  FORM = 'f|f|f,f|f|f',
  TABLE = 'l|l|l,l|l|l,l|l|l',
  VERTICAL_FORM = 'f,f,f,f'
}

class PsLoadingPlaceholder {
  static formTemplate() {
    return LoadingTemplate.FORM;
  }

  static tableTemplate() {
    return LoadingTemplate.TABLE;
  }

  static s() {
    return (
      <span className="content-placeholder" style={{ width: '20%' }}>
        &nbsp;
      </span>
    );
  }

  static m() {
    return (
      <span className="content-placeholder" style={{ width: '50%' }}>
        &nbsp;
      </span>
    );
  }

  static l() {
    return (
      <span className="content-placeholder" style={{ width: '100%' }}>
        &nbsp;
      </span>
    );
  }

  static f() {
    return (
      <div className="control">
        <label className="label m-m-bottom">{PsLoadingPlaceholder.s()}</label>
        <div className="panel content-placeholder" style={{ width: '100%' }}>
          &nbsp;
        </div>
      </div>
    );
  }

  static render(element: string) {
    switch (element) {
      case 's':
        return PsLoadingPlaceholder.s();
      case 'm':
        return PsLoadingPlaceholder.m();
      case 'l':
        return PsLoadingPlaceholder.l();
      case 'f':
        return PsLoadingPlaceholder.f();
    }
  }

  static parse(expression: string) {
    if (!expression) return <div />;
    const lines = expression.split(',');
    return (
      <React.Fragment>
        <div className="panel columns is-multiline is-8-widescreen">
          {lines.map((line, lindex) => {
            const elements = line.split('|');
            const className =
              elements.length > 2
                ? 'is-one-third-fullhd'
                : elements.length === 2
                ? 'is-half-fullhd'
                : '';
            return elements.map((element, index) => {
              return (
                <div
                  key={'field_' + lindex + '_' + index}
                  className={'column is-12 ' + className}
                >
                  {PsLoadingPlaceholder.render(element)}
                </div>
              );
            });
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default PsLoadingPlaceholder;
