import { usePsCookies } from 'components';
import { useEffect } from 'react';

export const useFontSize = () => {
  const cookies = usePsCookies();

  useEffect(() => {
    const fontSize = cookies.get('da_font_size');
    const html = document.getElementsByTagName('html')[0];

    if (!fontSize || !html) {
      return;
    }

    html.classList.remove(
      'medium-route-font',
      'small-route-font',
      'tiny-route-font'
    );
    if (fontSize !== 'default') {
      html.classList.add(fontSize);
    }
  }, [cookies]);
};
