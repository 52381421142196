import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ExpenseCatalogueItemResponse,
  ExpenseRequestForm,
  MostUsedExpenseItem,
  RequestsWizardExpensesResponse,
  SubmitedRequestsWizardExpensesResponse
} from '@state';

export const buyerDaToolRequestWizardApi = createApi({
  reducerPath: 'buyerDaToolRequestWizard',
  tagTypes: ['requestWizardStep3SubmitedExpenses'],
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    fetchHusbandyExpenseCatalogues: build.query<
      ExpenseCatalogueItemResponse[],
      void
    >({
      query: () => ({
        url: '/secured/catalogue-expenses/HUSBANDRY_EXPENSES/list',
        method: 'GET'
      })
    }),
    fetchSubmitedExpenses: build.query<
      SubmitedRequestsWizardExpensesResponse,
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        url: `/secured/request-case/${requestCaseId}/load-expenses-step`,
        method: 'GET'
      }),
      providesTags: ['requestWizardStep3SubmitedExpenses']
    }),
    saveRequestWizardStep3: build.mutation<
      RequestsWizardExpensesResponse,
      ExpenseRequestForm
    >({
      query: (expenseData) => ({
        url: '/secured/request-case/save-step-3',
        method: 'POST',
        data: expenseData
      }),
      invalidatesTags: ['requestWizardStep3SubmitedExpenses']
    }),
    fetchPastCaseExpenses: build.query<
      MostUsedExpenseItem[],
      { requestCaseId: string }
    >({
      query: ({ requestCaseId }) => ({
        url: `/secured/request-case/${requestCaseId}/load-historical-expenses`,
        method: 'GET'
      }),
      keepUnusedDataFor: 0
    })
  })
});

export const {
  useFetchHusbandyExpenseCataloguesQuery,
  useFetchSubmitedExpensesQuery,
  useSaveRequestWizardStep3Mutation,
  useFetchPastCaseExpensesQuery
} = buyerDaToolRequestWizardApi;
