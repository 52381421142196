import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EvaluationAvailableToRelateProforma } from '../../../module/evaluation/model/EvaluationAvailableToRelateProformaResponse';

export type EvaluationPageState = {
  portExpensesTotal: number;
  agencyExpensesTotal: number;
  husbandryExpensesTotal: number;
  linkedPDAs: EvaluationAvailableToRelateProforma;
};

const initialState: EvaluationPageState = {
  portExpensesTotal: 0,
  agencyExpensesTotal: 0,
  husbandryExpensesTotal: 0,
  linkedPDAs: []
};

export const evaluationPageSlice = createSlice({
  name: 'evaluation-page',
  initialState,
  reducers: {
    resetEvaluationPageValues: (state) => {
      initialState;
    },
    updateEvaluationPagePortExpensesTotal: (
      state,
      { payload }: PayloadAction<{ amount: number }>
    ) => {
      state.portExpensesTotal = payload.amount;
    },
    updateEvaluationPageAgencyExpensesTotal: (
      state,
      { payload }: PayloadAction<{ amount: number }>
    ) => {
      state.agencyExpensesTotal = payload.amount;
    },
    updateEvaluationPageHusbandryExpensesTotal: (
      state,
      { payload }: PayloadAction<{ amount: number }>
    ) => {
      state.husbandryExpensesTotal = payload.amount;
    },
    updateEvaluationPageLinkedPDAs: (
      state,
      {
        payload
      }: PayloadAction<{ linkedPDAs: EvaluationAvailableToRelateProforma }>
    ) => {
      state.linkedPDAs = payload.linkedPDAs;
    }
  }
});

export const {
  resetEvaluationPageValues,
  updateEvaluationPagePortExpensesTotal,
  updateEvaluationPageAgencyExpensesTotal,
  updateEvaluationPageHusbandryExpensesTotal,
  updateEvaluationPageLinkedPDAs
} = evaluationPageSlice.actions;

export type StateWithEvaluationPage = {
  [evaluationPageSlice.name]: EvaluationPageState;
};
