import { combineReducers } from 'redux';
import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { SupplierProfileForm } from '../../../module/supplier/model/SupplierProfileForm';
import { MailGroupResponse } from '../../../module/mail-group/model/MailGroupResponse';
import { MailGroupItem } from '../../../module/mail-group/model/MailGroupItem';
import { SupplierPortsResponse } from '../../../module/supplier/model/SupplierPortsResponse';
import { supplierApi } from '../restApi/secured/supplierApi';

export interface AgentRegistrationState {
  isProcessing: boolean;
  message: string;
  expertise: string[];
  ports: string[];
  isWorldwide: boolean;
  step: number;
}

export interface EditAgentPortsState {
  isLoading: boolean;
  isProcessing: boolean;
  isEdit: boolean;
  agentPorts: SupplierPortsResponse | null;
}

export interface EditAgentExpertiseState {
  isProcessing: boolean;
  isEdit: boolean;
  expertise: string[] | null;
}

export interface CreateAgentEmailState {
  isProcessing: boolean;
}

export interface EditAgentEmailPageState {
  mailGroupItem: MailGroupItem | null;
}

export interface EditAgentEmailState {
  isEdit: boolean;
  isLoading: boolean;
  isProcessing: boolean;
  mailGroup: MailGroupResponse | null;
}

export interface EditAgentProfileState {
  isEdit: boolean;
  isLoading: boolean;
  agentProfile: SupplierProfileForm | null;
}

const initialAgentRegistrationState: AgentRegistrationState = {
  isProcessing: false,
  message: '',
  expertise: [],
  ports: [],
  isWorldwide: true,
  step: 1
};

export const agentRegistrationSlice = createSlice({
  name: 'agent-registration',
  initialState: initialAgentRegistrationState,
  reducers: {
    inProcess: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setExpertise: (state, action: PayloadAction<string[]>) => {
      state.expertise = action.payload;
    },
    setPorts: (state, action: PayloadAction<string[]>) => {
      state.ports = action.payload;
    },
    setIsWorldwide: (state, action: PayloadAction<boolean>) => {
      state.isWorldwide = action.payload;
    },
    reset: (state) => {
      state.expertise = [];
      state.ports = [];
      state.step = initialAgentRegistrationState.step;
    }
  }
});

const initialEditAgentPortsState: EditAgentPortsState = {
  isLoading: false,
  isProcessing: false,
  isEdit: false,
  agentPorts: null
};

export const editAgentPortsSlice = createSlice({
  name: 'edit-agent-ports',
  initialState: initialEditAgentPortsState,
  reducers: {
    isLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    inProcess: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    isEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    setAgentPorts: (
      state,
      action: PayloadAction<SupplierPortsResponse | null>
    ) => {
      state.agentPorts = action.payload;
    }
  }
});

const initialAgentExpertiseState: EditAgentExpertiseState = {
  isProcessing: false,
  isEdit: false,
  expertise: null
};

export const editAgentExpertiseSlice = createSlice({
  name: 'edit-agent-expertise',
  initialState: initialAgentExpertiseState,
  reducers: {
    inProcess: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    isEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    setExpertise: (state, action: PayloadAction<string[]>) => {
      state.expertise = action.payload;
    }
  }
});

const initialEditAgentEmailState: EditAgentEmailState = {
  isEdit: false,
  isLoading: false,
  isProcessing: false,
  mailGroup: null
};

export const editAgentEmailSlice = createSlice({
  name: 'edit-agent-email',
  initialState: initialEditAgentEmailState,
  reducers: {
    isEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    isLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    isProcessing: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    },
    setMailGroup: (state, action: PayloadAction<MailGroupResponse | null>) => {
      state.mailGroup = action.payload;
    }
  }
});

const initialCreateAgentEmailState: CreateAgentEmailState = {
  isProcessing: false
};

export const createAgentEmailSlice = createSlice({
  name: 'create-agent-email',
  initialState: initialCreateAgentEmailState,
  reducers: {
    isProcessing: (state, action: PayloadAction<boolean>) => {
      state.isProcessing = action.payload;
    }
  }
});

const initialEditAgentProfileState: EditAgentProfileState = {
  isEdit: false,
  isLoading: false,
  agentProfile: null
};

export const editAgentProfileSlice = createSlice({
  name: 'edit-agent-profile',
  initialState: initialEditAgentProfileState,
  reducers: {
    isEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    isLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAgentProfile: (
      state,
      action: PayloadAction<SupplierProfileForm | null>
    ) => {
      state.agentProfile = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addMatcher(
      isAnyOf(supplierApi.endpoints.saveSupplierProfileSettings.matchFulfilled),
      (state, { payload }) => {
        state.agentProfile = payload;
      }
    );
  }
});

export const agentAccountStateName = 'agentAccountSettings';

export const agentAccountReducer = combineReducers({
  [agentRegistrationSlice.name]: agentRegistrationSlice.reducer,
  [editAgentPortsSlice.name]: editAgentPortsSlice.reducer,
  [editAgentExpertiseSlice.name]: editAgentExpertiseSlice.reducer,
  [editAgentProfileSlice.name]: editAgentProfileSlice.reducer,
  [editAgentEmailSlice.name]: editAgentEmailSlice.reducer,
  [createAgentEmailSlice.name]: createAgentEmailSlice.reducer
});

export type AgentAccountSettingsState = ReturnType<typeof agentAccountReducer>;
